import React from 'react';
import Lottie from 'lottie-react';
import preloaderAnimation from '../../preloader.json';

function Preloader() {
  return (
    <div className="trabu-preloader">
      <Lottie animationData={preloaderAnimation} loop />
    </div>
  );
}

export default Preloader;
