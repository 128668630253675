import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import * as Sentry from '@sentry/react';

function AppUrlListener() {
  // eslint-disable-next-line
  const history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (event) => {
      Sentry.sendFeedback(
        {
          name: 'Test Message',
          email: 'apple@aiheroes.io',
          message: 'App is open',
        },
        {
          includeReplay: true,
        },
      );

      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('.app').pop();

      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
}

export default AppUrlListener;
