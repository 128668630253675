import React from 'react';

function Legend() {
  return (
    <div className="legend">
      <div className="item avoid">
        Avoid
      </div>
      <div className="item mixed">
        Mixed
      </div>
      <div className="item good">
        Good
      </div>
      <div className="item best">
        Best
      </div>
    </div>
  );
}

export default Legend;
