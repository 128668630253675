import React from 'react';
import './Recommendations.scss';
import PropTypes from 'prop-types';

function BestMonths({ months }) {
  const monthsList = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

  return (
    <div className="best-months">
      {monthsList.map((month) => (
        <div key={month} className={`interval ${months[month]}`}>
          {/* first 3 characters */}
          {month.slice(0, 3)}
        </div>
      ))}
    </div>
  );
}

BestMonths.propTypes = {
  // eslint-disable-next-line
  months: PropTypes.object.isRequired,
};

export default BestMonths;
