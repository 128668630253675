import React, {
  createRef, useContext, useEffect, useState,
} from 'react';
import { Button } from '@mui/material';
import { FiUserPlus, FiLock } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Sheet from 'react-modal-sheet';
import {
  IonPage,
} from '@ionic/react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import LoginModal from '../../Components/LoginModal';
import OnboardingView from '../OnboardingView';

function LandingView() {
  const { isLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const page = createRef();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn]);

  const [loginSheetOpen, setLoginSheetOpen] = useState(false);
  const [signUpSheetOpen, setSignUpSheetOpen] = useState(false);

  const handleLoginClick = () => {
    setLoginSheetOpen(true);
  };

  const handleLoginClose = () => {
    setLoginSheetOpen(false);
  };

  const handleSignUpClick = () => {
    setSignUpSheetOpen(true);
  };

  const handleSignUpClose = () => {
    setSignUpSheetOpen(false);
  };

  return (
    <IonPage ref={page}>
      <div className="landing-page">
        <div className="video-background">
          <video autoPlay muted loop id="myVideo" playsInline>
            <source src="/beach-video.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="landing-page-content">
          <img className="landing-page-trabu-shape" src="/trabu-icon-white.svg" alt="Trabu icon" />
          <img src="/trabu_logo_light.png" alt="Trabu Logo" className="logo" />
          <h1>
            Perfect Family Adventures, Perfectly Timed
          </h1>
          <p>
            Discover and plan your dream holidays with Trabu&apos;s personalised ideas,
            creating a family travel bucket list that is tailored to your
            family&apos;s ages, interests, and travel aspirations. Powered with AI.
          </p>
          <Button
            onClick={handleSignUpClick}
            startIcon={<FiUserPlus />}
            className="btn btn-orange btn-long"
            type="button"
          >
            Create Account
          </Button>
          <Button
            onClick={handleLoginClick}
            sx={{ mt: 2 }}
            startIcon={<FiLock />}
            className="btn btn-long btn-white-border"
            type="button"
          >
            Log in
          </Button>
          <LoginModal close={handleLoginClose} open={loginSheetOpen} page={page} />
          <Sheet
            isOpen={signUpSheetOpen}
            onClose={handleSignUpClose}
          >
            <Sheet.Container>
              <Sheet.Content>
                <Sheet.Scroller>
                  {/* Your sheet content goes here */}
                  <OnboardingView close={handleSignUpClose} />
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onTap={handleSignUpClose} />
          </Sheet>
        </div>
      </div>
    </IonPage>
  );
}

export default LandingView;
