import React, {
  createContext, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { mapToAuthRequest } from '../../Utils/RequestUtils';
import { REFRESH_URL, REQUEST_URL, DELETE_ACCOUNT } from '../../Constants/URLS';

const FRONTEND_ACCESS_TOKEN = 'trabu_frontend_access_token';
const FRONTEND_REFRESH_TOKEN = 'trabu_frontend_refresh_token';

export const UserContext = createContext();

export default function UserProvider({ children }) {
  function getAccessTokenLocal() {
    return {
      accessToken: localStorage.getItem(FRONTEND_ACCESS_TOKEN) || '',
      refreshToken: localStorage.getItem(FRONTEND_REFRESH_TOKEN) || '',
    };
  }

  const [tokens, setTokens] = useState(getAccessTokenLocal());
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const isLoggedIn = useMemo(() => !!tokens?.accessToken, [tokens]);
  const isEmailVerified = useMemo(() => !!(isLoggedIn && user?.email_verified), [isLoggedIn, user]);
  const isOnboardingCompleted = useMemo(
    () => !!(isEmailVerified && user?.onboarding_completed),
    [isEmailVerified, user],
  );

  function setLocalAccessToken({ accessToken, refreshToken }) {
    localStorage[FRONTEND_ACCESS_TOKEN] = accessToken;
    localStorage[FRONTEND_REFRESH_TOKEN] = refreshToken;
  }

  async function getConfig() {
    const credential = getAccessTokenLocal();
    return {
      headers: {
        Authorization: `Bearer ${credential?.accessToken ?? ''}`,
      },
    };
  }

  function storeTokens(accessToken, refreshToken) {
    setTokens({
      accessToken,
      refreshToken,
    });
  }

  useEffect(() => {
    if (!tokens.accessToken) {
      setLoading(false);
    }
  }, [tokens]);

  function logout() {
    // TODO: invalidate token in api
    setTokens({
      accessToken: '',
      refreshToken: '',
    });
    setLoading(false);
  }

  function refresh() {
    const credential = getAccessTokenLocal();
    return axios
      .post(`${REFRESH_URL}`, { refresh: credential.refreshToken })
      .then((response) => {
        storeTokens(response.data.access, response.data.refresh);
        return {
          headers: {
            Authorization: `Bearer ${response.data.access}`,
          },
        };
      }).catch(() => {
        logout();
        setLoading(false);
      });
  }

  function getUserInfo() {
    function mapToAuthRequestLoc(request) {
      return mapToAuthRequest(request, getConfig, refresh, logout);
    }

    const request = (config) => axios.get(`${REQUEST_URL}`, config);
    return mapToAuthRequestLoc(request)
      .then((result) => {
        setUser(result.data);
        Sentry.setUser({
          email: result.data.email,
          fullName: `${result.data.first_name} ${result.data.last_name}`,
        });
        setLoading(false);
      });
  }

  useEffect(() => {
    setLocalAccessToken(tokens);
    if (tokens.accessToken.length > 0 && tokens.refreshToken.length > 0) {
      getUserInfo();
    }
  }, [tokens]);

  async function deleteAccount() {
    const config = await getConfig();
    return axios.delete(DELETE_ACCOUNT, config)
      .then(() => {
        logout();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Account deletion failed', error);
        throw error;
      });
  }

  const [userData] = useState({
    storeTokens,
    logout,
    getConfig,
    deleteAccount,
    getUserInfo,
  });

  return (
    <UserContext.Provider
      value={{
        ...userData,
        user,
        tokens,
        refresh,
        isLoggedIn,
        isOnboardingCompleted,
        isEmailVerified,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
