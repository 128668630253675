import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';

function OnboardingName({
  firstName, setFirstName, lastName, setLastName, nextStep,
}) {
  return (
    <div className="onboarding-name">
      <h1>
        <span>Welcome!</span>
        What&apos;s your name?
      </h1>
      <input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <Button
        className="btn btn-teal"
        startIcon={<IconArrowRight />}
        sx={{ mt: 3 }}
        onClick={nextStep}
        disabled={!firstName || !lastName}
      >
        Next
      </Button>
    </div>
  );
}

OnboardingName.propTypes = {
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default OnboardingName;
