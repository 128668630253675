import Swal from 'sweetalert2';

export default function loginReducer(state, action) {
  switch (action.type) {
    case 'USERNAME':
      return { ...state, username: action.payload };
    case 'PASSWORD':
      return { ...state, password: action.payload };
    case 'VALIDATE': {
      if (state.username.length === 0 || state.password.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please fill in the email address and password.',
          confirmButtonText: 'Close',
        });
      }
      const usernameError = state.username.length === 0 ? 'Must provide a username' : '';
      const passwordError = state.password.length === 0 ? 'Must provide a password' : '';

      return {
        ...state,
        usernameError,
        passwordError,
        canSubmit: !usernameError && !passwordError,
        fullFieldError: '',
      };
    }
    case 'SUBMIT':
      return { ...state, canSubmit: false, submitting: action.payload };
    case 'HANDLE_ERROR': {
      const errors = {};
      // django naming... makes this a bit uncomfortable
      const nonFieldErrors = action.payload.non_field_errors;
      if (nonFieldErrors) {
        [errors.fullFieldError] = nonFieldErrors;
      }

      return { ...state, ...errors };
    }
    default:
      throw new Error(`${action.type}unknown`);
  }
}
