import React from 'react';

function NoSearchResult() {
  return (
    <div className="no-search-result">
      <h1>Search Result Empty</h1>
      <p>There are no trips that match your criteria. Click the filter above to modify search.</p>
      <img src="/undraw_Traveling_yhxq.png" alt="NoSearchResult" />
    </div>
  );
}

export default NoSearchResult;
