import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';

function ComingSoon({ back }) {
  return (
    <div className="coming-soon">
      <h1>Coming Soon</h1>
      <p>This feature is still under construction and will be released during a future update.</p>
      <Button className="btn btn-orange btn-long" startIcon={<IconArrowLeft />} onClick={back}>
        Go Back
      </Button>
      <img src="/construction.svg" alt="Construction" />
    </div>
  );
}

ComingSoon.propTypes = {
  back: PropTypes.func.isRequired,
};

export default ComingSoon;
