import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './NavigationBar.scss';
import { IconCalendarMonth, IconHeart, IconUserCircle } from '@tabler/icons-react';
import Pages from '../../Constants/pages';
import TrabuIcon from '../Icons/TrabuIcon';

function NavigationBar() {
  const location = useLocation();
  const [pages, setPages] = useState(null);
  const history = useHistory();
  useEffect(() => {
    setPages(Pages);
  }, []);

  // Check if the current page should show the navigation bar
  const shouldShowNavBar = () => {
    if (!pages) return false;
    const currentPageKey = Object.keys(pages).find((key) => pages[key].path === location.pathname);
    return currentPageKey ? pages[currentPageKey].showMenu : true;
  };

  function isActive(page) {
    if (!pages) return false;
    if (!pages[page]) return false;
    return location.pathname === pages[page].path;
  }

  function isActiveMultiple(pagesList) {
    // eslint-disable-next-line no-restricted-syntax
    for (const page of pagesList) {
      if (isActive(page)) {
        return true;
      }
    }
    return false;
  }

  // Only render the navigation bar if shouldShowNavBar is true
  return shouldShowNavBar() && (
    <>
      <div className="trabu-toolbar">
        <button type="button" onClick={() => history.push('/ideas')} className={`toolbar-icon ${isActive('ideas') && 'active'}`}>
          <TrabuIcon />
          {/* Ideas */}
        </button>
        <button type="button" onClick={() => history.push('/bucketlist')} className={`toolbar-icon ${isActive('bucket_list') && 'active'}`}>
          <IconHeart />
          {/* Saved */}
        </button>
        <button type="button" onClick={() => history.push('/calendar-2')} className={`toolbar-icon ${isActiveMultiple(['calendar1', 'calendar2']) && 'active'}`}>
          <IconCalendarMonth />
          {/* Calendar */}
        </button>
        <button type="button" onClick={() => history.push('/profile')} className={`toolbar-icon ${isActive('profile') && 'active'}`}>
          <IconUserCircle />
          {/* Profile */}
        </button>
      </div>
    </>
  );
}
export default NavigationBar;
