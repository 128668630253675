import React, {
  createContext, useState,
} from 'react';
import PropTypes from 'prop-types';

export const ConfettiContext = createContext();

export default function ConfettiProvider({ children }) {
  const [confetti, setConfetti] = useState(false);

  function doConfetti() {
    setConfetti(true);
    setTimeout(() => setConfetti(false), 2000);
  }

  return (
    <ConfettiContext.Provider value={{ confetti, doConfetti }}>
      {children}
    </ConfettiContext.Provider>
  );
}

ConfettiProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
