import React, { useState } from 'react';
import PropTypes from 'prop-types';

function Tabs3({
  tab1,
  tab2,
  tab3,
  initial,
}) {
  const [tab, setTab] = useState(initial);

  return (
    <div className="tabs-3">
      <div className={`tab-bar tab-${tab}`}>
        <button type="button" className={`tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)} disabled={tab1.disabled || !tab1.content}>{tab1.title}</button>
        <button type="button" className={`tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)} disabled={tab2.disabled || !tab2.content}>{tab2.title}</button>
        <button type="button" className={`tab ${tab === 3 ? 'active' : ''}`} onClick={() => setTab(3)} disabled={tab3.disabled || !tab3.content}>{tab3.title}</button>
      </div>
      <div className="tab-content">
        {tab === 1 && tab1.content}
        {tab === 2 && tab2.content}
        {tab === 3 && tab3.content}
      </div>
    </div>
  );
}

Tabs3.propTypes = {
  tab1: PropTypes.shape({
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    content: PropTypes.node,
  }).isRequired,
  tab2: PropTypes.shape({
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    content: PropTypes.node,
  }).isRequired,
  tab3: PropTypes.shape({
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    content: PropTypes.node,
  }).isRequired,
  initial: PropTypes.number,
};

Tabs3.defaultProps = {
  initial: 1,
};

export default Tabs3;
