/**
 * Determines the appropriate image URL based on the presence of an HTTP URL in compressed_1500_url.
 * If no valid HTTP URL is present, it falls back to image_file or a default placeholder.
 * @param {object} imageData - The object containing image URLs and properties.
 * @returns {string} - The URL to be used for the image source.
 */
const getImageUrl = (imageData) => {
  if (imageData &&
    imageData?.compressed_1500_url &&
    imageData?.compressed_1500_url.startsWith('http')) {
    return imageData.compressed_1500_url;
  }
  if (imageData && imageData?.image_file) {
    return imageData.image_file;
  }
  // Default placeholder if neither option is valid
  return '/placeholder.jpg';
};

export default getImageUrl;
