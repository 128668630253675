import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import React, { useRef } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { IconMap } from '@tabler/icons-react';

import OverviewHeaderCard from '../OverviewHeaderCard/OverviewHeaderCard';
import {
  DESTINATION_PATH,
  EXPERIENCE_PATH,
} from '../../Constants/staticConstants';
import OverviewListCard from '../OverviewCard/OverviewListCard';
import './TripAccordion.scss';

function TripAccordion({
  accordionChange,
  blockitem,
  blockList,
  expanded,
  history,
}) {
  const detailsRef = useRef(null);

  return (
    <Accordion
      key={`${blockitem?.destination ?? ''}${blockList?.title ?? ''}`}
      expanded={
        expanded === `${blockitem?.destination ?? ''}${blockList?.title ?? ''}`
      }
      onChange={() => accordionChange(blockitem, blockList)}
      className="accordion"
    >
      <AccordionSummary
        className="accordion-summary"
        data-testid={`accordion-summary-${blockitem?.destination}-${blockList?.title}`}
      >
        <OverviewHeaderCard
          item={{
            id: `${blockitem?.destination ?? ''}${blockList?.title ?? ''}`,
            image: blockitem?.destinationDetail?.image,
          }}
        />
        <div className="header-card">
          <div className="header-left">
            <IconMap className="icon" />
            <button
              className="titletext"
              type="button"
              onClick={() =>
                history.push(`${DESTINATION_PATH}${blockitem?.destination}`)
              }
              style={{ color: 'white' }}
            >
              <span>{blockitem?.destinationDetail?.name}</span>
            </button>
          </div>
          <div className="header-right">
            {expanded ===
            `${blockitem?.destination ?? ''}${blockList?.title ?? ''}` ? (
              <MdExpandLess className="expand-less" />
            ) : (
              <MdExpandMore className="expand-more" />
            )}{' '}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails ref={detailsRef} className="accordion-details">
        <Grid container spacing={1.1}>
          {blockitem?.days &&
            blockitem?.days.map((dayList) => (
              <>
                {dayList &&
                  dayList?.list.map((item) =>
                    item?.experience && item?.experienceDetails ? (
                      <Grid
                        item
                        xs={6}
                        key={item?.experience}
                        onClick={() => {
                          history.push(`${EXPERIENCE_PATH}${item?.experience}`);
                        }}
                      >
                        <OverviewListCard
                          item={{
                            id: item?.experience || item?.experienceDetails?.id,
                            image: item?.experienceDetails?.image,
                            name: item?.experienceDetails?.experience?.name,
                          }}
                        />
                      </Grid>
                    ) : (
                      <></>
                    ),
                  )}
              </>
            ))}

          {blockitem?.list &&
            blockitem?.list.map((item) =>
              item?.experience && item?.experienceDetails ? (
                <Grid
                  item
                  xs={6}
                  key={item?.experience}
                  onClick={() => {
                    history.push(`${EXPERIENCE_PATH}${item?.experience}`);
                  }}
                >
                  <OverviewListCard
                    item={{
                      id: item?.experience || item?.experienceDetails?.id,
                      image: item?.experienceDetails?.image,
                      name: item?.experienceDetails?.experience?.name,
                    }}
                  />
                </Grid>
              ) : (
                <></>
              ),
            )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default TripAccordion;
