import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import OnboardingDiscover from '../../Components/Onboarding/OnboardingDiscover';
import OnboardingLocation from '../../Components/Onboarding/OnboardingLocation';
import OnboardingCompanions from '../../Components/Onboarding/OnboardingCompanions';

function AuthenticatedOnboarding() {
  const [step, setStep] = useState(1);
  const [selectedAirports, setSelectedAirports] = useState([]);
  const { isEmailVerified, isOnboardingCompleted } = useState(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!isEmailVerified || isOnboardingCompleted) {
      history.push('/');
    }
  }, [isEmailVerified, isOnboardingCompleted]);

  return (
    <IonPage>
      <IonContent>
        <div className="authenticated-onboarding">
          <div className="page-header">
            <img src="/trabu_logo_light.png" alt="Trabu logo" />
          </div>
          <div className="onboarding-content">
            <img src="/trabu-icon-black.svg" className="trabu-icon" alt="Icon" />
            {step === 1 && (
              <OnboardingDiscover ready={() => setStep(2)} />
            )}
            {step === 2 && (
              <OnboardingLocation
                nextStep={() => setStep(3)}
                back={() => setStep(1)}
                selectedAirports={selectedAirports}
                setSelectedAirports={setSelectedAirports}
              />
            )}
            {step === 3 && (
              <OnboardingCompanions back={() => setStep(2)} />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default AuthenticatedOnboarding;
