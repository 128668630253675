import { configureStore } from '@reduxjs/toolkit';

import tripReducer from './trip/tripSlice';
import schedulingReducer from './scheduling/schedulingSlice';

export const store = configureStore({
  reducer: {
    trip: tripReducer,
    scheduling: schedulingReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store



