import React from 'react';

function FakeCard() {
  // random image height between 150 and 300
  const randomHeight = Math.floor(Math.random() * 150) + 150;

  return (
    <div className="trip-fake-card">
      <div
        className="trip-card-image"
        style={{ height: `${randomHeight}px` }}
      />
      <div className="placeholder-title" />
    </div>
  );
}

export default FakeCard;
