import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import { Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './ProfileSettings.scss';
import { API_URL } from '../../Constants/URLS';

function Name({ open, close }) {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    if (userContext.user && userContext.user.first_name) {
      setFirstName(userContext.user.first_name);
      setLastName(userContext.user.last_name);
    }
  }, [userContext, open]);

  const updateName = () => {
    if (!firstName || !lastName) {
      Swal.fire('Error', 'Please enter your first and last name.', 'error');
      return;
    }
    if (firstName === userContext.user.first_name && lastName === userContext.user.last_name) {
      close();
      return;
    }
    api.patch(`${API_URL}/dj-rest-auth/user/`, { first_name: firstName, last_name: lastName })
      .then(() => {
        userContext.getUserInfo();
        Swal.fire('Success', 'Name updated successfully.', 'success');
        close();
      });
  };

  return (
    <Sheet snapPoints={[0.95]} isOpen={open} onClose={close}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="settings">
            <h1>Update Name</h1>
            <label>First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="E.g. John"
            />
            <label>Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="E.g. Smith"
            />
            <Button
              className="btn btn-orange"
              startIcon={<FaSave />}
              sx={{ mt: 3 }}
              onClick={updateName}
            >
              Save
            </Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={close} />
    </Sheet>
  );
}

Name.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default Name;
