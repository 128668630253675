import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import { IconHeart } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useIonViewDidEnter } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import Swal from 'sweetalert2';
import { TRIPS_URL, REACTIONS_URL } from '../../../Constants/URLS';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import getImageUrl from '../../Images/ImageUtils';
import { ConfettiContext } from '../../../Providers/ConfettiProvider/ConfettiProvider';
import useEventTrack from 'hooks/useEventTrack';
import TrackEvent from 'Constants/eventConstant';
import TrackError from 'Constants/errorEventConstant';

function TripIdea({ idea, next }) {
  const [reactionStatus, setReactionStatus] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const history = useHistory();
  const imageRef = useRef();
  const [isImageVisible, setImageVisible] = useState(false);
  const [images, setImages] = useState([]);
  const { doConfetti } = useContext(ConfettiContext);

  const fetchReactionStatus = () => {
    // Updated to include the model name in the request
    api
      .get(`${REACTIONS_URL}?model=trip&object_id=${idea.id}`)
      .then((response) => {
        const savedReaction = response.data.find((r) => r.reaction === 'saved');
        setReactionStatus(savedReaction ? 'saved' : null);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching reaction status:', error);
      });
  };

  const updateImages = () => {
    if (imageRef.current) {
      const newImages = [
        {
          original: getImageUrl(idea.image),
          renderItem: () => (
            <img
              src={getImageUrl(idea.image)}
              style={{
                objectPosition: `${idea.image?.interest_x || 50}% ${idea.image?.interest_y || 50}%`,
              }}
              className="image-gallery-image"
              alt={idea.image?.caption || 'Placeholder'}
            />
          ),
        },
        ...idea.additional_images.map((img) => ({
          original: getImageUrl(img),
          renderItem: () => (
            <img
              src={getImageUrl(img)}
              style={{
                objectPosition: `${img.interest_x}% ${img.interest_y}%`,
              }}
              className="image-gallery-image"
              alt={img.caption || 'Placeholder'}
            />
          ),
        })),
      ];
      setImages(newImages);
    }
  };

  const { trackEvent, trackError } = useEventTrack();

  useEffect(() => {
    if (idea) {
      updateImages();
    }
  }, [idea]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateImages();
    });

    if (imageRef.current) {
      resizeObserver.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        resizeObserver.unobserve(imageRef.current);
      }
    };
  }, [imageRef.current]);

  function scrollToNext() {
    if (next) {
      const element = document.getElementById(`idea-${next}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  const toggleLoved = () => {
    if (reactionStatus === 'saved') {
      api
        .post(`${TRIPS_URL}${idea.id}/remove_reaction/`)
        .then(() => {
          trackEvent(TrackEvent.REACTION_REMOVED, {
            type: 'trip-idea',
            tripIdeaId: idea.id,
          })
          setReactionStatus(null);
        })
        .catch(() => {
          trackError(TrackError.REACTION_REMOVED, {
            type: 'trip-idea',
            tripIdeaId: idea.id,
          })
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to remove reaction. Please try again.',
          });
        });
      return;
    }
    api
      .post(`${TRIPS_URL}${idea.id}/save/`)
      .then(() => {
        trackEvent(TrackEvent.REACTION_ADDED, {
          type: 'trip-idea',
          tripIdeaId: idea.id,
        })
        setReactionStatus('saved');
        doConfetti();
        scrollToNext();
      })
      .catch(() => {
        trackError(TrackError.REACTION_SAVED, {
          type: 'trip-idea',
          tripIdeaId: idea.id,
        })
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save the trip. Please try again.',
        });
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImageVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '1000px 0px 0px 0px', // Top, right, bottom, left margins
        threshold: 0,
      },
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchReactionStatus();
  }, [idea]);

  useIonViewDidEnter(() => {
    fetchReactionStatus();
  });

  return (
    // eslint-disable-next-line
    <div
      className="idea"
      ref={imageRef}
      id={`idea-${idea.id}`}
      onClick={() => history.push(`/app/trip/${idea.id}`)}
    >
      {/* Image loader */}
      {isImageVisible && (
        <>
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showBullets
            showNav={false}
            autoPlay={false}
            imageHeight="250"
            additionalClass="idea-gallery"
            lazyLoad
            useBrowserFullscreen={false}
            stopPropagation
          />
          <h2>
            {idea.name
              ? idea.name
              : `${idea.trip_template.description} : ${idea.number_of_days} days`}
          </h2>
          <Button
            className={`btn btn-icon ${reactionStatus === 'saved' ? 'btn-orange' : 'btn-white'}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleLoved();
            }}
          >
            <IconHeart />
            {/* {reactionStatus === 'saved' ? <IconHeartOff /> : <IconHeart />} */}
          </Button>
        </>
      )}
    </div>
  );
}

TripIdea.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    approval_granted: PropTypes.bool,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      caption: PropTypes.string,
      compressed_1500_url: PropTypes.string,
      compressed_1000_url: PropTypes.string,
      location: PropTypes.string,
      interest_x: PropTypes.number,
      interest_y: PropTypes.number,
    }),
    additional_images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        caption: PropTypes.string,
        compressed_1500_url: PropTypes.string,
        compressed_1000_url: PropTypes.string,
        location: PropTypes.string,
      }),
    ),
    trip_template: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
    number_of_days: PropTypes.number.isRequired,
  }).isRequired,

  next: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TripIdea.defaultProps = {
  next: null,
};

export default TripIdea;
