import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { INTEREST_CATEGORIES_URL } from '../../Constants/URLS';

export const IdeasFilterContext = createContext();

export default function IdeasFilterProvider({ children }) {
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedInterestCategories, setSelectedInterestCategories] = useState(
    [],
  );
  const [tripsDays, setTripsDays] = useState([0, 20]);
  const [countriesSelection, setCountriesSelection] = useState(true);

  const [childrenAges, setChildrenAges] = useState([]);
  const ageBands = ['0-2', '3-5', '6-8', '9-11', '12-15', '16-18', '19-21'];
  const [interestCategories, setInterestCategories] = useState(null);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  // const budgets = ['Economy', 'Classic', 'Luxury', 'Elite'];
  const [types, setTypes] = useState([]);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const isActive =
    selectedMonths.length > 0 ||
    selectedInterestCategories.length > 0 ||
    tripsDays[0] > 0 ||
    tripsDays[1] < 20 ||
    childrenAges.length > 0 ||
    types.length > 0;

  const toggleChildrenAge = (age) => {
    if (childrenAges.includes(age)) {
      setChildrenAges(childrenAges.filter((a) => a !== age));
    } else {
      setChildrenAges([...childrenAges, age]);
    }
  };

  function toggleCountry(countrySelect) {
    setCountriesSelection(countrySelect);
  }

  function toggleMonth(month) {
    if (selectedMonths.includes(month)) {
      setSelectedMonths(selectedMonths.filter((m) => m !== month));
    } else {
      setSelectedMonths([...selectedMonths, month]);
    }
  }

  function selectTripMonths(month) {
    const newMonths = Array.isArray(month) ? month : [month];
    setSelectedMonths(newMonths);
  }

  function toggleInterestCategory(category) {
    if (selectedInterestCategories.includes(category)) {
      setSelectedInterestCategories(
        selectedInterestCategories.filter((c) => c !== category),
      );
    } else {
      setSelectedInterestCategories([...selectedInterestCategories, category]);
    }
  }

  function resetFilters() {
    setSelectedMonths([]); // Resets the selected months
    setSelectedInterestCategories([]); // Resets the selected interest categories
    setTripsDays([0, 20]); // Resets the trip days to default
    setChildrenAges([]); // Resets the ages of children
    setTypes([]); // Resets the types
  }

  useEffect(() => {
    if (selectedMonths.length === 12) {
      setSelectedMonths([]);
    }
  }, [selectedMonths]);

  useEffect(() => {
    if (
      interestCategories &&
      selectedInterestCategories.length === interestCategories.length
    ) {
      setSelectedInterestCategories([]);
    }
  }, [interestCategories, selectedInterestCategories]);

  useEffect(() => {
    const userEmail = userContext.user?.email;
    if (userEmail) {
      api.get(INTEREST_CATEGORIES_URL).then((response) => {
        setInterestCategories(response.data.map((category) => category.name));
      });
    }
  }, [userContext.user?.email]);

  const makeCountriesFilter = () => `&countries_only=${countriesSelection}`;

  const makeMonthsFilter = () => {
    let filter = '';
    selectedMonths.forEach((month) => {
      filter += `&months=${month}`;
    });
    return filter;
  };

  const makeInterestCategoriesFilter = () => {
    let filter = '';
    selectedInterestCategories.forEach((category) => {
      const newCategory = encodeURIComponent(category);
      filter += `&interest_categories=${newCategory}`;
    });
    return filter;
  };

  const makeDaysFilter = () =>
    `&min_days=${tripsDays[0]}&max_days=${tripsDays[1]}`;

  const makeChildrenAgesFilter = () => {
    let filter = '';
    childrenAges.forEach((age) => {
      filter += `&children_ages=${age}`;
    });
    return filter;
  };

  const toggleType = (type) => {
    if (types.includes(type)) {
      setTypes(types.filter((t) => t !== type));
    } else {
      setTypes([...types, type]);
    }
  };

  function selectTripType() {
    setTypes(['trip']);
  }

  const makeTypesFilter = () => {
    let filter = '';
    if (types && types.length > 0) {
      types.forEach((type) => {
        filter += `&type=${type}`;
      });
    }

    return filter;
  };

  useEffect(() => {
    if (types.length === 3) {
      setTypes([]);
    }
  }, [types]);

  return (
    <IdeasFilterContext.Provider
      value={{
        selectedMonths,
        selectedInterestCategories,
        tripsDays,
        setTripsDays,

        isActive,
        ageBands,
        toggleChildrenAge,
        months,

        interestCategories,

        toggleMonth,
        selectTripMonths,
        selectTripType,
        toggleInterestCategory,
        resetFilters,

        makeMonthsFilter,
        makeInterestCategoriesFilter,
        makeDaysFilter,
        childrenAges,
        makeChildrenAgesFilter,
        types,
        toggleType,
        makeTypesFilter,
        countriesSelection,
        makeCountriesFilter,
        toggleCountry,
      }}
    >
      {children}
    </IdeasFilterContext.Provider>
  );
}

IdeasFilterProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
