import React, { useState, useContext, useEffect } from 'react';
import { Button, Slider } from '@mui/material';
import { TbAdjustments } from 'react-icons/tb';
import {
  IonModal,
  IonTitle,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonPage,
} from '@ionic/react';
import { IconMapPin, IconPlaneTilt, IconSparkles } from '@tabler/icons-react';
import TripsIdeasTab from '../../Components/Ideas/Tabs/TripsIdeasTab';
import DiscoverView from '../DiscoverView';
import { IdeasFilterContext } from '../../Providers/IdeasFilterProvider/IdeasFilterProvider';
import SelectablePill from '../../Components/SelectablePill';
import { FROM_CALENDAR } from 'Constants/staticConstants';
import { useLocation } from 'react-router-dom';
import { lockScreenPortrait } from 'Utils/OrientationUtils';
import { useFullscreen } from 'Providers/FullscreenProvider/FullscreenProvider';

const TRIPS_TAB = 1;
const COUNTRIES_TAB = 2;
function IdeasView() {
  const [tab, setTab] = useState(TRIPS_TAB);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const filters = useContext(IdeasFilterContext);
  const location = useLocation();
  const { setIsFullscreen } = useFullscreen();

  useEffect(() => {
    lockScreenPortrait();
    setIsFullscreen(false);
    const innerState = location?.location?.state;
    if (innerState?.from === FROM_CALENDAR) {
      setTab(TRIPS_TAB);
    }
  }, [location?.location]);

  useEffect(() => {
    if (tab === COUNTRIES_TAB) {
      filters.toggleCountry(true);
    }
  }, [tab]);

  const resetFilters = () => {
    filters.resetFilters();
    setFiltersOpen(false);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="ideas-page">
          <div className="ideas-top-block">
            <img src="/trabu_logomark_white.svg" alt="Logo" />
            <div className={`tabs-2 active-${tab}`}>
              <button
                type="button"
                aria-label="Trips"
                onClick={() => setTab(TRIPS_TAB)}
              >
                Trips
              </button>
              <button
                type="button"
                aria-label="Discover"
                onClick={() => setTab(COUNTRIES_TAB)}
              >
                Countries
              </button>
            </div>
            <Button
              className={`btn btn-icon btn-orange btn-filters ${filters.isActive ? 'active' : ''}`}
              onClick={() => setFiltersOpen(true)}
            >
              <TbAdjustments />
            </Button>
          </div>
          {filters.interestCategories && (
            <IonModal isOpen={filtersOpen}>
              <IonHeader className="ideas-filters-header" mode="ios">
                <IonToolbar className="ideas-filters-header-toolbar">
                  <IonTitle>Filters</IonTitle>
                  <IonButtons slot="start">
                    <IonButton
                      className="ion-reset"
                      onClick={() => resetFilters()}
                    >
                      Reset
                    </IonButton>
                  </IonButtons>
                  <IonButtons slot="end">
                    <IonButton
                      className="ion-cancel"
                      onClick={() => setFiltersOpen(false)}
                    >
                      Close
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <div className="ideas-filters-content">
                  {tab === TRIPS_TAB && (
                    <>
                      <h3 style={{ marginBottom: 0 }}>Filter by Type</h3>
                      <h5>Discover Only</h5>
                      <SelectablePill
                        text={
                          <>
                            <IconPlaneTilt />
                            Trip
                          </>
                        }
                        selected={filters.types.includes('trip')}
                        onSelect={() => filters.toggleType('trip')}
                      />
                      <SelectablePill
                        text={
                          <>
                            <IconSparkles />
                            Experience
                          </>
                        }
                        selected={filters.types.includes('experience')}
                        onSelect={() => filters.toggleType('experience')}
                      />
                      <SelectablePill
                        text={
                          <>
                            <IconMapPin />
                            Place
                          </>
                        }
                        selected={filters.types.includes('place')}
                        onSelect={() => filters.toggleType('place')}
                      />
                    </>
                  )}
                  <h3>Filter by Month</h3>
                  {filters.months.map((month) => (
                    <SelectablePill
                      key={month}
                      text={month}
                      selected={filters.selectedMonths.includes(month)}
                      onSelect={() => filters.toggleMonth(month)}
                    />
                  ))}
                  {tab === TRIPS_TAB && (
                    <>
                      <h3>Filter by Interest Category</h3>
                      {filters.interestCategories.map((category) => (
                        <SelectablePill
                          key={category}
                          text={category}
                          selected={filters.selectedInterestCategories.includes(
                            category,
                          )}
                          onSelect={() =>
                            filters.toggleInterestCategory(category)
                          }
                        />
                      ))}
                    </>
                  )}
                  <h3>Filter by Children Ages</h3>
                  {filters.ageBands.map((category) => (
                    <SelectablePill
                      key={category}
                      text={category}
                      selected={filters.childrenAges.includes(category)}
                      onSelect={() => filters.toggleChildrenAge(category)}
                    />
                  ))}
                  {tab === TRIPS_TAB && (
                    <>
                      <h3>Suggested Trip Length</h3>
                      <div className="slider-container">
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={filters.tripsDays}
                          onChange={(_, newValue) =>
                            filters.setTripsDays(newValue)
                          }
                          valueLabelDisplay="auto"
                          getAriaValueText={() =>
                            `${filters.tripsDays[0]} to ${filters.tripsDays[1]} days`
                          }
                          min={1}
                          max={20}
                        />
                      </div>
                      <p>
                        {filters.tripsDays[0]} to {filters.tripsDays[1]} days
                      </p>
                    </>
                  )}
                </div>
              </IonContent>
            </IonModal>
          )}
          <div className="ideas-container">
            {tab === TRIPS_TAB && (
              <TripsIdeasTab
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
              />
            )}
            {tab === COUNTRIES_TAB && <DiscoverView />}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default IdeasView;
