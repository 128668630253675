import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Masonry } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonContent,
} from '@ionic/react';
import FakeCards from '../../Components/Saved/FakeCards';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import DiscoverCard from '../../Components/DiscoverCard';
import { IdeasFilterContext } from '../../Providers/IdeasFilterProvider/IdeasFilterProvider';
import NoSearchResult from '../../Components/NoSearchResult/Index';
import useAnalytics from '../../Utils/useAnalytics';

function DiscoverView() {
  const [discoverData, setDiscoverData] = useState(null);
  const [count, setCount] = useState(0);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const filters = useContext(IdeasFilterContext);

  const {setCurrentScreen, logEvent} = useAnalytics()
  useEffect(() => {
    setCurrentScreen("discover")
    logEvent({ name: "test_event", context: {example_property: "test"}})
  }, [setCurrentScreen, logEvent]);

  useEffect(() => {
    api
      .get(
        `${API_URL}/discover?page=1${filters.makeMonthsFilter()}${filters.makeInterestCategoriesFilter()}${filters.makeChildrenAgesFilter()}${filters.makeTypesFilter()}${filters.makeCountriesFilter()}`,
      )
      .then((response) => {
        setDiscoverData(response.data.results);
        setCount(response.data.count);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error', error);
      });
  }, [filters]);

  const loadMore = () => {
    if (discoverData.length < count && !loading) {
      setLoading(true);
      api
        .get(
          `${API_URL}/discover?page=${page + 1}${filters.makeMonthsFilter()}${filters.makeInterestCategoriesFilter()}${filters.makeChildrenAgesFilter()}${filters.makeTypesFilter()}${filters.makeCountriesFilter()}`,
        )
        .then((response) => {
          setDiscoverData([...discoverData, ...response.data.results]);
          setPage(page + 1);
          setLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error', error);
        });
    }
  };

  // Might need it later
  // eslint-disable-next-line
  const handleClick = (type, id) => {
    switch (type) {
      case 'trip':
        history.push(`/trip-details/${id}`);
        break;
      case 'city':
        history.push(`/location/destination/${id}`);
        break;
      case 'destination':
        history.push(`/location/destination/${id}`);
        break;
      case 'region':
        history.push(`/location/region/${id}`);
        break;
      case 'country':
        history.push(`/location/country/${id}`);
        break;
      case 'experience':
        history.push(`/experience/${id}`);
        break;
      default:
        break;
    }
  };

  return (
    <IonContent>
      <div className="discover-view">
        {!discoverData && <FakeCards />}
        {discoverData && discoverData.length > 0 ? (
          <div>
            <Masonry columns={2} spacing={1} className="masonry" sequential>
              {discoverData.map((item) => (
                <DiscoverCard
                  image={item.image}
                  title={item.name}
                  type={item.type}
                  id={item.id}
                  key={`${item.type}-${item.id}`}
                />
              ))}
            </Masonry>
            {count > 0 && discoverData.length < count && (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  loadMore();
                  setTimeout(() => ev.target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent />
              </IonInfiniteScroll>
            )}
          </div>
        ) : (
          <NoSearchResult />
        )}
      </div>
    </IonContent>
  );
}

export default DiscoverView;
