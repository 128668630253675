import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import getImageUrl from '../Images/ImageUtils';

export default ({ open, onClose, availableTrips, scheduleTrip }) =>
  (<Dialog
    maxWidth="xl"
    fullWidth
    data-testid="trip-schedule-modal"
    open={open}
    onClose={onClose}
  >
    <DialogTitle>Schedule Trip</DialogTitle>
    <DialogContent className="schedule-trip-dialog">
      {availableTrips ? (
        <>
          {availableTrips.length > 0 &&
            availableTrips.map((availableTrip) => {
              const { id, image, name } = availableTrip;
              return (
                // eslint-disable-next-line
                <div
                  className="schedule-trip"
                  onClick={() => scheduleTrip(availableTrip)}
                  role="button"
                  key={id}
                >
                  <div
                    className="schedule-trip-image"
                    style={{
                      backgroundImage: `url(${getImageUrl(image)})`,
                      backgroundPosition: `${image?.interest_x || 50}% ${image?.interest_y || 50}%`
                    }}
                  />
                  <div className="schedule-trip-name">{name}</div>
                </div>
              );
            })}
        </>
      ) : (
        <CircularProgress />
      )}
    </DialogContent>
  </Dialog>);
