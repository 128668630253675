import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import { Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './ProfileSettings.scss';
import { API_URL } from '../../Constants/URLS';

function Notification({ open, close }) {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const [notifications, setNotifications] = useState('');

  const options = [
    { value: 'ESSENTIAL', label: 'Essential notifications' },
    { value: 'ALL', label: 'All notifications' },
  ];

  useEffect(() => {
    if (userContext.user && userContext.user.phone_number) {
      setNotifications(userContext.user.notifications);
    }
  }, [userContext, open]);

  const updateEmailNotifications = () => {
    if (notifications === userContext.user.notifications) {
      close();
      return;
    }
    api.patch(`${API_URL}/dj-rest-auth/user/`, { notifications })
      .then(() => {
        userContext.getUserInfo();
        Swal.fire('Success', 'Notification settings updated successfully.', 'success');
        close();
      }).catch(() => {
        Swal.fire('Error', 'Failed to update notification settings. Please try again.', 'error');
      });
  };

  return (
    <Sheet snapPoints={[0.95]} isOpen={open} onClose={close}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="settings">
            <h1>Notifications</h1>
            <label>Email Notifications</label>
            {options.map((option) => (
              // eslint-disable-next-line
              <div
                key={option.value}
                className={`radio ${option.value === notifications ? 'selected' : ''}`}
                onClick={() => setNotifications(option.value)}
              >
                {option.label}
              </div>
            ))}
            <Button
              className="btn btn-orange"
              startIcon={<FaSave />}
              sx={{ mt: 3 }}
              onClick={updateEmailNotifications}
            >
              Save
            </Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={close} />
    </Sheet>
  );
}

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default Notification;
