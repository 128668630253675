import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { TbHeart, TbX } from 'react-icons/tb';
import PinchZoomRotateImage from 'Components/PinchZoomRotate/PinchZoomRotateImage';
import { useFlags } from 'flagsmith/react';
import { features } from '../../Constants/flags';
import {
  UnlockScreenOrientation,
  lockScreenPortrait,
} from 'Utils/OrientationUtils';

function GalleryHeader({
  images,
  title,
  toggleLoved,
  reaction,
  galleryRef,
  fullScreen,
  setFullScreen,
  sizeRef,
}) {
  const flags = useFlags([features.picture_gallery_v2]);

  const placeholderOnly =
    images.length === 1 && images[0].original === '/placeholder.jpg';

  const toggleFullScreen = async () => {
    galleryRef.current.toggleFullScreen();
    if (fullScreen) {
      lockScreenPortrait();
    }
  };

  const makeFullScreen = async () => {
    if (!fullScreen && !placeholderOnly) {
      galleryRef.current.toggleFullScreen();
      UnlockScreenOrientation();
    }
  };

  return (
    <div className="gallery-header" ref={sizeRef}>
      {flags.picture_gallery_v2.enabled ? (
        <div className={fullScreen && 'gallery-v2-enabled'}>
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showBullets
            showNav={false}
            autoPlay={false}
            imageHeight="250"
            additionalClass="sheet-gallery"
            ref={galleryRef}
            lazyLoad
            useBrowserFullscreen={false}
            onScreenChange={() => setFullScreen(!fullScreen)}
            // onTouchEnd={makeFullScreen}
            onClick={makeFullScreen}
            stopPropagation
            renderItem={(item) => (
              <>
                {fullScreen ? (
                  <PinchZoomRotateImage
                    src={item.original}
                    alt={item.caption || 'Image'}
                  />
                ) : (
                  <img src={item.original} alt={item.caption || 'Image'} />
                )}
              </>
            )}
          />
        </div>
      ) : (
        <ImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={false}
          showBullets
          showNav={false}
          autoPlay={false}
          imageHeight="250"
          additionalClass="sheet-gallery"
          ref={galleryRef}
          lazyLoad
          useBrowserFullscreen={false}
          onScreenChange={() => setFullScreen(!fullScreen)}
          // onTouchEnd={makeFullScreen}
          onClick={makeFullScreen}
          stopPropagation
          renderItem={(item) => (
            <>
              {fullScreen ? (
                <div className="defaultImgStyle">
                  <img
                    className="defaultInnerImgStyle"
                    src={item.original}
                    alt={item.caption || 'Image'}
                  />
                </div>
              ) : (
                <img src={item.original} alt={item.caption || 'Image'} />
              )}
            </>
          )}
        />
      )}
      <h3>{title}</h3>
      <button
        type="button"
        aria-label="heart"
        className={`heart-button ${reaction ? 'saved' : ''}`}
        onClick={toggleLoved}
      >
        <TbHeart />
        {/* {reaction ? <TbHeartOff /> : <TbHeart />} */}
      </button>
      {fullScreen && (
        // eslint-disable-next-line
        <div className="fullscreen-close-button" onClick={toggleFullScreen}>
          <TbX />
        </div>
      )}
    </div>
  );
}

GalleryHeader.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  toggleLoved: PropTypes.func.isRequired,
  reaction: PropTypes.string,
  galleryRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
  fullScreen: PropTypes.bool.isRequired,
  setFullScreen: PropTypes.func.isRequired,
};

GalleryHeader.defaultProps = {
  reaction: null,
};

export default GalleryHeader;
