import React from 'react';
import FakeCard from '../FakeCard';

function FakeCards() {
  // return 7 fake trip cards
  return (
    <>
      <FakeCard />
      <FakeCard />
      <FakeCard />
      <FakeCard />
      <FakeCard />
      <FakeCard />
    </>
  );
}

export default FakeCards;
