export const DESTINATION_PATH = '/app/location/destination/';
export const EXPERIENCE_PATH = '/app/experience/';
export const TRIP_PATH = 'trip/';
export const COUNTRY_PATH = '/app/location/country/';
export const FROM_CALENDAR = 'calendar';
export const MONTH_ORDER: { [key: string]: number } = {
  February: 2,
  'March/April': 3,
  May: 5,
  'July/August': 7,
  October: 10,
  'December/January': 12,
};
// TODO hardcoding till the time we get data from API

export const holidayToMonth: { [key: string]: string } = {
  'February Half Term': 'February',
  'Easter Holidays': 'March/April',
  'May Half Term': 'May',
  'Summer Holidays': 'July/August',
  'October Half Term': 'October',
  'Christmas Holidays': 'December/January',
};
export enum FetchStatus {
  // eslint-disable-next-line no-unused-vars
  IDLE = 'IDLE',
  // eslint-disable-next-line no-unused-vars
  IN_PROGRESS = 'IN_PROGRESS',
  // eslint-disable-next-line no-unused-vars
  SUCCEEDED = 'SUCCEEDED',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'FAILED'
};