import React, { useContext, useEffect } from 'react';
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import {
  IconCalendarMonth,
  IconHeart,
  IconUserCircle,
} from '@tabler/icons-react';
import IdeasView from './Views/IdeasView';
import TrabuIcon from './Components/Icons/TrabuIcon';
import TripInformationView from './Views/TripInformationView';
import ProfileView from './Views/ProfileView';
import Airports from './Views/Airports';
import CalendarTimelineView from './Views/CalendarTimelineView';
import LocationView from './Views/LocationView';
import ExperienceView from './Views/ExperienceView';
import BucketListView from './Views/BucketListView';
import FolderView from './Views/FolderView';
import { UserContext } from './Providers/UserProvider/UserProvider';
import SupportView from './Views/SupportView/SupportView';
import { ConfettiContext } from './Providers/ConfettiProvider/ConfettiProvider';
import { useFullscreen } from 'Providers/FullscreenProvider/FullscreenProvider';
import { useFlags } from 'flagsmith/react';
import { features } from 'Constants/flags';
import TermsAndConditions from 'Components/TermsAndConditions/TermsAndConditions';

export default function Tabs() {
  const { isOnboardingCompleted } = useContext(UserContext);
  const history = useHistory();
  const { isFullscreen } = useFullscreen();
  const flags = useFlags([features.terms_and_conditions_v2]);

  const { confetti } = useContext(ConfettiContext);

  useEffect(() => {
    if (!isOnboardingCompleted) {
      history.push('/');
    }
  }, [isOnboardingCompleted]);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/app/ideas" component={IdeasView} />
        <Route exact path="/app/bucketlist" component={BucketListView} />
        <Route exact path="/app/folder/:id" component={FolderView} detail />
        <Route exact path="/app/profile" component={ProfileView} />
        <Route exact path="/app/profile/airports" component={Airports} detail />
        <Route
          exact
          path="/app/trip/:tripId"
          component={TripInformationView}
          detail
        />
        <Route exact path="/app/calendar" component={CalendarTimelineView} />
        <Route exact path="/app/location/:type/:id" component={LocationView} />
        <Route exact path="/app/experience/:id" component={ExperienceView} />
        <Route exact path="/app/support" component={SupportView} />
        <Route exact path="/app">
          <Redirect to="/app/ideas" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className={isFullscreen ? 'hidden-tab-bar' : ''}>
        <IonTabButton tab="ideas" href="/app/ideas" class="tab-button">
          <TrabuIcon />
        </IonTabButton>
        <IonTabButton tab="saved" href="/app/bucketlist">
          <IconHeart />
          {confetti && (
            <img
              src="/confetti.gif"
              className="confetti-bucketlist"
              alt="Confetti"
            />
          )}
        </IonTabButton>
        <IonTabButton tab="calendar" href="/app/calendar">
          <IconCalendarMonth />
        </IonTabButton>
        <IonTabButton tab="profile" href="/app/profile">
          <IconUserCircle />
        </IonTabButton>
      </IonTabBar>
      {
        flags.terms_and_conditions_v2.enabled && (
          <TermsAndConditions />
        )
      }
    </IonTabs>
  );
}


