import React, { createContext } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';


export interface FirebaseContextInterface {
  app: FirebaseApp,
}

const firebaseConfig = {
  apiKey: 'AIzaSyC5xiZg9-HtbBUhkZptpSaIModV_1jz_q8',
  authDomain: 'trabu-staging.firebaseapp.com',
  projectId: 'trabu-staging',
  storageBucket: 'trabu-staging.appspot.com',
  messagingSenderId: '251008756066',
  appId: '1:251008756066:web:e5569015c082259e1f243b',
  measurementId: 'G-8P88EQH3YY',
  automaticDataCollectionEnabled: true
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const FirebaseContext = createContext<FirebaseContextInterface>(
  {
    app,
  });

type FirebaseProviderProps = React.PropsWithChildren<object>;

// eslint-disable-next-line no-unused-vars
export default function FirebaseProvider({ children }: FirebaseProviderProps) {

  const context: FirebaseContextInterface = {
    app,
  };

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
};