import mixpanel, { Dict } from 'mixpanel-browser';
import TrackEvent from 'Constants/eventConstant';

export const mixpanelToken = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT || 'local';
  switch (environment) {
    case 'local': {
      return 'a9831b0d742da84ab9d404d56978073c';
    }
    case 'production':
      return '85006e81c109187f9b65fcad15340d7f';
    case 'staging': {
      return '5c65a376adf837e1238dad4abfcfd600';
    }
    default: {
      return 'a9831b0d742da84ab9d404d56978073c';
    }
  }
};

// Initialize Mixpanel
mixpanel.init(mixpanelToken(), { track_pageview: "url-with-path-and-query-string" });

// Custom Hook for Mixpanel
// eslint-disable-next-line no-unused-vars
const useEventTrack = () => {
    // Track custom events
    const trackEvent = (eventName: TrackEvent, eventData?: Dict) => {
        // eslint-disable-next-line no-debugger
        // debugger;
        if (mixpanel) {
            mixpanel.track(eventName, eventData);
        } else {
            console.warn(`Mixpanel is not initialized for tracking event: ${eventName}`);
        }
    };

    // Track errors
    const trackError = (errorMessage: string, errorDetails?: Dict) => {
        // eslint-disable-next-line no-debugger
        // debugger;
        trackEvent(TrackEvent.ERROR_OCCURRED, { errorMessage, ...errorDetails });
    };

    // Return the tracking functions
    return { trackEvent, trackError };
};

export default useEventTrack;
