enum TrackError {
    // eslint-disable-next-line no-unused-vars
    REACTION_REMOVED = 'Error removing reaction',
    // eslint-disable-next-line no-unused-vars
    REACTION_SAVED = 'Error saving reaction',
    // eslint-disable-next-line no-unused-vars
    SCHEDULE_HOLIDAY = 'Error scheduling holiday'
}

export default TrackError;
