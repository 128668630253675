import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Button } from '@mui/material';
import {
  TbArrowLeft, TbPlaneDeparture, TbTrash,
} from 'react-icons/tb';
import { FaSave } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { IonContent, IonPage } from '@ionic/react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import PageHeading from '../../Components/PageHeading';

function Airports() {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const [airports, setAirports] = useState([]);
  const [search, setSearch] = useState('');
  const [airportsResults, setAirportsResults] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (userContext.user && userContext.user.airports) {
      setAirports(userContext.user.airports);
    }
  }, [userContext]);

  useEffect(() => {
    if (search.length > 2) {
      api.get(`${API_URL}/airports?search=${search}`).then((res) => {
        setAirportsResults(res.data);
      });
    }
  }, [search]);

  const toggleAirport = (airport) => {
    const newAirports = airports.find((a) => a.id === airport.id)
      ? airports.filter((a) => a.id !== airport.id)
      : [...airports, airport];
    setAirports(newAirports);
  };

  const updateAirports = () => {
    if (airports.length === 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select at least one airport',
        icon: 'error',
      });
      return;
    }
    const data = airports.map((airport) => airport.id);
    api.patch(`${API_URL}/dj-rest-auth/user/`, {
      airports: data,
    }).then(() => {
      userContext.getUserInfo();
      history.back();
    }).catch(() => {
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while updating your airports',
        icon: 'error',
      });
    });
  };

  return (
    <IonPage>
      <IonContent>
        <PageHeading title="Airports" icon={<TbPlaneDeparture />} />
        <div className="airports settings">
          <Button
            className="btn btn-icon btn-white"
            sx={{ mb: 3, mt: -1, ml: -1 }}
            onClick={() => history.back()}
          >
            <TbArrowLeft />
          </Button>
          <label>Currently Selected</label>
          {airports.length === 0 && <small>None</small>}
          <div className="selected-airports">
            {airports.map((airport) => (
              <div key={airport.id} className="selected-airport">
                <div className="airport-details">
                  <div className="airport-name">
                    <span className="airport-code">{airport.iata_code}</span>
                    {airport.name}
                  </div>
                  {`${airport.city}, ${airport.country}`}
                </div>
                <Button
                  className="delete-airport"
                  onClick={() => toggleAirport(airport)}
                >
                  <TbTrash />
                </Button>
              </div>
            ))}
          </div>
          <Button
            className="btn btn-orange"
            startIcon={<FaSave />}
            sx={{ mt: 2, mb: 2 }}
            onClick={updateAirports}
          >
            Save
          </Button>
          <label>Search Airport</label>
          <input
            type="text"
            placeholder="Search for an airport"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="search-results">
            {airportsResults.map((airport) => (
          // eslint-disable-next-line
          <div
            key={airport.id}
            className={`airport ${airports.find((a) => a.id === airport.id) ? 'selected' : ''}`}
            onClick={() => toggleAirport(airport)}
          >
            <div className="info">
              <span>{airport.name}</span>
              {`${airport.city}, ${airport.country}`}
            </div>
            <span className="code">
              <TbPlaneDeparture />
              {' '}
              {airport.iata_code}
            </span>
          </div>
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Airports;
