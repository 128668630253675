/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Country, ScheduledItem, SchedulingState } from './schedulingInterfaces';
import { FetchStatus } from '../../Constants/staticConstants';
import {
  ADD_SCHEDULED_ITEM_URL,
  AVAILABLE_HOLIDAYS_URL,
  REMOVE_SCHEDULED_ITEM_URL,
  SCHEDULED_ITEMS_URL
} from '../../Constants/URLS';
import scheduledItemMapper, { holidayMapper } from './scheduledItemMapper';
import { Holiday } from '../../Views/CalendarMonthView/interfaces';
import { RootState } from '../store';
import { TripDetails } from '../trip/tripInterfaces';

export const initialState: SchedulingState = {
  scheduledItems: [],
  holidays: [],
  retrieveHolidaysStatus: FetchStatus.IDLE,
  retrieveHolidaysError: undefined,
  retrieveScheduledItemsStatus: FetchStatus.IDLE,
  retrieveScheduledItemsError: undefined,
  selectedHolidayIdInScheduleItemModel: null,
  scheduleItemStatus: FetchStatus.IDLE,
  scheduleItemError: undefined,
  unscheduleItemStatus: FetchStatus.IDLE,
  unschedulItemError: undefined
};

export const retrieveScheduledItems = createAsyncThunk<
  ScheduledItem[],
  { api: any },
  { rejectValue: string }
>('scheduling/retrieveScheduledItems',
  async ({ api }: { api: any },
         { rejectWithValue }) => {
    try {
      const response = await api.get(SCHEDULED_ITEMS_URL);
      const scheduledItems: ScheduledItem[] = response.data?.map(scheduledItemMapper);
      return scheduledItems;
    } catch (error) {
      return rejectWithValue(error?.message || 'Error fetching scheduled items');
    }
  }
);

export const retrieveHolidays = createAsyncThunk<
  Holiday[],
  { api: any },
  { rejectValue: string }
>('scheduling/retrieveHolidays',
  async ({ api }: { api: any },
         { rejectWithValue }) => {
    try {
      const response = await api.get(AVAILABLE_HOLIDAYS_URL);
      const holidays: Holiday[] = response ? response.data?.map(holidayMapper) : [];
      return holidays;
    } catch (error) {
      // console.error(error);
      return rejectWithValue(error?.message || 'Error fetching holidays');
    }
  }
);

export const scheduleItem = createAsyncThunk<
  ScheduledItem,
  {
    api: any,
    item: TripDetails | Country,
    holidayId: number,
  },
  { rejectValue: string }
>('scheduling/scheduleItem',
  async ({
           api,
           item,
           holidayId
         },
         { rejectWithValue }) => {
    try {
      let body = {};

      if ('trip_template' in item || ('type' in item && (item.type === 'trip'))) {
        body = {
          holiday_id: holidayId,
          trip_id: item.id
        };
      } else {
        body = {
          holiday_id: holidayId,
          country_id: item.id
        };
      }
      const response = await api.post(ADD_SCHEDULED_ITEM_URL, body);
      const scheduledItem: ScheduledItem = scheduledItemMapper(response.data);
      return scheduledItem;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.message || 'Error scheduling item');
    }
  }
);


export const unscheduleItem = createAsyncThunk<
  ScheduledItem,
  {
    api: any,
    item: ScheduledItem,
  },
  { rejectValue: string }
>('scheduling/unscheduleItem',
  async ({
           api,
           item
         },
         { rejectWithValue }) => {
    try {
      const url = `${REMOVE_SCHEDULED_ITEM_URL}/${item.id}/delete/`;
      await api.del(url, {});

      return item;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.message || 'Error scheduling item');
    }
  }
);


export const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    selectHolidayInScheduleItemModal(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.selectedHolidayIdInScheduleItemModel = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveScheduledItems.pending, (state) => ({
        ...state,
        retrieveScheduledItemsStatus: FetchStatus.IN_PROGRESS,
        retrieveScheduledItemsError: undefined
      }))
      .addCase(
        retrieveScheduledItems.fulfilled,
        (state, action: PayloadAction<ScheduledItem[]>) => ({
          ...state,
          retrieveScheduledItemsStatus: FetchStatus.SUCCEEDED,
          retrieveScheduledItemsError: undefined,
          scheduledItems: action?.payload
        })
      )
      .addCase(retrieveScheduledItems.rejected,
        (state, action) => ({
          ...state,
          retrieveScheduledItemsStatus: FetchStatus.FAILED,
          retrieveScheduledItemsError: action.payload ? action.payload : action.error.message
        }))
      .addCase(scheduleItem.pending, (state) => ({
        ...state,
        scheduleItemStatus: FetchStatus.IN_PROGRESS,
        scheduleItemStatusError: undefined
      }))
      .addCase(
        scheduleItem.fulfilled,
        (state) => ({
          ...state,
          scheduleItemStatus: FetchStatus.SUCCEEDED,
          scheduleItemError: undefined
        })
      )
      .addCase(scheduleItem.rejected,
        (state, action) => ({
          ...state,
          scheduleItemStatus: FetchStatus.FAILED,
          scheduleItemError: action.payload ? action.payload : action.error.message
        }))
      .addCase(retrieveHolidays.pending, (state) => ({
        ...state,
        retrieveHolidaysStatus: FetchStatus.IN_PROGRESS,
        retrieveHolidaysError: undefined
      }))
      .addCase(
        retrieveHolidays.fulfilled,
        (state, action: PayloadAction<Holiday[]>) => ({
          ...state,
          retrieveHolidaysStatus: FetchStatus.SUCCEEDED,
          retrieveHolidaysError: undefined,
          holidays: action?.payload
        })
      )
      .addCase(retrieveHolidays.rejected,
        (state, action) => ({
          ...state,
          retrieveHolidaysStatus: FetchStatus.FAILED,
          retrieveHolidaysError: action.payload ? action.payload : action.error.message
        }))
      .addCase(unscheduleItem.pending, (state) => ({
        ...state,
        unscheduleItemStatus: FetchStatus.IN_PROGRESS,
        unscheduleItemError: undefined
      }))
      .addCase(
        unscheduleItem.fulfilled,
        (state) => ({
          ...state,
          unscheduleItemStatus: FetchStatus.SUCCEEDED,
          unscheduleItemError: undefined
        })
      )
      .addCase(unscheduleItem.rejected,
        (state, action) => ({
          ...state,
          unscheduleItemStatus: FetchStatus.FAILED,
          unscheduleItemError: action.payload ? action.payload : action.error.message
        }));
  }
});

export default schedulingSlice.reducer;
export const { selectHolidayInScheduleItemModal } = schedulingSlice.actions;

export const selectRetrieveScheduledItemsStatus =
  (state: SchedulingState): FetchStatus => state.retrieveScheduledItemsStatus;

export const selectHolidays =
  (state: RootState): Holiday[] => state.scheduling.holidays;

export const selectedHolidayId =
  (state: RootState): number | null => state.scheduling.selectedHolidayIdInScheduleItemModel;

export const scheduledItemsForHolidayWithId =
  (state: RootState, holidayId: number): ScheduledItem[] =>
    state.scheduling.scheduledItems.filter(item => item.holiday.id === holidayId);

export const schedulingDetailsForCountry =
  (state: RootState, countryId: number): ScheduledItem[] =>
    state.scheduling.scheduledItems.filter(item => item.country?.id?.toString() === countryId.toString());

export const selectScheduledItems = (state: RootState) => state.scheduling.scheduledItems;

const selectTripId = (state: RootState, tripId: number) => tripId;

export const schedulingDetailsForTrip = createSelector(
  [selectScheduledItems, selectTripId],
  (items: ScheduledItem[], tripId: number) => items?.length > 0 ? items.filter(item => item.trip?.trip?.id?.toString() === tripId.toString()) : []
);





