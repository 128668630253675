import React, {
  createRef,
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  IonButton, IonButtons, IonContent, IonHeader, IonModal,
  IonPage, IonTitle, IonToolbar, useIonViewWillEnter, IonSpinner,
} from '@ionic/react';
import {
  IconArrowLeft, IconCalendarTime, IconFolder, IconFolderOff, IconFolderShare,
  IconInfoCircle, IconSearch, IconStar, IconStarOff, IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Box, Button, Grid, TextField, InputAdornment,
} from '@mui/material';
import { Masonry } from '@mui/lab';
import Swal from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import { useFetchAllItems, useFetchDeleted, useFetchFavourites } from './folderUtils';
import FakeCards from '../../Components/Saved/FakeCards';
import BucketListCard from '../../Components/BucketList/BucketListCard';

function FolderView() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const isPreset = ['all', 'favourites', 'deleted'].includes(id);
  const [folder, setFolder] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [initialItems, setInitialItems] = useState(null);
  const [items, setItems] = useState(null);
  const fetchAllItems = useFetchAllItems();
  const fetchFavourites = useFetchFavourites();
  const fetchDeleted = useFetchDeleted();
  const [selecting, setSelecting] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pageRef = createRef();
  const [moveModal, setMoveModal] = useState(false);
  const [presentingElement, setPresentingElement] = useState(undefined);
  const modalRef = createRef();
  const [folders, setFolders] = useState([]);
  const [search, setSearch] = useState('');

  const dismissModal = () => {
    modalRef.current?.dismiss();
    setMoveModal(false);
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      setSelecting(false);
    }
  }, [selectedItems]);

  const title = () => {
    switch (id) {
      case 'all':
        return 'All items';
      case 'deleted':
        return 'Recently deleted';
      case 'favourites':
        return 'Favourites';
      default:
        return folder ? folder.name : 'Folder';
    }
  };

  const fetchItems = () => {
    if (isPreset) {
      const fetchItemsAsync = async () => {
        switch (id) {
          case 'all':
            return fetchAllItems();
          case 'deleted':
            return fetchDeleted();
          default:
            return fetchFavourites();
        }
      };

      fetchItemsAsync().then((i) => setInitialItems(i));
    }
  };

  useEffect(() => {
    if (initialItems) {
      setItems(initialItems);
    }
  }, [initialItems]);

  const filterItems = () => {
    if (!search) {
      setItems(initialItems);
    } else {
      const newItems = initialItems.filter(
        (item) => item.name.toLowerCase().includes(search.toLowerCase()),
      );
      selectedItems.forEach((selectedItem) => {
        if (!newItems.find((item) => item.reaction_id === selectedItem)) {
          setSelectedItems(selectedItems.filter((i) => i !== selectedItem));
        }
      });
      setItems(newItems);
    }
  };

  useEffect(() => {
    filterItems();
  }, [search]);

  const massReaction = (reaction) => {
    api.post(`${API_URL}/reactions/mass_reaction/`, {
      reaction_ids: selectedItems,
      reaction,
    }).then(() => {
      setSelectedItems([]);
      fetchItems();
      setSelecting(false);
    });
  };

  const checkFolder = () => {
    if (!isPreset) {
      api.get(`${API_URL}/folders/${id}/`)
        .then((r) => {
          setFolder(r.data);
          setItems(r.data.items);
        });
    } else {
      fetchItems();
    }
    api.get(`${API_URL}/folders/`).then((res) => {
      setFolders(res.data);
    });
  };
  const moveToFolder = (folderId) => {
    api.post(`${API_URL}/reactions/mass_reaction/`, {
      reaction_ids: selectedItems,
      folder: folderId,
    }).then(() => {
      setSelectedItems([]);
      checkFolder();
      dismissModal();
      setSelecting(false);
    });
  };

  const toggleSelected = (item) => {
    if (selectedItems.includes(item.reaction_id)) {
      setSelectedItems(selectedItems.filter((i) => i !== item.reaction_id));
    } else {
      setSelectedItems([...selectedItems, item.reaction_id]);
    }
  };
  const toggleSelecting = (item) => {
    if (!selecting) {
      setSelectedItems([item.reaction_id]);
    } else {
      setSelectedItems([]);
    }
    setSelecting(!selecting);
  };

  const shouldDisableScheduleButton = () => selectedItems.some((selectedId) => {
    const selectedItem = items.find((item) => item.reaction_id === selectedId);
    return selectedItem && selectedItem.type !== 'trip';
  });

  // eslint-disable-next-line
  const getTripIdsFromReactionIds = () => {
    return selectedItems.map((reactionId) => {
      const selectedItem = items.find((item) => item.reaction_id === reactionId);
      // Check if the selectedItem exists and its type is 'trip' before including the ID
      if (selectedItem && selectedItem.type === 'trip') {
        return selectedItem.id;
      }
      return undefined;
    }).filter((tripId) => tripId !== undefined);
  };

  const scheduleSingleTrip = (tripId) => {
    setIsLoading(true);
    api.post(`${API_URL}/trips/${tripId}/schedule_for_me/`)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Scheduled Successfully',
            text: `Trip scheduled for ${response.data.holiday}`,
            showDenyButton: true,
            confirmButtonText: 'Go to Calendar',
            denyButtonText: 'Remain in Saved',
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/app/calendar', { state: { from: location.pathname } });
            }
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error('Error scheduling trip automatically.');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const scheduleMultipleTrips = (tripIds) => {
    setIsLoading(true);
    api.post(`${API_URL}/trips/schedule-multiple-trips/`, { trip_ids: tripIds })
      .then((response) => {
        if (response.status === 200) {
          // Calculate the number of trips successfully scheduled
          const numScheduled = response.data.schedule.length;
          Swal.fire({
            title: 'Trips Scheduled Successfully',
            text: `${numScheduled} trips have been scheduled successfully.`,
            icon: 'success',
            showDenyButton: true,
            confirmButtonText: 'Go to Calendar',
            denyButtonText: 'Remain in Saved',
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/app/calendar', { state: { from: location.pathname } });
            }
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error('Error scheduling multiple trips automatically.');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const scheduleTrips = () => {
    const tripIds = getTripIdsFromReactionIds();

    if (tripIds.length === 1) {
      scheduleSingleTrip(tripIds[0]);
    } else if (tripIds.length > 1) {
      scheduleMultipleTrips(tripIds);
    }
  };

  useIonViewWillEnter(checkFolder);

  useEffect(() => {
    setPresentingElement(pageRef.current);
  }, []);

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="ion-back-button ion-cancel"
              onClick={() => history.back()}
            >
              <IconArrowLeft />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {title()}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonModal
          presentingElement={presentingElement}
          isOpen={moveModal}
          onDidDismiss={() => setMoveModal(false)}
        >
          <IonContent>
            <IonHeader>
              <IonToolbar>
                <IonTitle>
                  Move items
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    className="ion-cancel"
                    onClick={dismissModal}
                  >
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Box sx={{ padding: '30px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} key="none">
                    <button
                      type="button"
                      className="folder-card"
                      onClick={() => moveToFolder(null)}
                    >
                      <IconFolderOff />
                      None
                    </button>
                  </Grid>
                  {folders.map((f) => (
                    <Grid item xs={6} key={f.id}>
                      <button
                        type="button"
                        className="folder-card"
                        onClick={() => moveToFolder(f.id)}
                      >
                        <IconFolder />
                        {f.name}
                      </button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </IonContent>
          </IonContent>
        </IonModal>
        <div className={`folder-floater toolbar ${selecting ? '' : 'hidden'}`}>
          <button
            type="button"
            onClick={() => massReaction('favourite')}
            disabled={selectedItems.length === 0}
          >
            <IconStar />
            Favourite
          </button>
          <button
            type="button"
            onClick={() => massReaction('saved')}
            disabled={selectedItems.length === 0}
          >
            <IconStarOff />
            Unfavourite
          </button>
          <button
            type="button"
            disabled={selectedItems.length === 0}
            onClick={() => setMoveModal(true)}
          >
            <IconFolderShare />
            Move
          </button>
          <button
            type="button"
            disabled={selectedItems.length === 0 || shouldDisableScheduleButton() || isLoading}
            onClick={scheduleTrips}
          >
            {isLoading ? (
              <>
                <IonSpinner className="orange-spinner" name="lines" />
                {' '}
                Scheduling...
              </>
            ) : (
              <>
                <IconCalendarTime />
                {' '}
                Schedule
              </>
            )}
          </button>
          <button
            type="button"
            onClick={() => massReaction('deleted')}
            disabled={selectedItems.length === 0}
          >
            <IconTrash />
            Remove
          </button>
        </div>
        <div className={`folder-floater info ${selecting ? 'hidden' : ''}`}>
          <IconInfoCircle />
          Tap and hold saved items to interact.
        </div>
        <Box sx={{ padding: '30px' }} className="folder-search-row">
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={selecting ? 9 : 12}>
              <TextField
                type="text"
                placeholder="Search..."
                className="search-input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {selecting && (
              <Grid item xs={3}>
                <Button
                  fullWidth
                  className="selecting-count"
                  startIcon={<IconX />}
                  onClick={() => setSelectedItems([])}
                >
                  {selectedItems.length}
                </Button>
              </Grid>
            )}
          </Grid>
          {items ? (
            <Grid container spacing={1.5}>
              {items.map((item) => (
                <Grid item xs={6} key={item.reaction_id}>
                  <BucketListCard
                    item={item}
                    selecting={selecting}
                    selected={selectedItems.includes(item.reaction_id)}
                    toggleSelecting={() => toggleSelecting(item)}
                    toggleSelected={() => toggleSelected(item)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Masonry columns={2} spacing={1.5} className="folder-masonry" sequential>
              <FakeCards />
            </Masonry>
          )}
        </Box>
      </IonContent>
    </IonPage>
  );
}

export default FolderView;
