import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IconDots } from '@tabler/icons-react';
import { IonActionSheet } from '@ionic/react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import useEventTrack from 'hooks/useEventTrack';
import TrackEvent from 'Constants/eventConstant';

function Folder({
  name, icon, id, refresh,
}) {
  const preset = ['all', 'favourites', 'deleted'];
  const isPreset = preset.includes(id);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const history = useHistory();
  const { trackEvent } = useEventTrack();

  const pressDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this folder!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(`${API_URL}/folders/${id}/`)
          .then(() => {
            refresh();
          });
      }
    });
  };

  const handleClick = () => {
    history.push(`/app/folder/${id}`);
  };

  const actionsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    trackEvent(TrackEvent.FOLDER_ACTION, {
      folder: name,
      label: id,
      type: 'folder',
    })
    setIsSheetOpen(!isSheetOpen);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <div className="folder" onClick={handleClick}>
        <div className="folder-info">
          {icon}
          <h3>
            {name}
          </h3>
        </div>
        <div className="folder-actions">
          {!isPreset && (
            <>
              <button type="button" aria-label="Actions" onClick={actionsClick}>
                <IconDots />
              </button>
            </>
          )}
        </div>
      </div>
      <IonActionSheet
        isOpen={isSheetOpen}
        header={`Folder: ${name}`}
        onDidDismiss={() => setIsSheetOpen(false)}
        buttons={[
          {
            text: 'Edit',
          },
          {
            text: 'Delete',
            role: 'destructive',
            handler: pressDelete,
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ]}
      />
    </>
  );
}

Folder.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  refresh: PropTypes.func,
};

Folder.defaultProps = {
  refresh: () => {
  },
};

export default Folder;
