import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { TbCheck, TbHeartFilled } from 'react-icons/tb';
import { IconHeartFilled } from '@tabler/icons-react';
import { Masonry } from '@mui/lab';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { DISCOVER_URL } from '../../Constants/URLS';
import FakeCards from '../Saved/FakeCards';
import OnboardingDiscoverItem from './OnboardingDiscoverItem';

function OnboardingDiscover({ ready }) {
  const [discoverData, setDiscoverData] = useState(null);
  const [count, setCount] = useState(0);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [page, setPage] = useState(1);
  const [savedCount, setSavedCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('Fetching data from API');
    setLoading(true);
    api.get(DISCOVER_URL)
      .then((response) => {
        console.log('Data fetched successfully', response.data);
        setDiscoverData(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        setLoading(false);
      });
  }, []);

  const next = () => {
    userContext.getUserInfo();
    ready();
  };

  const loadMore = () => {
    if (discoverData.length < count && !loading) {
      setLoading(true);
      api.get(`${DISCOVER_URL}?page=${page + 1}`)
        .then((response) => {
          setDiscoverData([...discoverData, ...response.data.results]);
          setPage(page + 1);
          setLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error', error);
        });
    }
  };

  return (
    <div className="onboarding-discover">
      <h1>
        Tap to
        {' '}
        <TbHeartFilled />
        {' '}
        your interests.
      </h1>
      <h2>
        <IconHeartFilled />
        {' '}
        at least 5 for Trabu to work.
      </h2>
      <h2>
        <IconHeartFilled />
        {' '}
        10+ for the best results!
      </h2>
      <Masonry columns={2} spacing={1} sx={{ mt: 2 }} className="masonry" sequential>
        {!discoverData && <FakeCards />}
        {discoverData && discoverData.map((item) => (
          <OnboardingDiscoverItem
            item={item}
            key={item.id}
            increment={() => setSavedCount(savedCount + 1)}
            decrement={() => setSavedCount(Math.max(savedCount - 1, 0))}
          />
        ))}
      </Masonry>
      {count > 0 && discoverData && discoverData.length < count && (
        <Button
          className="btn btn-border"
          onClick={loadMore}
          disabled={loading}
          sx={{ mt: 1 }}
        >
          {loading ? 'Loading...' : 'Load More'}
        </Button>
      )}
      <Button
        className="btn btn-teal btn-ready"
        endIcon={<TbCheck />}
        disabled={(userContext.user?.saved_count || 0) + savedCount < 5}
        onClick={next}
      >
        Ready
      </Button>
    </div>
  );
}

OnboardingDiscover.propTypes = {
  ready: PropTypes.func.isRequired,
};

export default OnboardingDiscover;
