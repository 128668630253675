/* eslint-disable camelcase */
import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TbCalendar, TbHeart, TbMoon, TbWand, TbX } from 'react-icons/tb';
import Swal from 'sweetalert2';
import { Box, Button, Dialog, DialogContent, Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { IconArrowLeft, IconPlaneTilt, IconUpload } from '@tabler/icons-react';
import {
  FORWARDING_URL,
  REACTIONS_URL,
  TRIP_AIRPORTS_URL,
  TRIPS_URL,
} from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import Preloader from '../../Components/Preloader';
import Itinerary from '../../Components/TripInformation/Itinerary';
import 'swiper/swiper-bundle.min.css';
import ComingSoon from '../../Components/ComingSoon';
import BestMonths from '../../Components/Recommendations/BestMonths';
import BestAges from '../../Components/Recommendations/BestAges';
import Legend from '../../Components/Recommendations/Legend';
import getImageUrl from '../../Components/Images/ImageUtils';
import { ConfettiContext } from '../../Providers/ConfettiProvider/ConfettiProvider';
import TripOverview from '../../Components/TripInformation/TripOverview/index';
import { fetchTripDetails } from '../../store/trip/tripSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from '../../Utils/useAnalytics';
import ScheduleComponentV2 from '../../Components/ScheduleComponentV2/ScheduleComponentV2';
import { features } from '../../Constants/flags';
import { useFlags } from 'flagsmith/react';
import PinchZoomRotateImage from 'Components/PinchZoomRotate/PinchZoomRotateImage';
import './TripInformationView.scss';
import {
  UnlockScreenOrientation,
  lockScreenPortrait,
} from 'Utils/OrientationUtils';
import { useFullscreen } from 'Providers/FullscreenProvider/FullscreenProvider';
import useEventTrack from 'hooks/useEventTrack';
import TrackEvent from 'Constants/eventConstant';
import TrackError from 'Constants/errorEventConstant';

const OVERVIEW_TAB = 1;
const ITINERARY_TAB = 2;

const TripInformationView = () => {
  const { tripId } = useParams();
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [tab, setTab] = useState(OVERVIEW_TAB);
  const [arrivalAirport, setArrivalAirport] = useState(null);
  const [departureAirport, setDepartureAirport] = useState(null);
  const [reactionStatus, setReactionStatus] = useState(null);
  const [images, setImages] = useState([]);
  const galleryRef = createRef();
  const [isItineraryVisible, setIsItineraryVisible] = useState(false);
  const itineraryRef = createRef();
  const placeholderOnly =
    images.length === 1 && images[0].original === '/placeholder.jpg';
  const sizeRef = createRef();
  const history = useHistory();
  const [scrolled, setScrolled] = useState(false);
  const [comingSoonDialog, setComingSoonDialog] = useState(false);
  const { doConfetti } = useContext(ConfettiContext);
  const dispatch = useDispatch();
  const { tripDetails } = useSelector((state) => state.trip);
  const flags = useFlags([features.picture_gallery_v2]);
  const { setIsFullscreen, isFullscreen } = useFullscreen();
  const { trackEvent, trackError } = useEventTrack();

  const fetchAirports = async () => {
    try {
      const response = await api.get(`${TRIP_AIRPORTS_URL}${tripId}/`);
      const { arrival_airport_details, departure_airport_details } =
        response.data;

      if (arrival_airport_details) {
        setArrivalAirport(
          `${arrival_airport_details.name} (${arrival_airport_details.iata_code})`,
        );
      }

      if (departure_airport_details) {
        setDepartureAirport(
          `${departure_airport_details.name} (${departure_airport_details.iata_code})`,
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching airports:', error);
    }
  };

  const handleContentScroll = (event) => {
    const { scrollTop } = event.detail;
    setScrolled(scrollTop > 10);

    const rect = itineraryRef.current.getBoundingClientRect();
    if (rect.top <= (window.innerHeight / 3) * 2) {
      setIsItineraryVisible(true);
    } else {
      setIsItineraryVisible(false);
    }
  };

  const fetchReactionStatus = () => {
    // Updated to include the model name in the request
    api
      .get(`${REACTIONS_URL}?model=trip&object_id=${tripId}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setReactionStatus(response.data[0].reaction);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching reaction status:', error);
      });
  };

  const share = () => {
    if (navigator.share) {
      const forwardingUrl = `${FORWARDING_URL}/${tripId}/`;
      const tripDescription = tripDetails.description
        ? tripDetails.description
        : tripDetails.trip_template.description;
      navigator
        .share({
          title: 'Trabu',
          text: tripDescription,
          url: forwardingUrl,
        })
        .then(
          // eslint-disable-next-line no-console
          () => console.log('Successful share'),
        )
        .catch(
          // eslint-disable-next-line no-console
          (error) => console.log('Error sharing:', error),
        );
    } else {
      const forwardingUrl = `${FORWARDING_URL}/${tripId}/`;
      navigator.clipboard.writeText(
        `Trabu - ${tripDetails.name ? tripDetails.name : tripDetails.trip_template.description} - ${forwardingUrl}`,
      );
      Swal.fire({
        icon: 'success',
        title: 'Copied!',
        text: 'The link has been copied to the clipboard.',
        timer: 1500,
      });
    }
  };

  const toggleLoved = () => {
    if (['saved', 'favourite'].includes(reactionStatus)) {
      api
        .post(`${TRIPS_URL}${tripId}/remove_reaction/`)
        .then(() => {
          trackEvent(TrackEvent.REACTION_REMOVED, {
            type: 'trip',
            tripId,
          })
          setReactionStatus(null);
        })
        .catch(() => {
          trackError(TrackError.REACTION_REMOVED, {
            type: 'trip',
            tripId,
          })
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to remove reaction. Please try again.',
          });
        });
      return;
    }
    api
      .post(`${TRIPS_URL}${tripId}/save/`)
      .then(() => {
        trackEvent(TrackEvent.REACTION_SAVED, {
          type: 'trip',
          tripId,
        })
        setReactionStatus('saved');
        doConfetti();
      })
      .catch(() => {
        trackError(TrackError.REACTION_SAVED, {
          type: 'trip',
          tripId,
        })
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save the trip. Please try again.',
        });
      });
  };

  const { setCurrentScreen, logEvent } = useAnalytics();
  useEffect(() => {
    setCurrentScreen('trip_information').then();
    logEvent({
      name: 'example_log_event',
      context: { trip_id: tripId },
    }).then();
  }, [setCurrentScreen, logEvent]);

  useEffect(() => {
    fetchReactionStatus();
    fetchAirports();
  }, [tripId]);

  useEffect(() => {
    if (tripId) dispatch(fetchTripDetails({ tripId, api }));
  }, [dispatch, tripId]);

  const updateImages = () => {
    if (tripDetails && sizeRef.current) {
      const newImages = [
        {
          original: getImageUrl(tripDetails.image),
          thumbnail: getImageUrl(tripDetails.image),
        },
        ...tripDetails.additional_images.map((image) => ({
          original: getImageUrl(image),
          thumbnail: getImageUrl(image),
        })),
      ];
      setImages(newImages);
    }
  };

  useEffect(() => {
    if (tripDetails) {
      updateImages();
    }
  }, [tripDetails]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateImages();
    });

    if (sizeRef.current) {
      resizeObserver.observe(sizeRef.current);
    }

    return () => {
      if (sizeRef.current) {
        resizeObserver.unobserve(sizeRef.current);
      }
    };
  }, [sizeRef.current]);

  const makeFullScreen = async () => {
    if (!isFullscreen && !placeholderOnly) {
      trackEvent(TrackEvent.GALLERY_FULL_SCREEN, {
        type: 'trip',
        tripId,
      })
      galleryRef.current.toggleFullScreen();
      UnlockScreenOrientation();
    }
  };

  const toggleFullScreen = async () => {
    galleryRef.current.toggleFullScreen();
    if (isFullscreen) {
      lockScreenPortrait();
    }
  };

  const openImage = (index) => {
    galleryRef.current.setState({ currentIndex: index, offsetPercentage: 0 });
    galleryRef.current.toggleFullScreen();
    UnlockScreenOrientation();
  };

  const handleBackClick = () => {
    if (isFullscreen) {
      galleryRef.current.toggleFullScreen();
      setTimeout(() => {
        history.back();
      }, 100);
    } else {
      history.back();
    }
  };

  useIonViewWillEnter(() => {
    fetchReactionStatus();
  });

  if (!tripDetails) {
    return (
      <IonPage>
        <IonContent>
          <Preloader />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      {!isFullscreen && (
        <IonHeader className="transparent-header-wrapper">
          <IonToolbar
            className={`transparent-header ${scrolled ? 'scrolled' : ''}`}
          >
            <IonButtons slot="start">
              <IonButton
                fill="clear"
                onClick={handleBackClick}
                className="transparent-header-icon back"
              >
                <IconArrowLeft />
              </IonButton>
              <IonButton
                fill="clear"
                onClick={share}
                className="transparent-header-icon"
              >
                <IconUpload />
              </IonButton>
            </IonButtons>
            <IonText slot="end" className="transparent-header-type">
              <IconPlaneTilt />
              Trip
            </IonText>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent
        fullscreen
        className="transparent-header-content"
        onIonScroll={handleContentScroll}
        scrollEvents
      >
        <div className="trip-info-header" ref={sizeRef}>
          {flags.picture_gallery_v2.enabled ? (
            <div className={isFullscreen && 'gallery-v2-enabled'}>
              <ImageGallery
                items={images}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                showBullets
                showNav={false}
                autoPlay={false}
                imageHeight="250"
                additionalClass="trip-header-gallery"
                ref={galleryRef}
                onScreenChange={() => setIsFullscreen(!isFullscreen)}
                onClick={makeFullScreen}
                useBrowserFullscreen={false}
                renderItem={(item) => (
                  <>
                    {isFullscreen ? (
                      <PinchZoomRotateImage
                        src={item.original}
                        alt={item.caption || 'Image'}
                      />
                    ) : (
                      <img src={item.original} alt={item.caption || 'Image'} />
                    )}
                  </>
                )}
              />
            </div>
          ) : (
            <ImageGallery
              items={images}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              showBullets
              showNav={false}
              autoPlay={false}
              imageHeight="250"
              additionalClass="trip-header-gallery"
              ref={galleryRef}
              onScreenChange={() => setIsFullscreen(!isFullscreen)}
              onClick={makeFullScreen}
              useBrowserFullscreen={false}
              renderItem={(item) => (
                <>
                  {isFullscreen ? (
                    <div className="defaultImgStyle">
                      <img
                        className="defaultInnerImgStyle"
                        src={item.original}
                        alt={item.caption || 'Image'}
                      />
                    </div>
                  ) : (
                    <img src={item.original} alt={item.caption || 'Image'} />
                  )}
                </>
              )}
            />
          )}
          {isFullscreen && (
            // eslint-disable-next-line
            <div className="fullscreen-close-button" onClick={toggleFullScreen}>
              <TbX />
            </div>
          )}
          <h1>
            {tripDetails.name
              ? `${tripDetails.name}`
              : `${tripDetails.trip_template.description} : ${tripDetails.number_of_days} days`}
          </h1>
          <Button
            className={`btn-love btn btn-icon ${['saved', 'favourite'].includes(reactionStatus) ? 'btn-orange' : 'btn-white'}`}
            onClick={() => toggleLoved()}
          >
            <TbHeart />
            {/* {['saved', 'favourite'].includes(reactionStatus) ? (
              <TbHeartOff />
            ) : (
              <TbHeart />
            )} */}
          </Button>
        </div>
        <div className="trip-information">
          <Box sx={{ p: '30px', pb: 2 }}>
            {tripDetails.description && (
              // eslint-disable-next-line react/no-danger
              <p
                className="trip-description"
                dangerouslySetInnerHTML={{ __html: tripDetails.description }}
              />
            )}

            {tripDetails.additional_images.length > 0 && (
              // eslint-disable-next-line
              <img
                src={getImageUrl(tripDetails.additional_images[0])}
                style={{
                  objectPosition: `${tripDetails.additional_images[0].interest_x}% ${tripDetails.additional_images[0].interest_y}%`,
                }}
                alt="Additional"
                className="additional-image large"
                onClick={() => openImage(1)}
              />
            )}
            {tripDetails.additional_images.length > 1 && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {/* eslint-disable-next-line */}
                    <img
                      src={getImageUrl(tripDetails.additional_images[1])}
                      style={{
                        objectPosition: `${tripDetails.additional_images[1].interest_x}% ${tripDetails.additional_images[1].interest_y}%`,
                      }}
                      alt="Additional"
                      className="additional-image"
                      onClick={() => openImage(2)}
                    />
                  </Grid>
                  {tripDetails.additional_images.length > 2 && (
                    <Grid item xs={4}>
                      {/* eslint-disable-next-line */}
                      <img
                        src={getImageUrl(tripDetails.additional_images[2])}
                        style={{
                          objectPosition: `${tripDetails.additional_images[2].interest_x}% ${tripDetails.additional_images[2].interest_y}%`,
                        }}
                        alt="Additional"
                        className="additional-image"
                        onClick={() => openImage(3)}
                      />
                    </Grid>
                  )}
                  {tripDetails.additional_images.length > 3 && (
                    <Grid item xs={4}>
                      {/* eslint-disable-next-line */}
                      <img
                        src={getImageUrl(tripDetails.additional_images[3])}
                        style={{
                          objectPosition: `${tripDetails.additional_images[3].interest_x}% ${tripDetails.additional_images[3].interest_y}%`,
                        }}
                        alt="Additional"
                        className="additional-image"
                        onClick={() => openImage(4)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Box sx={{ mb: 2 }} />
              </>
            )}
            {(tripDetails.recommended_months ||
              tripDetails.recommended_children_ages) && (
                <h2 style={{ textAlign: 'center' }}>When to Go?</h2>
              )}
            {tripDetails.recommended_months && (
              <BestMonths months={tripDetails.recommended_months} />
            )}
            {tripDetails.recommended_children_ages && (
              <Box sx={{ mt: 1.5 }}>
                <BestAges ages={tripDetails.recommended_children_ages} />
              </Box>
            )}
            {(tripDetails.recommended_months ||
              tripDetails.recommended_children_ages) && (
                <>
                  <Legend />
                  <Box sx={{ mb: 3 }} />
                </>
              )}
              <>
                <Legend />
                <Box sx={{ mb: 3 }} />
              </>
            <ScheduleComponentV2 item={tripDetails} />
          </Box>
          <div className="itinerary-controls">
            <h2>Recommended Itinerary</h2>
            <div className={`tabs-2 active-${tab}`}>
              <button
                type="button"
                aria-label="Overview"
                onClick={() => setTab(OVERVIEW_TAB)}
              >
                Overview
              </button>
              <button
                type="button"
                aria-label="Itinerary"
                onClick={() => setTab(ITINERARY_TAB)}
              >
                Itinerary
              </button>
            </div>
          </div>
          <div ref={itineraryRef}>
            <div className="itinerary-controls">
              <span>
                <TbMoon /> Trip length:{' '}
                <b>
                  {tripDetails.number_of_days > 1
                    ? `${tripDetails.number_of_days} days`
                    : '1 day'}
                </b>
              </span>
            </div>
            {tab === ITINERARY_TAB ? (
              <>
                {tripDetails && tripDetails.daily_itinerary && (
                  <Itinerary
                    arrivalAirport={arrivalAirport}
                    departureAirport={departureAirport}
                    itinerary={tripDetails.daily_itinerary}
                    experiences={tripDetails.experiences}
                  />
                )}
              </>
            ) : (
              <Box className="overviewlist">
                <TripOverview
                  arrivalAirport={arrivalAirport}
                  departureAirport={departureAirport}
                  itinerary={tripDetails.daily_itinerary}
                  experiences={tripDetails.experiences}
                />
              </Box>
            )}
          </div>

          <div
            className={`itinerary-buttons ${isItineraryVisible ? '' : 'hidden'}`}
          >
            <Button
              className="btn btn-orange btn-long"
              startIcon={<TbCalendar />}
              onClick={() => setComingSoonDialog(true)}
            >
              Book now
            </Button>
            <Button
              className="btn btn-white btn-long"
              startIcon={<TbWand />}
              onClick={() => setComingSoonDialog(true)}
            >
              Build your own
            </Button>
          </div>

          <Dialog
            open={comingSoonDialog}
            onClose={() => setComingSoonDialog(false)}
          >
            <DialogContent>
              <ComingSoon back={() => setComingSoonDialog(false)} />
            </DialogContent>
          </Dialog>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TripInformationView;
