import { useContext, useMemo } from 'react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';

export function useFetchAllItems() {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  return async () => {
    const response = await api.get(`${API_URL}/saved/`);
    return response.data;
  };
}

export function useFetchFavourites() {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  return async () => {
    const response = await api.get(`${API_URL}/favourites/`);
    return response.data;
  };
}

export function useFetchDeleted() {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  return async () => {
    const response = await api.get(`${API_URL}/deleted/`);
    return response.data;
  };
}

export function fetch() {}
