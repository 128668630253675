import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconCheck,
  IconMap, IconPlaneTilt, IconSparkles, IconStarFilled,
} from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import getImageUrl from '../Images/ImageUtils';
import './BucketListCard.scss';

function BucketListCard({
  item, selecting, selected, toggleSelecting, toggleSelected,
}) {
  const history = useHistory();
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);

  const typeIcon = () => {
    switch (item.type) {
      case 'trip':
        return <IconPlaneTilt />;
      case 'experience':
        return <IconSparkles />;
      default:
        return <IconMap />;
    }
  };

  const handlePress = () => {
    if (selecting) {
      toggleSelected();
    } else {
      const locationTypes = ['destination', 'country', 'region'];
      history.push(`/app/${locationTypes.includes(item.type) ? 'location/' : ''}${item.type}/${item.id}`);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartTime(new Date().getTime());
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    // check if user has scrolled
    if (Math.abs(e.changedTouches[0].clientX - touchStartX) > 10) {
      return;
    }

    // check if long press
    if (new Date().getTime() - touchStartTime > 300) {
      toggleSelecting();
    } else {
      handlePress();
    }
  };

  const imageUrl = getImageUrl(item.image);

  return (
    // eslint-disable-next-line
    <div
      className={`bucketlist-card ${selected ? 'selected' : ''}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className={`selecting-circle ${selecting ? 'selecting' : ''} ${selected ? 'selected' : ''}`}>
        <IconCheck />
      </div>
      <div
        className="bucketlist-card-inner"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: `${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_x : 50}% ${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_y : 50}%`,
        }}
      >
        <div className="overlay" />
        <div className="icon">
          {typeIcon()}
        </div>
        {item.reaction === 'favourite' && (
          <div className="icon favourite">
            <IconStarFilled />
          </div>
        )}
        <h3>{item.name}</h3>
      </div>
    </div>
  );
}

BucketListCard.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired,
  selecting: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  toggleSelecting: PropTypes.func.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};

export default BucketListCard;
