enum TrackEvent {
    // eslint-disable-next-line no-unused-vars
    LOVED_REMOVED = 'Love Was Removed',
    // eslint-disable-next-line no-unused-vars
    LOVED_SAVED = 'Love Was Saved',
    // eslint-disable-next-line no-unused-vars
    REACTION_REMOVED = 'Reaction Was Removed',
    // eslint-disable-next-line no-unused-vars
    REACTION_ADDED = 'Reaction Was Saved',
    // eslint-disable-next-line no-unused-vars
    REACTION_CREATED = 'Reaction Created',
    // eslint-disable-next-line no-unused-vars
    TRIP_CARD_CLICKED = 'Trip Card Clicked',
    // eslint-disable-next-line no-unused-vars
    EXPERIENCE_CARD_CLICKED = 'Experience Card Clicked',
    // eslint-disable-next-line no-unused-vars
    LOCATION_CARD_CLICKED = 'Location Card Clicked',
    // eslint-disable-next-line no-unused-vars
    ERROR_OCCURRED = 'Error Occurred',
    // eslint-disable-next-line no-unused-vars
    GALLERY_FULL_SCREEN = 'Gallery full screen',
    // eslint-disable-next-line no-unused-vars
    FOLDER_ACTION = 'Folder Action',
    // eslint-disable-next-line no-unused-vars
    TRIP_RESCHEDULED = 'Trip Rescheduled',
    // eslint-disable-next-line no-unused-vars
    TRIP_SCHEDULED = 'Trip Scheduled',

    // eslint-disable-next-line no-unused-vars
    SCHEDULE_HOLIDAY = 'Schedule Holiday',
    // eslint-disable-next-line no-unused-vars
    SAVED_TRIPS = 'Saved Trips'
}

export default TrackEvent;
