import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Button, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL, REQUEST_URL } from '../../Constants/URLS';

function EmailConfirmationView() {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [inter, setInter] = useState(null);

  const paddedMinutes = Math.floor(timeLeft / 60).toString().padStart(2, '0');
  const paddedSeconds = (timeLeft % 60).toString().padStart(2, '0');

  const history = useHistory();

  useEffect(() => {
    if (!userContext.isLoggedIn || userContext.isEmailVerified) {
      history.push('/');
    }
  }, [userContext, userContext.isEmailVerified, userContext.isLoggedIn]);

  // refresh user info every 20 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      api.get(REQUEST_URL).then((r) => {
        if (r.data.email_verified) {
          window.location.reload();
        }
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const resetTimer = () => {
    setTimeLeft(120);
    clearInterval(inter);
    // set interval to decrement timeLeft every second
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    setInter(interval);
  };

  const resendEmail = () => {
    api.post(`${API_URL}/dj-rest-auth/registration/resend-email/`, {
      email: userContext.user.email,
    }).then(() => {
      resetTimer();
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    });
  };

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(inter);
    }
  }, [timeLeft]);

  useEffect(() => {
    resetTimer();
    return () => clearInterval(inter);
  }, []);

  return (
    <div className="email-confirmation">
      <div className="page-header">
        <img src="/trabu_logo_light.png" alt="Trabu logo" />
      </div>
      <div className="page-content">
        <img src="/trabu-icon-black.svg" className="trabu-icon" alt="Icon" />
        <h1>
          <span>Perfect!</span>
          Click the link in your email to continue.
        </h1>
        <CircularProgress />
        <p>
          Haven&apos;t received an email? You can request another one in
          {' '}
          <strong>
            {paddedMinutes}
            :
            {paddedSeconds}
          </strong>
          .
        </p>
        {timeLeft === 0 && (
          <Button
            className="btn btn-small btn-border"
            onClick={resendEmail}
          >
            Resend email
          </Button>
        )}
      </div>
    </div>
  );
}

export default EmailConfirmationView;
