import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { IconUserHeart, IconUserPlus } from '@tabler/icons-react';
import {
  Box,
  Button,
} from '@mui/material';
import moment from 'moment/moment';
import { TbDeviceFloppy } from 'react-icons/tb';
import {
  IonModal, IonDatetime, IonContent,
  IonHeader, IonToolbar, IonButton, IonButtons, IonTitle,
} from '@ionic/react';
import Swal from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';

function TravelCompanions({ open, close }) {
  const [companions, setCompanions] = useState([]);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [addCompanionModalOpen, setAddCompanionModalOpen] = useState(false);
  const [addCompanion, setAddCompanion] = useState({ name: '', date_of_birth: moment().format('YYYY-MM-DD') });
  const [editCompanionModalOpen, setEditCompanionModalOpen] = useState(false);
  const [editCompanion, setEditCompanion] = useState({});

  const openAddCompanionModal = () => {
    setAddCompanionModalOpen(true);
    setAddCompanion({ name: '', date_of_birth: moment().format('YYYY-MM-DD') });
  };

  const openEditCompanionModal = (companion) => {
    setEditCompanion(companion);
    setEditCompanionModalOpen(true);
  };

  const handleEditCompanion = () => {
    if (editCompanion.name === '' || editCompanion.date_of_birth === '') {
      Swal.fire('Error', 'Please fill in all fields', 'error');
      return;
    }
    api.patch(`${API_URL}/companions/${editCompanion.id}/`, editCompanion).then(() => {
      userContext.getUserInfo();
      setEditCompanionModalOpen(false);
    }).catch(() => {
      Swal.fire('Error', 'Failed to update companion. Please try again.', 'error');
    });
  };

  const handleAddCompanion = () => {
    if (addCompanion.name === '' || addCompanion.date_of_birth === '') {
      Swal.fire('Error', 'Please fill in all fields', 'error');
      return;
    }
    const data = {
      name: addCompanion.name,
      date_of_birth: moment(addCompanion.date_of_birth).format('YYYY-MM-DD'),
      user: userContext.user.id,
    };
    api.post(`${API_URL}/companions/`, { ...data }).then(() => {
      userContext.getUserInfo();
      setAddCompanionModalOpen(false);
    }).catch(() => {
      Swal.fire('Error', 'Failed to add companion. Please try again.', 'error');
    });
  };

  const handleDeleteCompanion = (companion) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this family member? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(`${API_URL}/companions/${companion.id}/`).then(() => {
          userContext.getUserInfo();
          setEditCompanionModalOpen(false);
        }).catch(() => {
          Swal.fire('Error', 'Failed to delete companion. Please try again.', 'error');
        });
      }
    });
  };

  useEffect(() => {
    if (userContext.user && userContext.user.companions) {
      setCompanions(userContext.user.companions);
    }
  }, [userContext, open]);

  return (
    <>
      <IonModal isOpen={open}>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className="ion-cancel" onClick={close}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Box className="profile settings" sx={{ pt: 3 }}>
            <h1>Your Family</h1>
            <div className="options">
              {companions.map((companion, index) => (
                <button
                  type="button"
                  aria-label="Phone Number"
                  className="option"
                  onClick={() => openEditCompanionModal(companion)}
                  key={index}
                >
                  <IconUserHeart />
                  <div className="option-text">
                    <span>
                      {companion.name}
                    </span>
                    {`${companion.age} years old`}
                  </div>
                </button>
              ))}
              <Button
                sx={{ mt: 3 }}
                className="btn btn-orange btn-long"
                startIcon={<IconUserPlus />}
                onClick={openAddCompanionModal}
              >
                Add Family Member
              </Button>
            </div>
          </Box>
        </IonContent>
      </IonModal>
      <IonModal isOpen={addCompanionModalOpen}>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className="ion-cancel" onClick={() => setAddCompanionModalOpen(false)}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>Add Travel Companion</IonTitle>
            <IonButtons slot="end">
              <IonButton className="ion-save" onClick={handleAddCompanion}>Save</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="settings settings-modal">
          <label style={{ marginTop: 0 }}>Name</label>
          <input
            type="text"
            placeholder="Name"
            value={addCompanion.name}
            onChange={(e) => setAddCompanion({ ...addCompanion, name: e.target.value })}
          />
          <label>Date of Birth</label>
          {/* <input
            type="date"
            placeholder="Date of birth"
            value={addCompanion.date_of_birth}
            onChange={(e) => setAddCompanion({ ...addCompanion, date_of_birth: e.target.value })}
          /> */}
          <IonDatetime
            id="addCompanionDate"
            presentation="date"
            preferWheel
            onIonChange={(e) => setAddCompanion({ ...addCompanion, date_of_birth: e.detail.value })}
            max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
          />
        </IonContent>
      </IonModal>
      <IonModal isOpen={editCompanionModalOpen}>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className="ion-reset" onClick={() => handleDeleteCompanion(editCompanion)}>Delete</IonButton>
            </IonButtons>
            <IonTitle>Edit Family Member</IonTitle>
            <IonButtons slot="end">
              <IonButton className="ion-cancel" onClick={() => setEditCompanionModalOpen(false)}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="settings settings-modal">
          <label style={{ marginTop: 0 }}>Name</label>
          <input
            type="text"
            placeholder="Name"
            value={editCompanion.name}
            onChange={(e) => setEditCompanion({ ...editCompanion, name: e.target.value })}
          />
          <label>Date of Birth</label>
          <IonDatetime
            id="addCompanionDate"
            presentation="date"
            preferWheel
            onIonChange={(e) => setEditCompanion(
              { ...editCompanion, date_of_birth: e.detail.value },
            )}
            value={editCompanion.date_of_birth}
            max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
          />
          <Button
            className="btn btn-orange btn-long"
            onClick={handleEditCompanion}
            startIcon={<TbDeviceFloppy />}
            sx={{ mt: 3 }}
          >
            Save
          </Button>
        </IonContent>
      </IonModal>
    </>
  );
}

TravelCompanions.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default TravelCompanions;
