import React from 'react';
import PropTypes from 'prop-types';

function PageHeading({
  icon, title, className, children, hideRoundedContainer,
}) {
  return (
    <>
      {/* <div className="statusbar-padding" /> */}
      <div className={`page-heading ${className}`}>
        <div className="page-heading-content">
          {icon}
          <h1>
            {title}
          </h1>
        </div>
        {children}
        {!hideRoundedContainer && <div className="heading-rounded-container" />}
      </div>
    </>
  );
}

PageHeading.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  hideRoundedContainer: PropTypes.bool,
};

PageHeading.defaultProps = {
  className: '',
  hideRoundedContainer: false,
};

PageHeading.defaultProps = {
  children: <></>,
};

export default PageHeading;
