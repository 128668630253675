import { Button } from '@mui/material';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import React from 'react';

interface YearButtonProps {
  onClick: () => void;
  disabled: boolean;
}

interface YearNavigationProps {
  onClickPrevious: () => void;
  onClickNext: () => void;
  year: number;
  minYear: number;
  maxYear: number;
}

export function NextYearButton({ onClick, disabled }: YearButtonProps) {
  return <Button
    className="btn btn-white btn-icon"
    onClick={onClick}
    disabled={disabled}
  >
    <FiArrowRight />
  </Button>;
}

export function PreviousYearButton({ onClick, disabled }: YearButtonProps) {
  return <Button
    className="btn btn-white btn-icon"
    onClick={onClick}
    disabled={disabled}
  >
    <FiArrowLeft />
  </Button>;
}

export function YearNavigation({onClickPrevious,
                                 onClickNext,
                                 year,
                                 minYear,
                                 maxYear}: YearNavigationProps) {
  return <div className="calendar-year-selector">
    <PreviousYearButton
      onClick={onClickPrevious}
      disabled={year === minYear} />
    <span>{year}</span>
    <NextYearButton
      onClick={onClickNext}
      disabled={year === maxYear}
    />
  </div>;
}