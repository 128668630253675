import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import { Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './ProfileSettings.scss';
import { API_URL } from '../../Constants/URLS';

function DateOfBirth({ open, close }) {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const [dateOfBirth, setDateOfBirth] = useState('');

  useEffect(() => {
    if (userContext.user && userContext.user.date_of_birth) {
      setDateOfBirth(userContext.user.date_of_birth);
    }
  }, [userContext, open]);

  const updateDateOfBirth = () => {
    if (!dateOfBirth) {
      Swal.fire('Error', 'Please enter your date of birth.', 'error');
      return;
    }
    if (dateOfBirth === userContext.user.date_of_birth) {
      close();
      return;
    }
    api.patch(`${API_URL}/dj-rest-auth/user/`, { date_of_birth: dateOfBirth })
      .then(() => {
        userContext.getUserInfo();
        Swal.fire('Success', 'Date of birth updated successfully.', 'success');
        close();
      });
  };

  return (
    <Sheet snapPoints={[0.95]} isOpen={open} onClose={close}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="settings">
            <h1>Update Date of Birth</h1>
            <label>Date of Birth</label>
            <input
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              placeholder="E.g. 1990-12-25"
            />
            <Button
              className="btn btn-orange"
              startIcon={<FaSave />}
              sx={{ mt: 3 }}
              onClick={updateDateOfBirth}
            >
              Save
            </Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={close} />
    </Sheet>
  );
}

DateOfBirth.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default DateOfBirth;
