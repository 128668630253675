/* eslint-disable */

import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import Preloader from '../../Components/Preloader';
import GalleryHeader from '../../Components/GalleryHeader';
import LocationInfo from '../../Components/Info/LocationInfo';
import { IconArrowLeft, IconMapPin } from '@tabler/icons-react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonToolbar,
} from '@ionic/react';

import getImageUrl from '../../Components/Images/ImageUtils';
import { ConfettiContext } from '../../Providers/ConfettiProvider/ConfettiProvider';
import { useFullscreen } from 'Providers/FullscreenProvider/FullscreenProvider';
import { UnlockScreenOrientation } from 'Utils/OrientationUtils';

function LocationView() {
  const { type, id } = useParams();
  const [location, setLocation] = useState(null);
  const [reactionStatus, setReactionStatus] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [images, setImages] = useState([]);
  const galleryRef = createRef();
  const [scrolled, setScrolled] = useState(false);
  // const [fullScreen, setFullScreen] = useState(false);
  const { doConfetti } = useContext(ConfettiContext);
  const sizeRef = createRef();
  const { setIsFullscreen, isFullscreen } = useFullscreen();

  const handleContentScroll = (event) => {
    const { scrollTop } = event.detail;
    setScrolled(scrollTop > 10);
  };

  const fetchDetails = () => {
    api
      .get(`${API_URL}/${type === 'country' ? 'countries' : `${type}s`}/${id}`)
      .then((response) => {
        setLocation(response.data);
        window.scrollTo(0, 0);
        setReactionStatus(response.data.reaction?.reaction);
      });
  };
  console.log('location', location);

  useEffect(() => {
    fetchDetails();
  }, [id, type]);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const updateImages = () => {
    if (location && sizeRef.current) {
      console.log('imagelocation', location.image);
      const newImages = [
        {
          original: getImageUrl(location.image),

          thumbnail: getImageUrl(location.image),
        },
        ...location.additional_images.map((image) => ({
          original: getImageUrl(image),
          thumbnail: getImageUrl(image),
        })),
      ];
      setImages(newImages);
    }
  };

  useEffect(() => {
    if (location) {
      updateImages();
    }
  }, [location]);
  const toggleLoved = () => {
    if (reactionStatus) {
      setReactionStatus(null);
      api
        .post(
          `${API_URL}/${type === 'country' ? 'countries' : `${type}s`}/${id}/remove_reaction/`,
        )
        .catch(() => {
          fetchDetails();
        });
    } else {
      setReactionStatus('saved');
      doConfetti();
      api
        .post(
          `${API_URL}/${type === 'country' ? 'countries' : `${type}s`}/${id}/save/`,
        )
        .catch(() => {
          fetchDetails();
        });
    }
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateImages();
    });

    if (sizeRef.current) {
      resizeObserver.observe(sizeRef.current);
    }

    return () => {
      if (sizeRef.current) {
        resizeObserver.unobserve(sizeRef.current);
      }
    };
  }, [sizeRef.current]);

  const openImage = (index) => {
    galleryRef.current.setState({ currentIndex: index, offsetPercentage: 0 });
    galleryRef.current.toggleFullScreen();
    UnlockScreenOrientation();
  };

  if (!location) {
    return (
      <IonPage>
        <IonContent>
          <Preloader />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      {!isFullscreen && (
        <IonHeader className="transparent-header-wrapper">
          <IonToolbar
            className={`transparent-header ${scrolled ? 'scrolled' : ''}`}
          >
            <IonButtons slot="start">
              <IonButton
                fill="clear"
                onClick={() => history.back()}
                className="transparent-header-icon back"
              >
                <IconArrowLeft />
              </IonButton>
            </IonButtons>
            <IonText slot="end" className="transparent-header-type">
              <IconMapPin />
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </IonText>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent
        fullscreen
        className="transparent-header-content"
        onIonScroll={handleContentScroll}
        scrollEvents
      >
        <div
          className="location-info-notch"
          style={{ opacity: (scrollPosition - 150) / (305 - 150) }}
        />
        {location && (
          <>
            <GalleryHeader
              images={images}
              galleryRef={galleryRef}
              sizeRef={sizeRef}
              toggleLoved={toggleLoved}
              title={location.name}
              type={type}
              reaction={reactionStatus}
              fullScreen={isFullscreen}
              setFullScreen={setIsFullscreen}
            />
            <LocationInfo location={location} openImage={openImage} />
          </>
        )}
      </IonContent>
    </IonPage>
  );
}

export default LocationView;
