import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './App.scss';
import { NotificationContainer } from 'react-notifications';
import PropTypes from 'prop-types';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import history from './Utils/history';
import UserProvider, {
  UserContext,
} from './Providers/UserProvider/UserProvider';
import NotificationProvider from './Providers/NotificationProvider/NotificationProvider';
import CustomRouter from './Utils/CustomRouters';
import NavigationBar from './Components/NavigationBar';
import IdeasFilterProvider from './Providers/IdeasFilterProvider/IdeasFilterProvider';
import Feedback from './Components/Feedback';
import AuthenticatedOnboarding from './Views/AuthenticatedOnboarding';
import EmailConfirmationView from './Views/EmailConfirmationView';
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import LandingPage from './Views/LandingPage';
import Tabs from './Tabs';
import ConfettiProvider from './Providers/ConfettiProvider/ConfettiProvider';
import AppUrlListener from './AppUrlListener';
import Preloader from './Components/Preloader';
import FirebaseProvider from './Providers/Firebase/FirebaseProvider';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { defaultValues, flagsmithEnvironmentId } from './Constants/flags';

import {
  UnlockScreenOrientation,
  lockScreenPortrait,
} from 'Utils/OrientationUtils';
import { FullscreenProvider } from 'Providers/FullscreenProvider/FullscreenProvider';

setupIonicReact();

function NavbarPage({ children }) {
  const { tokens } = useContext(UserContext);

  return (
    <>
      {tokens.accessToken && <NavigationBar />}
      {children}
    </>
  );
}

NavbarPage.propTypes = {
  children: PropTypes.node.isRequired,
};

function OnboardingWrapper() {
  const { user, isLoggedIn, isEmailVerified, isOnboardingCompleted, loading } =
    useContext(UserContext);

  if (loading) {
    return <Preloader />;
  }

  useEffect(() => {
    const lockOrientation = async () => {
      lockScreenPortrait();
    };

    lockOrientation();

    return () => {
      UnlockScreenOrientation();
    };
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener />
        <IonRouterOutlet
          key={`${user?.email}-${user?.email_verified}-${user?.onboarding_completed}`}
        >
          <Route exact path="/landing" component={LandingPage} />
          <Route
            exact
            path="/email-verification"
            component={EmailConfirmationView}
          />
          <Route exact path="/onboarding" component={AuthenticatedOnboarding} />
          <Route path="/app" component={Tabs} />
          <Route
            exact
            path="/"
            render={() => {
              if (!isLoggedIn) {
                return <Redirect to="/landing" />;
              }
              if (!isEmailVerified) {
                return <Redirect to="/email-verification" />;
              }
              if (!isOnboardingCompleted) {
                return <Redirect to="/onboarding" />;
              }
              return <Redirect to="/app" />;
            }}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

function App() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <FullscreenProvider>
      <FirebaseProvider>
        <FlagsmithProvider
          options={{
            environmentID: flagsmithEnvironmentId(),
            defaultFlags: defaultValues,
            flagsmith,
          }}
          flagsmith={flagsmith}
        >
          <UserProvider>
            <CustomRouter history={history}>
              <div className="App" id="App">
                <IdeasFilterProvider>
                  <ConfettiProvider>
                    <NotificationProvider>
                      <NotificationContainer />
                      <Feedback />
                      <OnboardingWrapper />
                    </NotificationProvider>
                  </ConfettiProvider>
                </IdeasFilterProvider>
              </div>
            </CustomRouter>
          </UserProvider>
        </FlagsmithProvider>
      </FirebaseProvider>
    </FullscreenProvider>
  );
}

export default App;
