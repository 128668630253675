import React from 'react';
import './Recommendations.scss';
import PropTypes from 'prop-types';

function BestAges({ ages }) {
  const agesList = Object.keys(ages).filter((age) => age.includes('ages'));

  return (
    <div className="best-ages">
      {agesList.map((age) => (
        <div className={`interval ${ages[age]}`} key={age}>
          {`${age.replace('ages_', '').replace('_', '-')}`}
        </div>
      ))}
    </div>
  );
}

BestAges.propTypes = {
  // eslint-disable-next-line
  ages: PropTypes.object.isRequired,
};

export default BestAges;
