import { REQUEST_URL, TERMS_ACCEPTANCE_URL, TERMS_AND_CONDITIONS_URL } from 'Constants/URLS';
import { UserContext } from 'Providers/UserProvider/UserProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Sheet from 'react-modal-sheet';
import apiUtilsHook from 'Utils/ApiUtilsHook';
import './TermsAndConditions.scss';
import { Button, Checkbox } from '@mui/material';
import Swal from 'sweetalert2';

const TrabuIcon = (props) => (
    <svg
        viewBox="0 0 452 526"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M264.829 96.0626C315.089 96.0626 355.976 136.961 355.976 187.236C355.976 237.512 315.089 278.41 264.829 278.41C214.568 278.41 173.682 237.512 173.682 187.236C173.682 136.961 214.568 96.0626 264.829 96.0626ZM264.829 347.77C353.46 347.77 425.315 275.894 425.315 187.236C425.315 98.5793 353.46 26.7026 264.829 26.7026C176.198 26.7026 104.343 98.5793 104.343 187.236C104.343 275.894 176.198 347.77 264.829 347.77Z"
            fill="white"
        />
        <path
            d="M299.502 449.868C358.675 442.09 411.767 414.707 452 374.462L402.994 325.441C367.594 360.851 318.727 382.782 264.829 382.782C157.038 382.782 69.3393 295.057 69.3393 187.235C69.3393 133.32 91.2641 84.4309 126.664 49.0282L77.6574 0C29.7001 47.9716 0 114.199 0 187.235C0 321.552 100.462 432.83 230.163 449.868V457.271H163.912V526H365.746V457.271H299.502V449.868Z"
            fill="white"
        />
    </svg>
);

const TermsAndConditions = () => {
    const [termsAndCondition, setTermsAndCondition] = useState(null);
    const [termsAndConditionSheetOpen, setTermsAndConditionSheetOpen] = useState(false);
    const [accepted, setAccepted] = useState(false);

    const userContext = useContext(UserContext);
    const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

    useEffect(() => {
        api.get(REQUEST_URL).then((res) => {
            if (res.data && res.data?.terms_update_required) {
                api.get(TERMS_AND_CONDITIONS_URL).then((response) => {
                    setTermsAndCondition(response.data);
                    setTermsAndConditionSheetOpen(true);
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Could not load terms and conditions',
                        confirmButtonText: 'Close',
                    });
                });
            }
        })
    }, [])

    const handleTermsAndConditionClose = () => {
        setTermsAndConditionSheetOpen(false);
    }

    const handleAggreedTermsAndConditions = () => {
        if (accepted) {
            api.post(TERMS_ACCEPTANCE_URL, { terms: termsAndCondition?.id }).then((res) => {
                if (res.data) {
                    setTermsAndConditionSheetOpen(false);
                }
            }).catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Could not accept terms and conditions',
                    confirmButtonText: 'Close',
                });
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please accept the terms and conditions',
                confirmButtonText: 'Close',
            });
        }
    }

    return <Sheet
        isOpen={termsAndConditionSheetOpen}
        onClose={handleTermsAndConditionClose}
    >
        <Sheet.Container>
            <Sheet.Content>
                <Sheet.Scroller>
                    <div className='terms'>
                        <h1 className='terms-title'>Terms and Conditions</h1>
                        <p className='terms-content'>{termsAndCondition?.content}</p>
                        <div className='terms-checkbox'>
                            <Checkbox id='agree' onChange={() => setAccepted(!accepted)} checked={accepted} />
                            <label htmlFor="agree">
                                By signing in or creating an account, you agree with our <a className='terms-link' href="/terms">Terms & conditions</a> and <a className='terms-link' href="/privacy">Privacy statement</a>
                            </label>
                        </div>
                        <Button
                            className="btn btn-orange btn-long"
                            onClick={handleAggreedTermsAndConditions}
                            startIcon={<TrabuIcon />}
                        >
                            Explore
                        </Button>
                    </div>
                </Sheet.Scroller>
            </Sheet.Content>
        </Sheet.Container>
    </Sheet>
}

export default TermsAndConditions;
