import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid } from '@mui/material';
import {
  IconArrowLeft, IconCalendarMonth, IconPlus, IconUser, IconX,
} from '@tabler/icons-react';
import moment from 'moment';
import { TbCheck } from 'react-icons/tb';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { IonDatetime } from '@ionic/react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';

function OnboardingCompanions({ back }) {
  const [companions, setCompanions] = useState([]);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const history = useHistory();
  const [editIndex, setEditIndex] = useState(null);

  const addCompanion = () => {
    setCompanions([...companions, {
      name: '',
      date_of_birth: moment().format('YYYY-MM-DD'),
    }]);
  };

  const deleteCompanion = (index) => {
    const newCompanions = [...companions];
    newCompanions.splice(index, 1);
    setCompanions(newCompanions);
  };

  const saveCompanions = () => {
    let valid = true;
    companions.forEach((companion) => {
      if (companion.name === '' || companion.date_of_birth === '') {
        valid = false;
      }
    });

    if (!valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all fields',
      });
      return;
    }

    api.patch(`${API_URL}/dj-rest-auth/user/`, { companions, onboarding_completed: true }).then(() => {
      userContext.getUserInfo();
      history.push('/');
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    });
  };

  return (
    <div className="onboarding-companions">
      <Button
        className="btn btn-icon btn-white"
        onClick={back}
        sx={{ mt: -1, ml: -1, mb: 2 }}
      >
        <IconArrowLeft />
      </Button>
      <h1>Who are you travelling with?</h1>
      <div className="companions">
        {companions.map((companion, index) => (
          <div key={index} className="companion">
            <button
              className="delete"
              onClick={() => deleteCompanion(index)}
              aria-label="Delete companion"
              type="button"
            >
              <IconX />
            </button>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="field-group">
                  <IconUser />
                  <input
                    type="text"
                    placeholder="Name"
                    value={companion.name}
                    onChange={(e) => {
                      const newCompanions = [...companions];
                      newCompanions[index].name = e.target.value;
                      setCompanions(newCompanions);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="field-group">
                  <IconCalendarMonth />
                  <input
                    type="date"
                    placeholder="Date of birth"
                    value={companion.date_of_birth}
                    onClick={() => setEditIndex(index)}
                    readOnly
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
        <Dialog open={editIndex !== null} onClose={() => setEditIndex(null)}>
          <div className="edit-companion">
            <IonDatetime
              presentation="date"
              id="addCompanionDate"
              preferWheel
              onIonChange={(e) => {
                const newCompanions = [...companions];
                newCompanions[editIndex].date_of_birth = moment(e.detail.value).format('YYYY-MM-DD');
                setCompanions(newCompanions);
              }}
              value={companions[editIndex]?.date_of_birth}
              max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
            />
          </div>
        </Dialog>
        <Button onClick={addCompanion}>
          <IconPlus />
        </Button>
      </div>
      <Button
        className="btn btn-teal"
        endIcon={<TbCheck />}
        sx={{ mt: 2 }}
        onClick={saveCompanions}
      >
        Finish
      </Button>
    </div>
  );
}

OnboardingCompanions.propTypes = {
  back: PropTypes.func.isRequired,
};

export default OnboardingCompanions;
