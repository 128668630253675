/* eslint-disable import/no-extraneous-dependencies */
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { isMobile } from 'react-device-detect';

export async function lockScreenPortrait() {
  if (isMobile) {
    try {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    } catch (error) {
      console.error('Failed to lock orientation:', error);
    }
  }
}

export async function UnlockScreenOrientation() {
  if (isMobile) {
    try {
      await ScreenOrientation.unlock();
    } catch (error) {
      console.error('Failed to unlock orientation:', error);
    }
  }
}
