import React from 'react';

function TrabuIcon() {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 3.80957C7.79086 3.80957 6 5.51514 6 7.61908C6 9.72302 7.79086 11.4286 10 11.4286C12.2091 11.4286 14 9.72302 14 7.61908C14 5.51514 12.2091 3.80957 10 3.80957ZM4 7.61908C4 4.46318 6.68629 1.90481 10 1.90481C13.3137 1.90481 16 4.46318 16 7.61908C16 10.775 13.3137 13.3334 10 13.3334C6.68629 13.3334 4 10.775 4 7.61908Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.32591 0.377664C5.65922 0.797061 5.57243 1.39438 5.13206 1.71182C3.22982 3.08303 2 5.26491 2 7.7249C2 11.881 5.50966 15.2381 9.82353 15.2381C11.9537 15.2381 13.8846 14.4217 15.2973 13.0929C15.6903 12.7233 16.3234 12.7271 16.7115 13.1013C17.0996 13.4756 17.0956 14.0786 16.7027 14.4482C14.9323 16.1133 12.5029 17.1429 9.82353 17.1429C4.3912 17.1429 0 12.9197 0 7.7249C0 4.64637 1.54198 1.91085 3.92504 0.193045C4.36541 -0.12439 4.9926 -0.0417335 5.32591 0.377664Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.96094 19.0476V16.1905H9.96094V19.0476H7.96094Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9609 19.0476C12.9609 19.5736 12.5132 20 11.9609 20H5.96094C5.40865 20 4.96094 19.5736 4.96094 19.0476C4.96094 18.5216 5.40865 18.0952 5.96094 18.0952H11.9609C12.5132 18.0952 12.9609 18.5216 12.9609 19.0476Z" fill="currentColor" />
    </svg>
  );
}

export default TrabuIcon;
