import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://84e38ae31d92633adb371a8054f65608@o4507644724707328.ingest.de.sentry.io/4507661300072528',
    environment: process.env.REACT_APP_ENVIRONMENT || 'production',
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        error &&
        error.message &&
        error.message.match(/database unavailable/i)
      ) {
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = ['database-unavailable'];
      }
      return event;
    },
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="326670318776-4uf7ljfis411m3nt0jlsiknv4i7ssbh4.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
