import React, {
  createRef,
  useContext, useEffect, useReducer, useRef, useState,
} from 'react';
import {
  Button, CircularProgress, InputAdornment, TextField,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { FiArrowRight, FiLock, FiMail } from 'react-icons/fi';
import Swal from 'sweetalert2';
// import { GoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import { IconMail } from '@tabler/icons-react';
import {
  IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar,
} from '@ionic/react';
import { API_URL, LOGIN_URL } from '../../Constants/URLS';
import apiUtils from '../../Utils/ApiUtils';
// import { handleGoogleSuccess } from '../../Utils/AuthUtils';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import loginReducer from './LoginReducer';

// eslint-disable-next-line
function LoginModal({ open, close, page }) {
  const { storeTokens } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form, formDispatch] = useReducer(loginReducer, {
    fullFieldError: '',
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
    canSubmit: false,
    submitting: false,
  });
  const modalRef = useRef(null);

  const location = useLocation();
  const history = useHistory();
  const api = apiUtils();
  // const onGoogleSuccess = (credentialResponse) => {
  //   handleGoogleSuccess(
  //     credentialResponse,
  //     api,
  //     storeTokens,
  //     history.push,
  //   );
  // };
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  // eslint-disable-next-line
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const emailRef = createRef();
  const passwordRef = createRef();
  const contentRef = createRef();

  const handleForgotPasswordClick = () => {
    setForgotPasswordDialog(true);
  };

  // eslint-disable-next-line
  const resetPassword = () => {
    if (!forgotPasswordEmail) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please enter your email address',
      });
      return;
    }

    api.postNoAuth(`${API_URL}/dj-rest-auth/password/reset/`, { email: forgotPasswordEmail }).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'If the email address is registered, you will receive an email with instructions to reset your password.',
      });
      setForgotPasswordDialog(false);
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'The email address is invalid or not registered. Please try again or contact support.',
      });
    });
  };

  function tryLogin() {
    formDispatch({ type: 'SUBMIT', payload: true });
    setLoading(true);
    api.postNoAuth(LOGIN_URL, { email: form.username, password: form.password })
      .then((response) => {
        if (response?.status === 200) {
          formDispatch({ type: 'SUBMIT', payload: false });
          storeTokens(response.data.access, response.data.refresh);
          // Check if from is present in router
          if (location.state && location.state.from) {
            history.push(location.state.from);
          }
          close();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.detail,
            confirmButtonText: 'Close',
          });
        }
        setLoading(false);
      }).catch((data) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.response.data.detail,
          confirmButtonText: 'Close',
        });
        formDispatch({ type: 'SUBMIT', payload: false });
        if (data.response.status === 400) {
          formDispatch({ type: 'HANDLE_ERROR', payload: data.response.data });
        }
        setLoading(false);
      });
  }

  const scrollToEmail = () => {
    contentRef.current.scrollToPoint(
      0,
      emailRef.current.offsetTop + 60,
      500,
    );
  };

  const scrollToPassword = () => {
    contentRef.current.scrollToPoint(
      0,
      passwordRef.current.offsetTop + 120,
      500,
    );
  };

  useEffect(
    () => {
      if (form.canSubmit) tryLogin();
    },
    [form.canSubmit],
  );

  const handleDismiss = () => {
    if (modalRef.current) {
      modalRef.current.dismiss();
    }
  };

  return (
    <>
      <IonModal ref={modalRef} isOpen={open} onDidDismiss={close} presentingElement={page.current}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={handleDismiss} className="ion-cancel">Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={contentRef}>
          <div className="login-page">
            <div className="login-form">
              <h1>
                Welcome
                <br />
                <span>back!</span>
              </h1>
              {form.fullFieldError && <div className="formError">{form.fullFieldError}</div>}
              <TextField
                variant="filled"
                className="text-field"
                placeholder="Email address..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiMail />
                    </InputAdornment>
                  ),
                }}
                value={form.username}
                autoComplete="email"
                onChange={(e) => formDispatch({ type: 'USERNAME', payload: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    formDispatch({ type: 'VALIDATE' });
                  }
                }}
                sx={{ mb: 2 }}
                onFocus={scrollToEmail}
                inputRef={emailRef}
              />
              <TextField
                variant="filled"
                className="text-field"
                placeholder="Password..."
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiLock />
                    </InputAdornment>
                  ),
                }}
                autoComplete="current-password"
                value={form.password}
                onChange={(e) => formDispatch({ type: 'PASSWORD', payload: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    formDispatch({ type: 'VALIDATE' });
                  }
                }}
                sx={{ mb: 2 }}
                inputRef={passwordRef}
                onFocus={scrollToPassword}
              />
              <div className="formActionsRow">
                <Button
                  onClick={() => formDispatch({ type: 'VALIDATE' })}
                  className="btn btn-long btn-orange btn-login"
                  startIcon={!loading && <FiArrowRight />}
                  disabled={loading}
                  sx={{ mb: 2 }}
                >
                  {!loading ? 'Log in' : <CircularProgress color="inherit" sx={{ height: 'unset !important', width: 'unset !important' }} />}
                </Button>
                <button
                  className="btn-secondary-action"
                  onClick={handleForgotPasswordClick}
                  type="button"
                >
                  Forgot password
                </button>
                {/* <Dialog
            fullWidth
            open={forgotPasswordDialog}
            onClose={() => setForgotPasswordDialog(false)}
          >
            <DialogTitle>Forgot Password</DialogTitle>
            <DialogContent>
              If you forgot your password, please fill in
              your email address and we will send you a link to reset it.

              <label>Email Address</label>
              <TextField
                type="email"
                placeholder="E.g. support@trabu.app"
                value={forgotPasswordEmail}
                onChange={(e) => console.log(e)}
                className="text-field"
              />
              <Button
                className="btn btn-orange btn-long"
                startIcon={<IconMail />}
                sx={{ mt: 2 }}
                onClick={resetPassword}
              >
                Send Reset Link
              </Button>
            </DialogContent>
          </Dialog> */}
              </div>
            </div>
            {/* <div className="social-login">
            <h2>Or login with:</h2>
            <GoogleLogin
              onSuccess={onGoogleSuccess}
            /> */}
            {/* <FacebookProvider appId="1622064405225031">
              <LoginButton
                scope="email"
                onError={(error) => console.log(error)}
                onSuccess={onFacebookSuccess}
              >
                Login via Facebook
              </LoginButton>
            </FacebookProvider> */}
            {/* </div> */}
          </div>
        </IonContent>
      </IonModal>
      <IonModal
        isOpen={forgotPasswordDialog}
        onDidDismiss={() => setForgotPasswordDialog(false)}
        presentingElement={page.current}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setForgotPasswordDialog(false)} className="ion-cancel">Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="login-page">
            <div className="login-form">
              <h1>Reset password</h1>
              <p>
                If you forgot your password, please fill in your
                email address and we will send you a link to reset it.
              </p>
              <label className="form-label">Email Address</label>
              <input
                type="text"
                placeholder="E.g. support@trabu.app"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
              <Button
                className="btn btn-orange btn-long"
                startIcon={<IconMail />}
                sx={{ mt: 3 }}
                onClick={resetPassword}
              >
                Send Reset Link
              </Button>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
}

LoginModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  page: PropTypes.element.isRequired,
};

export default LoginModal;
