import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './PinchZoomRotateImage.scss';

const PinchZoomRotateImage = (props) => {
  const { src, alt } = props;

  return (
    <div className="upperLayout">
      <TransformWrapper
        initialScale={1}
        minScale={1}
        maxScale={3}
        centerOnInit
        centerZoomedOut
        limitToBounds
        onInit={(e) => {
          if (window.innerHeight > window.innerWidth) {
            e.centerView(1);
          } else {
            e.resetTransform();
          }
        }}
        // panning={{ disabled: true }}
      >
        <TransformComponent wrapperClass="flex items-center justify-center  ">
          <img
            src={src}
            alt={alt}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100vh',
              display: 'block',
              margin: '20 0 20 0',
            }}
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

export default PinchZoomRotateImage;
