import { useCallback } from 'react';
import analytics from './analytics';
import { LogEvent } from './interfaces';

const useFirebaseAnalytics = () => {
  const setCurrentScreen = useCallback(async (screen: string) => {
    await analytics.setCurrentScreen(screen);
  }, []);

  const logEvent = useCallback(async (event: LogEvent) => {
    await analytics.logEvent(event);
  }, []);

  return {
    setCurrentScreen,
    logEvent
  };
};

export default useFirebaseAnalytics;