import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import getImageUrl from '../Images/ImageUtils';
import './OverviewHeaderCard.scss';

function OverviewHeaderCard({ item }) {
  const imageUrl = getImageUrl(item.image);

  return (
    <Grid
      sx={{
        height: {
          xs: '87px',
          sm: '100px',
          md: '120px',
          lg: '120px',
          xl: '140px',
        },
      }}
      className="overviewheader-card"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imageUrl})`,
        backgroundPosition: `${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_x : 50}% ${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_y : 50}%`,
      }}
      data-testid="overview-header-card"
    />
  );
}

OverviewHeaderCard.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired,
};

export default OverviewHeaderCard;
