import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { TbPlaneArrival, TbPlaneDeparture } from 'react-icons/tb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import TripAccordion from '../../TripAccordion/TripAccordion';

function TripOverview({
  arrivalAirport = '',
  departureAirport = '',
  itinerary = [],
  experiences = [],
}) {
  const [expanded, setExpanded] = useState('panel1');

  const [overviewData, setOverviewData] = useState(null);
  const history = useHistory();

  const mergeDuplicateDestinations = (allDestinations) => {
    const destinationMap = new Map();

    allDestinations.forEach((destinationItem) => {
      destinationItem.blocks?.forEach((block) => {
        const { destination } = block;

        const experienceItems = [
          ...(block.list || []),
          ...((block.days || []).flatMap((day) => day.list) || []),
        ];

        if (destinationMap.has(destination)) {
          destinationMap.get(destination).list.push(...experienceItems);
        } else {
          destinationMap.set(destination, {
            ...omit(block, ['list', 'days']),

            list: experienceItems,
          });
        }
      });
    });

    const destinationObj = {};

    destinationMap.forEach((block, destination) => {
      const title = `Destination ${destination}`;
      if (!destinationObj[title]) {
        destinationObj[title] = { title, blocks: [] };
      }
      destinationObj[title].blocks.push(block);
    });

    const destinationArray = Object.values(destinationObj);

    const getExperiences = (allExplist) =>
      allExplist
        .map((expItem) => expItem.experience)
        .filter((exp) => exp !== undefined);

    //  remove duplicated experiences in `list` if they are already in `days.list`
    const result = destinationArray.map((innerDestination) => ({
      ...innerDestination,
      blocks: innerDestination.blocks.map((block) => {
        const daysExperiences = (block.days || []).flatMap((day) =>
          getExperiences(day.list),
        );
        const listExperiences = getExperiences(block.list);

        const isExpListDuplicated = listExperiences.every((exp) =>
          daysExperiences.includes(exp),
        );

        const updatedDestinationBlock = { ...block };
        if (isExpListDuplicated) {
          delete updatedDestinationBlock.list;
        }

        return updatedDestinationBlock;
      }),
    }));

    return result;
  };

  const combinedTrips = (itineraryData, experiencesData) => {
    const overviewArr = itineraryData.map((block) => {
      const updatedBlocks =
        block?.blocks &&
        block.blocks.map((dayBlock) => {
          const experiencesForDestination =
            experiencesData &&
            experiencesData.find(
              (exp) => exp.location_destination.id === dayBlock.destination,
            );

          const updatedDayBlock = {
            ...dayBlock,
            destinationDetail: experiencesForDestination
              ? experiencesForDestination.location_destination
              : dayBlock.destinationDetail,
            dayCount: dayBlock.days ? dayBlock.days.length : 0,
            fullDay: !!dayBlock?.list,
            days:
              dayBlock?.days &&
              dayBlock.days.map((day) => {
                const updatedDays = {
                  ...day,
                  list:
                    day?.list &&
                    day.list.map((experience) => {
                      const expDetails = experiencesData.find(
                        (exp) => exp.id === experience.experience,
                      );

                      return {
                        ...experience,
                        experienceDetails:
                          expDetails || experience.experienceDetails,
                      };
                    }),
                };
                return updatedDays;
              }),
            list:
              dayBlock?.list &&
              dayBlock.list.map((day) => {
                const expDetails = experiencesData.find(
                  (exp) => exp.id === day.experience,
                );

                return {
                  ...day,
                  experienceDetails: expDetails || day.experienceDetails,
                };
              }),
          };

          return updatedDayBlock;
        });

      return {
        ...block,
        blocks: updatedBlocks,
      };
    });

    return overviewArr;
  };

  useEffect(() => {
    if (itinerary && experiences) {
      const overviewArr = combinedTrips(itinerary, experiences);

      setOverviewData(mergeDuplicateDestinations(overviewArr));
    }
  }, [itinerary, experiences]);

  useEffect(() => {
    if (overviewData && overviewData.length > 0) {
      if (overviewData && overviewData.length > 0) {
        const blockWithDestination = overviewData.find(
          (blockList) =>
            blockList?.blocks && blockList?.blocks[0]?.destinationDetail,
        );

        if (blockWithDestination) {
          setExpanded(
            `${blockWithDestination?.blocks[0]?.destination ?? ''}${blockWithDestination?.title ?? ''}`,
          );
        }
      }
    }
  }, [overviewData]);

  const accordionChange = (blockitem, blockList) => {
    setExpanded(
      expanded === `${blockitem?.destination ?? ''}${blockList.title ?? ''}`
        ? 0
        : `${blockitem?.destination ?? ''}${blockList.title ?? ''}`,
    );
  };
  return (
    <div className="overview">
      <div className="trip-overview-header trip-overview-header-footer">
        <TbPlaneArrival />
        <span> Arrive at {arrivalAirport}</span>
      </div>
      {overviewData &&
        overviewData.map((blockList) => (
          <div key={blockList.title}>
            {blockList?.blocks &&
              blockList?.blocks.map(
                (blockitem, blockIndex) =>
                  blockitem?.destinationDetail && (
                    <TripAccordion
                      key={blockIndex}
                      accordionChange={accordionChange}
                      blockitem={blockitem}
                      blockList={blockList}
                      blockIndex={blockIndex}
                      expanded={expanded}
                      history={history}
                    />
                  ),
              )}
          </div>
        ))}

      <div className="trip-overview-footer trip-overview-header-footer">
        <TbPlaneDeparture />
        <span>
          {departureAirport
            ? `Depart from ${departureAirport}`
            : 'End of the trip'}
        </span>
      </div>
    </div>
  );
}
TripOverview.propTypes = {
  // arrivalAirport: PropTypes.string,
  // departureAirport: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  itinerary: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  experiences: PropTypes.array.isRequired,
};

export default TripOverview;
