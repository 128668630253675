/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRIPS_URL } from 'Constants/URLS';
import { FetchStatus } from 'Constants/staticConstants';
import { TripDetails, TripState } from './tripInterfaces';

export const initialState: TripState = {
  tripDetails: null,
  retrieveTripDetailsStatus: FetchStatus.IDLE,
  error: null,
};

export const fetchTripDetails = createAsyncThunk(
  'trip/fetchTripDetails',
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { api, tripId }: { api: any; tripId: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.get(`${TRIPS_URL}${tripId}/`);
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || 'Error fetching trip details',
      );
    }
  },
);

export const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    clearTripData: (state) => ({
      ...state,
      tripDetails: null,
      retrieveTripDetailsStatus: FetchStatus.IDLE,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTripDetails.pending, (state: TripState) => ({
        ...state,
        retrieveTripDetailsStatus: FetchStatus.IN_PROGRESS,
        error: null,
        tripDetails: null,
      }))
      .addCase(
        fetchTripDetails.fulfilled,
        (state, action: PayloadAction<TripDetails>) => ({
          ...state,
          retrieveTripDetailsStatus: FetchStatus.SUCCEEDED,
          tripDetails: action?.payload,
        }),
      )
      .addCase(
        fetchTripDetails.rejected,
        (state: TripState, action) => ({
          ...state,
          retrieveTripDetailsStatus: FetchStatus.FAILED,
          error: action.error.message,
        }),
      );
  },
});
export const { clearTripData } = tripSlice.actions;

export default tripSlice.reducer;

