import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import {
  IonContent,
  IonPage,
  // useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { HOLIDAY_URL } from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { YearNavigation } from './YearNavigationButtons';
import HolidayScheduledItemRow from './HolidayScheduledItemRow';
import { useAppDispatch } from '../../store/hooks';
import { retrieveScheduledItems } from '../../store/scheduling/schedulingSlice';
import CalendarMonthViewV2 from '../CalendarMonthView/CalendarMonthViewV2';

const HOLIDAY_TAB = 1;
const MONTH_TAB = 2;

function CalendarTimelineView() {
  const [tab, setTab] = useState(HOLIDAY_TAB);

  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [holidays, setHolidays] = useState([]);
  const [year, setYear] = useState(null);
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const contentRef = useRef(null);
  // const [, setCompleteTripData] = useState([]);
  const dispatch = useAppDispatch();

  const retrieveScheduledItemsData = async () => {
    await dispatch(retrieveScheduledItems({ api }));
  };

  useEffect(() => {
    retrieveScheduledItemsData();
  }, [tab]);

  // useIonViewWillEnter(retrieveScheduledItemsData);

  function fetchData() {
    api
      .get(HOLIDAY_URL)
      .then((response) => {
        setHolidays(response.data);
      })
      .catch((error) => {
        console.error('Error fetching holidays:', error);
      });
  }
  useIonViewDidEnter(() => {
    retrieveScheduledItemsData();
    fetchData();
  });
  // useIonViewWillEnter(fetchData);

  function checkAnchor() {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        contentRef.current?.scrollToPoint(0, element.offsetTop - 40, 500);
      }
      window.history.pushState(
        '',
        document.title,
        window.location.pathname + window.location.search,
      );
    }
  }

  useEffect(() => {
    if (holidays.length > 0) {
      checkAnchor();
      const min = Math.min(...holidays.map((holiday) => holiday.year));
      const max = Math.max(...holidays.map((holiday) => holiday.year));
      setMinYear(min);
      setMaxYear(max);
      setYear(min);
    }
  }, [holidays]);

  const handleScroll = () => {
    const separators = document.querySelectorAll('.year-separator');

    separators.forEach((separator) => {
      const rect = separator.getBoundingClientRect();

      if (rect.top <= 250) {
        const visibleYear = Number(separator.textContent);
        setYear(visibleYear);
      }
    });
  };

  function scrollToYear(newYear) {
    const yearElement = document.getElementById(`year${newYear}`);
    if (yearElement) {
      const topPos = yearElement.offsetTop;
      contentRef.current?.scrollToPoint(0, topPos - 40, 500);
    }
  }

  return (
    <IonPage>
      <div className="tabs-top-block">
        <img src="/trabu_logomark_white.svg" alt="Logo" />
        <div className={`tabs-2 active-${tab}`}>
          <button
            type="button"
            aria-label="Preferences"
            onClick={() => setTab(HOLIDAY_TAB)}
          >
            Holidays
          </button>
          <button
            type="button"
            aria-label="Profile"
            onClick={() => setTab(MONTH_TAB)}
          >
            Months
          </button>
        </div>
      </div>

      <IonContent
        className="rounded-content"
        ref={contentRef}
        onIonScroll={handleScroll}
        scrollEvents
      >
        {tab === HOLIDAY_TAB ? (
          <>
            <YearNavigation
              year={year}
              minYear={minYear}
              maxYear={maxYear}
              onClickPrevious={() => scrollToYear(year - 1)}
              onClickNext={() => scrollToYear(year + 1)}
            />
            <div className="calendar trabu-app-container">
              {holidays.map((holiday, index) =>
                HolidayScheduledItemRow({
                  previousHoliday: holidays[index - 1],

                  holiday,
                  index,
                }),
              )}
            </div>
          </>
        ) : (
          <Box className="calenderlist">
            <CalendarMonthViewV2 />
          </Box>
        )}
      </IonContent>
    </IonPage>
  );
}

export default CalendarTimelineView;
