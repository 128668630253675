import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { LogEvent } from './interfaces';

const setCurrentScreen = async (screen: string) => {
  await FirebaseAnalytics.setCurrentScreen({
    screenName: screen,
  });
};

const logEvent = async (event: LogEvent) => {
  await FirebaseAnalytics.logEvent({
    name: event.name,
    params: { context: event.context },
  });
};

export default {
  setCurrentScreen,
  logEvent,
}