import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  TbHeartFilled, TbMapPin, TbPlaneTilt, TbSparkles,
} from 'react-icons/tb';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import getImageUrl from '../Images/ImageUtils';

function OnboardingDiscoverItem({ item, increment, decrement }) {
  const [height, setHeight] = useState(0);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [saved, setSaved] = useState(false);

  const plural = () => {
    if (item.type === 'country') {
      return 'countries';
    }
    if (item.type === 'experience') {
      return 'experience-locations';
    }
    return `${item.type}s`;
  };

  useEffect(() => {
    if (height === 0) {
      switch (item.id % 3) {
        case 0:
          setHeight(200);
          break;
        case 1:
          setHeight(220);
          break;
        case 2:
          setHeight(240);
          break;
        default:
          setHeight(200);
      }
    }
  }, [height]);

  const icon = (itemType) => {
    switch (itemType) {
      case 'trip':
        return <TbPlaneTilt />;
      case 'experience':
        return <TbSparkles />;
      default:
        return <TbMapPin />;
    }
  };

  const toggleHeart = () => {
    if (saved) {
      setSaved(false);
      decrement();
      api.post(`${API_URL}/${plural()}/${item.id}/remove_reaction/`).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      });
    } else {
      setSaved(true);
      increment();
      api.post(`${API_URL}/${plural()}/${item.id}/save/`).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      });
    }
  };

  return (
    // eslint-disable-next-line
    <div
      className="onboarding-discover-item"
      onClick={toggleHeart}
    >
      <img
        alt={item.title}
        src={getImageUrl(item.image)}
        loading="lazy"
        style={{
          height: `${height}px`,
          objectPosition: `${item.image?.interest_x || 50}% ${item.image?.interest_y || 50}$`,
        }}
      />
      <div className="content">
        <span className="type">
          {icon(item.type)}
        </span>
        <span className="title">
          {item.name}
        </span>
      </div>
      <div className={`reaction ${saved ? 'saved' : ''}`}>
        <TbHeartFilled />
      </div>
    </div>
  );
}

OnboardingDiscoverItem.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
};

export default OnboardingDiscoverItem;
