import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';
import apiUtils from '../../Utils/ApiUtils';
import { API_URL, LOGIN_URL } from '../../Constants/URLS';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function OnboardingEmail({
  firstName, lastName,
}) {
  const [validation, setValidation] = useState(false);
  const [existingEmail, setExistingEmail] = useState(false);
  const { storeTokens } = useContext(UserContext);
  const api = apiUtils();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const signUp = () => {
    setLoading(true);
    api.postNoAuth(`${API_URL}/dj-rest-auth/registration/`, {
      email,
      first_name: firstName,
      last_name: lastName,
      password1: password,
      password2: confirmPassword,
    }).then(() => {
      api.postNoAuth(LOGIN_URL, { email, password })
        .then((response) => {
          if (response?.status === 200) {
            storeTokens(response.data.access, response.data.refresh);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'There has been an error logging in. Please try again.',
              confirmButtonText: 'Close',
            });
          }
          setLoading(false);
        }).catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There has been an error logging in. Please try again.',
            confirmButtonText: 'Close',
          });
          setLoading(false);
        });
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There has been an error signing up. Please try again.',
        confirmButtonText: 'Close',
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    const newValidation = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    setValidation(newValidation);
    setLoading(true);
    api.postNoAuth(`${API_URL}/users/check_email/`, {
      email,
    }).then((res) => {
      setExistingEmail(res.data.exists);
      setLoading(false);
    }).catch(() => {
      setExistingEmail(false);
      setLoading(false);
    });
  }, [email]);

  return (
    <div className="onboarding-email">
      <h1>
        What is your email?
      </h1>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {!validation && email !== '' && (
        <span className="validation">
          Please enter a valid email.
        </span>
      )}
      {existingEmail && (
        <span className="validation">
          This email is already in use.
        </span>
      )}
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      {password && confirmPassword && password !== confirmPassword && (
        <span className="validation">
          Passwords do not match.
        </span>
      )}
      <Button
        className="btn btn-teal"
        startIcon={<IconArrowRight />}
        sx={{ mt: 3 }}
        onClick={signUp}
        disabled={loading
          || !validation
          || existingEmail
          || !password
          || !confirmPassword
          || password !== confirmPassword}
      >
        Next
      </Button>
    </div>
  );
}

OnboardingEmail.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default OnboardingEmail;
