import { Button, CircularProgress } from '@mui/material';
import { TbCalendar, TbCalendarOff } from 'react-icons/tb';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FetchStatus } from '../../Constants/staticConstants';
import ScheduleItemModal from '../ScheduleItemModal';
import { TripDetails, TripImage } from '../../store/trip/tripInterfaces';
import {
  Country,
  ScheduledItem,
} from '../../store/scheduling/schedulingInterfaces';
import {
  retrieveScheduledItems,
  schedulingDetailsForCountry,
  schedulingDetailsForTrip,
  unscheduleItem,
} from '../../store/scheduling/schedulingSlice';
import { useStore } from 'react-redux';
import { RootState } from '../../store/store';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { useIonViewWillEnter } from '@ionic/react';
import './ScheduleComponentStyles.scss';
import AutoscheduleComponentV2 from '../../Views/TripInformationView/AutoscheduleComponentV2';

interface Location {
  id: number;
  name: string;
  type: string;
  image: TripImage;
}

export type SchedulableItem = TripDetails | Country | Location;

export interface ScheduleComponentV2Props {
  item: SchedulableItem;
}

const ScheduleComponentV2 = ({ item }: ScheduleComponentV2Props) => {
  const [showUnsheduleLoader, setShowUnscheduleLoader] = useState(false);

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const { retrieveScheduledItemsStatus, unscheduleItemStatus } = useAppSelector(
    (state) => state.scheduling,
  );
  const state = useStore().getState() as RootState;
  const dispatch = useAppDispatch();
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const isACountry = (x: SchedulableItem) =>
    (x satisfies Country) && 'type' in item;

  let scheduledItems: ScheduledItem[] = [];
  if (isACountry(item)) {
    scheduledItems = schedulingDetailsForCountry(state, item.id);
  } else {
    scheduledItems = schedulingDetailsForTrip(state, item?.id);
  }

  const ScheduleButton = () => (
    <>
      <Button
        className="btn btn-orange btn-long"
        startIcon={<TbCalendar />}
        onClick={() => setIsScheduleModalOpen(true)}
      >
        Schedule Trip
      </Button>
      {!isACountry(item) && <AutoscheduleComponentV2 tripDetails={item} />}
    </>
  );
  const fetchData = () => {
    const refresh = async () => {
      if (retrieveScheduledItemsStatus !== FetchStatus.IN_PROGRESS) {
        await dispatch(
          retrieveScheduledItems({
            api,
          }),
        );
      }
    };
    refresh();
  };

  useIonViewWillEnter(fetchData);
  useEffect(fetchData, []);

  const onUnschedule = (scheduledItem: ScheduledItem) => {
    (async () => {
      setShowUnscheduleLoader(true);
      await dispatch(
        unscheduleItem({
          api,
          item: scheduledItem,
        }),
      );
      await dispatch(
        retrieveScheduledItems({
          api,
        }),
      );
      setShowUnscheduleLoader(false);
    })();
  };

  const ScheduledInformation = ({
    scheduled,
  }: {
    scheduled: ScheduledItem;
  }) => {
    const { name, year } = scheduled.holiday;

    const label = scheduled.trip
      ? 'Trip is scheduled for '
      : `Your visit to this country is scheduled for `;
    return (
      <div className="scheduled-item">
        <p>
          {`${label}`}
          <b>
            {' '}
            {name} {year}
          </b>
          .
        </p>

        <Button
          className="btn btn-orange btn-long loading-container"
          startIcon={<TbCalendarOff />}
          onClick={() => onUnschedule(scheduled)}
          disabled={
            unscheduleItemStatus === FetchStatus.IN_PROGRESS ||
            showUnsheduleLoader
          }
          endIcon={
            unscheduleItemStatus === FetchStatus.IN_PROGRESS ||
            showUnsheduleLoader ? (
              <CircularProgress size={20} />
            ) : null
          }
        >
          Unschedule
        </Button>

        <Button
          className="btn-white btn-long btn btn-margin"
          onClick={() => setIsScheduleModalOpen(true)}
          startIcon={<img src="/reschedule.svg" alt="Icon" />}
        >
          Reschedule
        </Button>
      </div>
    );
  };

  const isScheduled = scheduledItems && scheduledItems?.length > 0;

  return (
    <div>
      {isScheduled ? (
        <ScheduledInformation scheduled={scheduledItems[0]} />
      ) : (
        <div>
          <div className="scheduled-item">
            {retrieveScheduledItemsStatus === FetchStatus.IN_PROGRESS ? (
              <CircularProgress />
            ) : (
              <ScheduleButton />
            )}
          </div>
        </div>
      )}
      <ScheduleItemModal
        openModal={isScheduleModalOpen}
        item={item}
        onCloseModal={() => setIsScheduleModalOpen(false)}
        from="other"
      />
    </div>
  );
};
export default ScheduleComponentV2;
