import React from 'react';
import { Button, Grid } from '@mui/material';
import getImageUrl from '../Images/ImageUtils';
import './CalendarMonthCard.scss';
import { CalendarMonthCardProps } from './interfaces';

const CalendarMonthCard: React.FC<CalendarMonthCardProps> = ({
  item,
  onClick,
  onRescheduleClick,
}) => {
  const imageUrl = getImageUrl(item.image);
  const rescheduleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRescheduleClick();
  };
  return (
    <Grid
      sx={{
        height: {
          xs: '120px',
          sm: '120px',
          md: '140px',
          lg: '160px',
          xl: '200px',
        },
      }}
      className="calenderheader-card"
      onClick={onClick}
      role="button"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imageUrl})`,
        backgroundPosition: `${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_x : 50}% ${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_y : 50}%`,
      }}
    >
      <div className="overlay" />
      <div className="footertext">
        <h5>{item.name}</h5>
      </div>

      <Button
        className="btn-white btnstyle"
        onClick={rescheduleClick}
        startIcon={<img src="/reschedule.svg" alt="Icon" />}
      >
        Reschedule
      </Button>
    </Grid>
  );
};

export default CalendarMonthCard;
