import React, { useState } from 'react';
import { Button } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import OnboardingName from '../../Components/Onboarding/OnboardingName';
import OnboardingEmail from '../../Components/Onboarding/OnboardingEmail';

function OnboardingView({ close }) {
  // eslint-disable-next-line
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const back = () => {
    if (step === 1) {
      close();
      return;
    }
    setStep(step - 1);
  };

  return (
    <div className="onboarding-view">
      <img src="/trabu-icon-black.svg" className="trabu-icon" alt="Icon" />
      <Button
        className="btn btn-icon btn-white btn-back"
        onClick={back}
      >
        <IconArrowLeft />
      </Button>
      {step === 1 && (
        <OnboardingName
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          nextStep={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <OnboardingEmail
          firstName={firstName}
          lastName={lastName}
        />
      )}
    </div>
  );
}

OnboardingView.propTypes = {
  close: PropTypes.func.isRequired,
};

export default OnboardingView;
