/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TbMap, TbMoon, TbPlaneArrival, TbPlaneDeparture, TbRoute, TbSparkles,
} from 'react-icons/tb';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import { COMPACT_DESTINATION_URL } from '../../../Constants/URLS';
import getImageUrl from '../../Images/ImageUtils';

function DestinationBlock({ days, destinationId }) {
  const [destination, setDestination] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const history = useHistory();

  useEffect(() => {
    if (destination) return;
    api.get(`${COMPACT_DESTINATION_URL}${destinationId}/`)
      .then((response) => {
        setDestination(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching destination.', error);
      });
  }, []);

  return destination && (
    <div className="itinerary-block destination">
      <div className="itinerary-block-node" />
      {/* eslint-disable-next-line */}
      <div
        className="itinerary-block-content"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${getImageUrl(destination.image)})`,
          backgroundPosition: `${destination.image?.interest_x || 50}% ${destination.image?.interest_y || 50}%`,
        }}
        onClick={() => history.push(`/app/location/destination/${destinationId}`)}
      >
        <TbMap />
        <h2>
          {days}
          {' in '}
          {destination.name}
        </h2>
      </div>
    </div>
  );
}

DestinationBlock.propTypes = {
  days: PropTypes.string.isRequired,
  destinationId: PropTypes.number.isRequired,
};

function DestinationName({ destinationId }) {
  const [destination, setDestination] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  useEffect(() => {
    if (destination) return;
    api.get(`${COMPACT_DESTINATION_URL}${destinationId}/`)
      .then((response) => {
        setDestination(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching destination.', error);
      });
  }, []);

  return destination && destination.name;
}

DestinationName.propTypes = {
  destinationId: PropTypes.number.isRequired,
};

function Itinerary({
  arrivalAirport, departureAirport, itinerary, experiences,
}) {
  const history = useHistory();
  function computeDay(mainBlock, block) {
    if (mainBlock.blocks.length === 1) {
      if (block.days) return `${block.days.length} days`;
      return 'Full day';
    }
    if (mainBlock.blocks.indexOf(block) === 0) {
      return 'Morning';
    } if (mainBlock.blocks.indexOf(block) === 1) {
      return 'Afternoon';
    }
    return 'Evening';
  }

  return (
    <div className="itinerary">
      {itinerary && itinerary.map((mainBlock, idx) => (
        <>
          {mainBlock && mainBlock.blocks && mainBlock.blocks.map((block, blockIdx) => (
            <>
              <div className={`itinerary-travel-block ${idx === 0 && blockIdx === 0 ? 'first' : ''}`}>
                <div className="itinerary-block-node" />
                <div className="itinerary-travel-content">
                  {idx === 0 && blockIdx === 0 ? (
                    <>
                      <TbPlaneArrival />
                      Arrive at
                      {' '}
                      {arrivalAirport}
                    </>
                  ) : (
                    <>
                      {itinerary[idx - 1] && itinerary[idx - 1].blocks
                        && itinerary[idx - 1].blocks[itinerary[idx - 1]
                          .blocks.length - 1].destination === block.destination ? (
                        <TbMoon />
                      ) : (
                        <TbRoute />
                      )}
                      <div>
                        {itinerary[idx - 1] && itinerary[idx - 1].blocks
                          && itinerary[idx - 1].blocks[itinerary[idx - 1]
                            .blocks.length - 1].destination === block.destination ? (
                          <>
                            Overnight stay in
                            {' '}
                          </>
                        ) : (
                          <>
                            Travel to
                            {' '}
                          </>
                        )}
                        <DestinationName destinationId={block.destination} />
                        {block.list && block.list[0].travel
                          && block.list[0].from !== block.list[0].to && (
                            <span>
                              {' '}
                              (
                              {block.list[0].travel}
                              )
                            </span>
                          )}
                        {block.days && block.days[0].list[0].travel
                          && block.days[0].list[0].from !== block.days[0].list[0].to && (
                            <span>
                              {' '}
                              (
                              {block.days[0].list[0].travel}
                              )
                            </span>
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <DestinationBlock
                days={computeDay(mainBlock, block)}
                destinationId={block.destination}
              />
              {block.days ? (
                <>
                  {block.days.map((day, idx3) => (
                    <>
                      {day.list.map((item, idx4) => {
                        const experience = experiences.find((exp) => exp.id === item.experience);
                        return experience && (
                          <>
                            <div
                              className={`itinerary-block ${idx3 === block.days.length - 1 && idx4 === day.list.length - 1 ? 'last-item' : ''}`}
                              key={idx3}
                            >
                              <div className="itinerary-block-node" />
                              {/* eslint-disable-next-line */}
                              <div
                                className="itinerary-block-content"
                                style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${getImageUrl(experience.image)})` }}
                                onClick={() => history.push(`/app/experience/${experience.id}`)}
                              >
                                <TbSparkles />
                                <h2>{experience.experience.name}</h2>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {block.list.map((item, idx3) => {
                    const experience = experiences.find((exp) => exp.id === item.experience);
                    return experience && (
                      <>
                        <div
                          className={`itinerary-block ${idx3 === block.list.length - 1 ? 'last-item' : ''}`}
                          key={idx3}
                        >
                          <div className="itinerary-block-node" />
                          {/* eslint-disable-next-line */}
                          <div
                            className="itinerary-block-content"
                            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${getImageUrl(experience.image)})` }}
                            onClick={() => history.push(`/app/experience/${experience.id}`)}
                          >
                            <TbSparkles />
                            <h2>{experience.experience.name}</h2>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </>
          ))}
          {idx === itinerary.length - 1 && (
            <div className="itinerary-travel-block last">
              <div className="itinerary-block-node" />
              <div className="itinerary-travel-content">
                <TbPlaneDeparture />
                {departureAirport ? `Depart from ${departureAirport}` : 'End of the trip'}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}

Itinerary.propTypes = {
  arrivalAirport: PropTypes.string,
  departureAirport: PropTypes.string,
  // eslint-disable-next-line
  itinerary: PropTypes.array.isRequired,
  // eslint-disable-next-line
  experiences: PropTypes.array.isRequired,
};

Itinerary.defaultProps = {
  arrivalAirport: '',
  departureAirport: '',
};

export default Itinerary;
