import React from 'react';
import PropTypes from 'prop-types';
import { TbPlaneTilt } from 'react-icons/tb';

function Airport({ airport, selected, toggleAirport }) {
  return (
    // eslint-disable-next-line
    <div
      className={`airport ${selected ? 'selected' : ''}`}
      onClick={() => toggleAirport(airport)}
    >
      <div className="info">
        <b>{airport.name}</b>
        <small>{`${airport.city}, ${airport.country}`}</small>
      </div>
      <span className="code">
        <TbPlaneTilt />
        {airport.iata_code}
      </span>
    </div>
  );
}

Airport.propTypes = {
  // eslint-disable-next-line
  airport: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  toggleAirport: PropTypes.func.isRequired,
};

export default Airport;
