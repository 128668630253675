/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import {
  TbHelpSquareRounded, TbInbox,
  TbKey,
  TbMail, TbPlaneDeparture,
  TbPower, TbTrash,
  TbUser,
  TbUserHeart,
} from 'react-icons/tb';
import moment from 'moment';
import { IconCalendarMonth, IconPhone } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage, IonHeader, IonToolbar } from '@ionic/react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import Name from '../../Components/ProfileSettings/Name';
import DateOfBirth from '../../Components/ProfileSettings/DateOfBirth';
import PhoneNumber from '../../Components/ProfileSettings/PhoneNumber';
import Password from '../../Components/ProfileSettings/Password';
import TravelCompanions from '../../Components/ProfileSettings/TravelCompanions';
import EmailNotifications from '../../Components/ProfileSettings/EmailNotifications';

function ProfileView() {
  const { user, logout, deleteAccount } = useContext(UserContext);
  const [tab, setTab] = useState(1);
  const [nameSheetOpen, setNameSheetOpen] = useState(false);
  const [dateOfBirthSheetOpen, setDateOfBirthSheetOpen] = useState(false);
  const [phoneSheetOpen, setPhoneSheetOpen] = useState(false);
  const [passwordSheetOpen, setPasswordSheetOpen] = useState(false);
  const [companionsSheetOpen, setCompanionsSheetOpen] = useState(false);
  const [emailNotificationsSheetOpen, setEmailNotificationsSheetOpen] = useState(false);
  const history = useHistory();

  const handleDeleteAccount = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete your account? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccount().catch(() => {
          Swal.fire('Error', 'Account deletion failed. Please try again.', 'error');
        });
      }
    });
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, log out!',
      cancelButtonText: 'No, stay',
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  const companionsString = () => {
    if (user.companions.length === 0) {
      return 'None';
    }
    return user.companions.map((companion) => `${companion.name} (${companion.age})`).join(', ');
  };

  const notificationText = (notification) => {
    switch (notification) {
      case 'ESSENTIAL':
        return 'Essential notifications';
      default:
        return 'All notifications';
    }
  };

  // if get parameter is set, immediately switch tab
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get('tab');
    if (tabParam) {
      setTab(parseInt(tabParam, 10));
    }
  }, []);

  return user.first_name && (
    <IonPage>
      <div className="tabs-top-block">
        <img src="/trabu_logomark_white.svg" alt="Logo" />
        <div className={`tabs-2 active-${tab}`}>
          <button type="button" aria-label="Profile" onClick={() => setTab(1)}>
            Profile
          </button>
          <button type="button" aria-label="Preferences" onClick={() => setTab(2)}>
            Preferences
          </button>
        </div>
        <Button
          className="btn btn-icon btn-orange"
          onClick={handleLogout}
        >
          <TbPower />
        </Button>
      </div>

      <IonContent className="rounded-content">
        <div className="profile-container">
          {tab === 1 && (
            <>
              <h1>Personal Information</h1>
              <div className="options">
                <button type="button" aria-label="Name" onClick={() => setNameSheetOpen(true)} className="option">
                  <TbUser />
                  <div className="option-text">
                    <span>Full Name</span>
                    {user.first_name}
                    {' '}
                    {user.last_name}
                  </div>
                </button>
                <button type="button" aria-label="Email" disabled className="option">
                  <TbMail />
                  <div className="option-text">
                    <span>Email</span>
                    {user.email}
                  </div>
                </button>
                <button
                  type="button"
                  aria-label="Password"
                  className="option"
                  onClick={() => setPasswordSheetOpen(true)}
                >
                  <TbKey />
                  <div className="option-text">
                    <span>Password</span>
                    ************
                  </div>
                </button>
                <button
                  type="button"
                  aria-label="Date of Birth"
                  className="option"
                  onClick={() => setDateOfBirthSheetOpen(true)}
                >
                  <IconCalendarMonth />
                  <div className="option-text">
                    <span>Date of Birth</span>
                    {user.date_of_birth ? moment(user.date_of_birth).format('MMMM D, YYYY') : 'Not provided'}
                  </div>
                </button>
                <button
                  type="button"
                  aria-label="Phone Number"
                  className="option"
                  onClick={() => setPhoneSheetOpen(true)}
                >
                  <IconPhone />
                  <div className="option-text">
                    <span>Phone Number</span>
                    {user.phone_number || 'Not provided'}
                  </div>
                </button>
                <button
                  type="button"
                  aria-label="Travel Companions"
                  className="option"
                  onClick={() => setCompanionsSheetOpen(true)}
                >
                  <TbUserHeart />
                  <div className="option-text">
                    <span>Travel Companions</span>
                    {companionsString()}
                  </div>
                </button>
              </div>
              <button type="button" aria-label="Log Out" className="link" onClick={() => history.push('/app/support')}>
                <TbHelpSquareRounded />
                Support
              </button>
              <button type="button" aria-label="Delete Account" className="link" onClick={handleDeleteAccount}>
                <TbTrash />
                Delete Account
              </button>
              <button type="button" aria-label="Log Out" className="link" onClick={handleLogout}>
                <TbPower />
                Log Out
              </button>
              <Name open={nameSheetOpen} close={() => setNameSheetOpen(false)} />
              <DateOfBirth
                open={dateOfBirthSheetOpen}
                close={() => setDateOfBirthSheetOpen(false)}
              />
              <PhoneNumber open={phoneSheetOpen} close={() => setPhoneSheetOpen(false)} />
              <Password open={passwordSheetOpen} close={() => setPasswordSheetOpen(false)} />
              <TravelCompanions
                open={companionsSheetOpen}
                close={() => setCompanionsSheetOpen(false)}
              />
            </>
          )}
          {tab === 2 && (
            <>
              <h1>Personal Information</h1>
              <div className="options">
                <button
                  type="button"
                  aria-label="Airports"
                  className="option"
                  onClick={() => history.push('/app/profile/airports')}
                >
                  <TbPlaneDeparture />
                  <div className="option-text">
                    <span>Preferred Departure Airports</span>
                    {user.airports.map((airport) => airport.iata_code).join(', ')}
                  </div>
                </button>
                <button
                  type="button"
                  aria-label="Notifications"
                  className="option"
                  onClick={() => setEmailNotificationsSheetOpen(true)}
                >
                  <TbInbox />
                  <div className="option-text">
                    <span>Email Notifications</span>
                    {notificationText(user.notifications)}
                  </div>
                </button>
              </div>
              <EmailNotifications
                open={emailNotificationsSheetOpen}
                close={() => setEmailNotificationsSheetOpen(false)}
              />
            </>
          )}
        </div>
      </IonContent>
      {/* </div> */}
    </IonPage>
  );
}

export default ProfileView;
