import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TRIPS_URL } from '../../../../Constants/URLS';
import TripIdea from '../../TripIdea';
import { IdeasFilterContext } from '../../../../Providers/IdeasFilterProvider/IdeasFilterProvider';
import NoSearchResult from '../../../NoSearchResult/Index';

function TripsIdeasTab() {
  const [trips, setTrips] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const filters = useContext(IdeasFilterContext);

  function checkAnchor() {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  function fetchTrips() {
    api
      .get(
        `${TRIPS_URL}?no_reactions=true${filters.makeMonthsFilter()}${filters.makeInterestCategoriesFilter()}${filters.makeDaysFilter()}${filters.makeChildrenAgesFilter()}&is_safe=true`,
      )
      .then((response) => {
        setTrips(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  useEffect(() => {
    fetchTrips();
    setTimeout(() => checkAnchor(), 1000);
  }, []);

  useEffect(() => {
    fetchTrips();
  }, [filters]);

  return (
    <>
      {trips &&
        trips.map((trip, index) => (
          <TripIdea
            idea={trip}
            next={index < trips.length - 1 ? trips[index + 1].id : ''}
            key={trip.id}
          />
        ))}
      {trips && trips.length === 0 && <NoSearchResult />}
      {!trips && (
        <div className="idea loading">
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default TripsIdeasTab;
