import React from 'react';
import { Masonry } from '@mui/lab';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Card from '../Saved/Card';
import Tabs3 from '../Tabs3';

function Recommendations({ recommendations }) {
  const history = useHistory();

  const handleClick = (type, id) => {
    if (type === 'experience') {
      history.push(`/app/experience/${id}`);
    } else if (type === 'trip') {
      history.push(`/app/trip/${id}`);
    } else {
      history.push(`/app/location/${type}/${id}`);
    }
  };

  const tab1 = recommendations.trips && (
    <>
      {recommendations.trips.length > 0 ? (
        <Masonry columns={2} spacing={1} className="saved-items-masonry" sequential>
          {recommendations.trips.map((recommendation) => (
            <Card
              title={recommendation.name}
              type={recommendation.type}
              image={recommendation.image}
              key={recommendation.id}
              onClick={() => handleClick(recommendation.type, recommendation.id)}
            />
          ))}
        </Masonry>
      ) : (
        <div className="placeholder">No recommended trips.</div>
      )}
    </>
  );

  const tab2 = recommendations.places && (
    <>
      {recommendations.places.length > 0 ? (
        <Masonry columns={2} spacing={1} className="saved-items-masonry" sequential>
          {recommendations.places.map((recommendation) => (
            <Card
              title={recommendation.name}
              type={recommendation.type}
              image={recommendation.image}
              key={recommendation.id}
              onClick={() => handleClick(recommendation.type, recommendation.id)}
            />
          ))}
        </Masonry>
      ) : (
        <div className="placeholder">No recommended places.</div>
      )}
    </>
  );

  const tab3 = recommendations.experiences && (
    <>
      {recommendations.experiences.length > 0 ? (
        <Masonry columns={2} spacing={1} className="saved-items-masonry" sequential>
          {recommendations.experiences.map((recommendation) => (
            <Card
              title={recommendation.name}
              type={recommendation.type}
              image={recommendation.image}
              key={recommendation.id}
              onClick={() => handleClick(recommendation.type, recommendation.id)}
            />
          ))}
        </Masonry>
      ) : (
        <div className="placeholder">No recommended experiences.</div>
      )}
    </>
  );

  return (
    <>
      <h2>Recommendations</h2>
      <Tabs3
        tab1={{
          title: 'Trips',
          content: tab1,
        }}
        tab2={{
          title: 'Places',
          content: tab2,
        }}
        tab3={{
          title: 'Experiences',
          content: tab3,
        }}
        initial={1}
      />
    </>
  );
}

Recommendations.propTypes = {
  // eslint-disable-next-line
  recommendations: PropTypes.object.isRequired,
};

export default Recommendations;
