import { Country, ScheduledItem } from './schedulingInterfaces';
import { UserTrip } from '../../Views/CalendarMonthView/interfaces';
import { TripDetails, TripImage, TripTemplate } from '../trip/tripInterfaces';

interface HolidayResponse {
  id: number;
  year: number;
  name: string;
  icon: {
    icon: string;
    name: string;
  };
}

interface TripResponse {
  id: number;
  name: string;
  approval_granted: boolean;
  description: string;
  image: TripImage;
  additional_images: TripImage[];
  trip_template: TripTemplate;
  number_of_days: number;
}

interface UserTripResponse {
  id: number;
  name?: string;
  // holiday?: HolidayResponse,
  trip?: TripResponse;
}

interface CountryResponse {
  name: string;
  id: number;
  image: TripImage | null;
}

interface ImageResponse {
  id: number;
  image_file: string;
  caption: string;
  interest_x: number;
  interest_y: number;
  location: string;
  compressed_1500_url: string;
  compressed_1000_url: string;
}

interface GetScheduledItemsResponse {
  id: number;
  holiday: HolidayResponse;
  trip?: UserTripResponse;
  country?: CountryResponse;
}

export const holidayMapper = (data: HolidayResponse) => ({
  id: data?.id,
  year: data?.year,
  name: data?.name,
  icon: {
    icon: data?.icon?.icon,
    name: data?.icon?.name,
  },
});

const tripMapper = (data: TripResponse): TripDetails => ({
  id: data.id,
  name: data.name,
  approval_granted: data.approval_granted,
  description: data.description,
  image: data.image,
  additional_images: data.additional_images,
  trip_template: data.trip_template,
  number_of_days: data.number_of_days,
});

const userTripMapper = (data: UserTripResponse): UserTrip => ({
  id: data.id,
  name: data.name,
  trip: data.trip ? tripMapper(data.trip) : null,
});

const imageMapper = (data: ImageResponse): TripImage => ({
  id: data.id,
  image_file: data.image_file,
  caption: data.caption,
  interest_x: data.interest_x,
  interest_y: data.interest_y,
  location: data.location,
  compressed_1500_url: data.compressed_1500_url,
  compressed_1000_url: data.compressed_1000_url,
});

const countryMapper = (data: CountryResponse): Country => ({
  id: data.id,
  name: data.name,
  image: data.image ? imageMapper(data.image) : null,
});

export default (data: GetScheduledItemsResponse): ScheduledItem => ({
  id: data.id,
  holiday: holidayMapper(data.holiday),
  trip: data.trip ? userTripMapper(data.trip) : null,
  country: data.country ? countryMapper(data.country) : null,
});
