import React from 'react';
import PropTypes from 'prop-types';
import { IconSparkles } from '@tabler/icons-react';
import getImageUrl from '../Images/ImageUtils';
import './OverviewListCard.scss';

function OverviewListCard({ item }) {
  const imageUrl = getImageUrl(item.image);

  return (
    <div className="overviewlist-card">
      <div className="overviewlist-card-inner"
           style={{
             backgroundImage: `url(${imageUrl})`,
             backgroundPosition: `${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_x : 50}% ${item.image && imageUrl !== '/placeholder-square.jpg' ? item.image.interest_y : 50}%`
           }}
      >
        <div className="overlay" />
        <div className="icon">
          <IconSparkles />
        </div>
        <h3 className="title_span"><span>{item.name}</span></h3>
      </div>
    </div>
  );
}

OverviewListCard.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired
};

export default OverviewListCard;
