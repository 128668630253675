import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Card from './Saved/Card';
import { Button } from '@mui/material';
import { IconHeart } from '@tabler/icons-react';
import './DiscoverCard.scss';
import { API_URL, REACTIONS_URL } from 'Constants/URLS';
import apiUtilsHook from '../Utils/ApiUtilsHook';
import { UserContext } from 'Providers/UserProvider/UserProvider';
import { useIonViewWillEnter } from '@ionic/react';
import useEventTrack from 'hooks/useEventTrack';
import TrackEvent from 'Constants/eventConstant';
import TrackError from 'Constants/errorEventConstant';

function DiscoverCard({ image = null, title, type, id = null }) {
  const history = useHistory();
  const [location, setLocation] = useState(null);
  const [reactionStatus, setReactionStatus] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const { trackEvent, trackError } = useEventTrack();

  const handleClick = () => {
    if (type === 'trip') {
      trackEvent(TrackEvent.TRIP_CARD_CLICKED, {
        type,
        discoverCardId: id,
      })
      history.push(`/app/trip/${id}`);
      return;
    }
    if (type === 'experience') {
      trackEvent(TrackEvent.EXPERIENCE_CARD_CLICKED, {
        type,
        discoverCardId: id,
      })
      history.push(`/app/experience/${id}`);
      return;
    }

    trackEvent(TrackEvent.LOCATION_CARD_CLICKED, {
      type,
      discoverCardId: id,
    })
    history.push(`/app/location/${type}/${id}`);
  };

  const fetchReactionStatus = () => {
    api
      .get(`${REACTIONS_URL}?model=${type}&object_id=${id}`)
      .then((response) => {
        const savedReaction = response.data.find((r) => r.reaction === 'saved');
        setReactionStatus(savedReaction ? 'saved' : null);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching reaction status:', error);
      });
  };

  useIonViewWillEnter(() => {
    fetchReactionStatus();
  });

  useEffect(() => {
    const path = type === 'country' ? 'countries' : `${type}s`;

    if (!location) {
      api.get(`${API_URL}/${path}/${id}`).then((response) => {
        setLocation(response.data);
      });
    }
    fetchReactionStatus();
  }, []);

  const toggleLoved = () => {
    try {
      const path = type === 'country' ? 'countries' : `${type}s`;
      if (reactionStatus) {
        setReactionStatus(null);
        api
          .post(
            `${API_URL}/${type === 'country' ? 'countries' : `${type}s`}/${id}/remove_reaction/`,
          )
          .then(() => {
            console.log('Successfully removed reaction');
            trackEvent(TrackEvent.REACTION_REMOVED, {
              type,
              discoverCardId: id,
            });
          })
          .catch((error) => {
            trackError(TrackError.REACTION_REMOVED, {
              type,
              discoverCardId: id,
            });
            console.error('Error removing reaction:', error);
            setReactionStatus('saved');
          });
      } else {
        setReactionStatus('saved');
        api
          .post(`${API_URL}/${path}/${id}/save/`)
          .then(() => {
            console.log('Successfully saved reaction');
            trackEvent(TrackEvent.REACTION_ADDED, {
              type,
              discoverCardId: id,
            });
          })
          .catch((error) => {
            trackError('Error saving reaction', {
              type,
              discoverCardId: id,
            })
            console.error('Error saving reaction:', error);
            setReactionStatus(null);
          });
      }
    } catch (error) {
      console.error('Unexpected error in toggleLoved:', error);
    }
  };
  return (
    <>
      <div onClick={handleClick} aria-hidden="true">
        <div className="discover-image-container">
          <Card title={title} type={type} id={id} image={image} />

          <Button
            data-testid="heart-test-button"
            className={` btn btn-icon heart-button ${reactionStatus === 'saved' ? 'btn-orange' : 'btn-white'}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleLoved();
            }}
          >
            <IconHeart />
            {/* {reactionStatus === 'saved' ? <IconHeartOff /> : <IconHeart />} */}
          </Button>
        </div>
      </div>
    </>
  );
}

DiscoverCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DiscoverCard;
