/* eslint-disable */

import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import {Button} from '@mui/material';
import { FaSave } from 'react-icons/fa';
import {TbCheck, TbX} from 'react-icons/tb';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import {UserContext} from '../../Providers/UserProvider/UserProvider';
import './ProfileSettings.scss';
import {API_URL} from '../../Constants/URLS';
import PasswordChecklist from "react-password-checklist"

function Password({open, close}) {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setPassword('');
    setConfirmPassword('');
  }, [open]);

  const updatePassword = () => {
    if (!isValid) {
      Swal.fire('Error', 'Please complete all the requirements..', 'error');
      return;
    }
    api.post(`${API_URL}/dj-rest-auth/password/change/`,
      { new_password1: password, new_password2: confirmPassword }
    )
      .then(() => {
        Swal.fire('Success', 'Password updated successfully.', 'success');
        close();
      });
  };

  return (
    <Sheet snapPoints={[0.95]} isOpen={open} onClose={close}>
      <Sheet.Container>
        <Sheet.Header/>
        <Sheet.Content>
          <div className="settings">
            <h1>Update Password</h1>
            <label>New Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Type new password..."
            />
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password..."
            />

            <div className="password-checklist">
              <PasswordChecklist
                iconComponents={{
                  ValidIcon: <TbCheck />,
                  InvalidIcon: <TbX />,
                }}
                rules={["minLength", "specialChar", "number", "capital", "match"]}
                minLength={8}
                value={password}
                valueAgain={confirmPassword}
                onChange={(v) => setIsValid(v)}
              />
            </div>

             <Button
              className="btn btn-orange"
              startIcon={<FaSave />}
              sx={{ mt: 3 }}
              onClick={updatePassword}
             >
              Save
             </Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={close}/>
    </Sheet>
  );
}

Password.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default Password;
