import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TbMapPin, TbPlaneTilt, TbSparkles } from 'react-icons/tb';
import getImageUrl from '../../Images/ImageUtils';
import FakeCard from '../FakeCard';

function Card({
  image = null,
  title,
  type,

  onClick = () => {},
}) {
  const [height, setHeight] = useState(0);
  const cardRef = useRef();
  const [tripImageLoaded, setTripImageLoaded] = useState(false);

  const handleTripImageLoad = () => {
    setTripImageLoaded(true);
  };

  const handleTripImageError = () => {
    setTripImageLoaded(true);
  };
  useEffect(() => {
    if (height === 0) {
      setHeight(Math.floor(Math.random() * 150) + 150);
    }
  }, [height]);

  const icon = (itemType) => {
    switch (itemType) {
      case 'trip':
        return <TbPlaneTilt />;
      case 'experience':
        return <TbSparkles />;
      default:
        return <TbMapPin />;
    }
  };

  return (
    <>
      {!tripImageLoaded && <FakeCard />}
      {/* eslint-disable-next-line */}
      <div ref={cardRef} className="card" onClick={onClick}>
        <img
          alt={title}
          src={getImageUrl(image)}
          style={{
            height: `${height}px`,
            objectPosition: `${image?.interest_x || 50}% ${image?.interest_y || 50}%`,
          }}
          loading="lazy"
          onLoad={handleTripImageLoad}
          onError={handleTripImageError}
        />
        <div className="card-content">
          <span className="icon">{icon(type)}</span>
          <h4>{title}</h4>
        </div>
      </div>
    </>
  );
}

Card.propTypes = {
  // eslint-disable-next-line
  image: PropTypes.object,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // onClick: PropTypes.func,
};

export default Card;
