import React from 'react';
import PropTypes from 'prop-types';

function SelectablePill({ text, selected, onSelect }) {
  return (
    <button
      className={`selectable-pill ${selected && 'selected'}`}
      onClick={() => onSelect()}
      type="button"
    >
      {text}
    </button>
  );
}

SelectablePill.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectablePill;
