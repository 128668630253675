import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Button } from '@mui/material';
import {
  IconFolderHeart, IconStar, IconTrash, IconFolder, IconFolderPlus,
} from '@tabler/icons-react';
import Folder from '../../Components/BucketList/Folder';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import ComingSoon from '../../Components/ComingSoon';

function BucketListView() {
  const [tab, setTab] = useState(1);
  const [userFolders, setUserFolders] = useState([]);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addName, setAddName] = useState('');

  const fetchFolders = () => {
    api.get(`${API_URL}/folders/`)
      .then((response) => {
        setUserFolders(response.data);
      });
  };

  const addFolder = () => {
    if (addName === '') {
      return;
    }
    api.post(`${API_URL}/folders/`, { name: addName })
      .then(() => {
        fetchFolders();
        setAddModalOpen(false);
      });
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    if (addModalOpen) {
      setAddName('');
    }
  }, [addModalOpen]);

  return (
    <IonPage>
      <div className="tabs-top-block">
        <img src="/trabu_logomark_white.svg" alt="Logo" />
        <div className={`tabs-2 active-${tab}`}>
          <button type="button" aria-label="Profile" onClick={() => setTab(1)}>
            Bucket List
          </button>
          <button type="button" aria-label="Preferences" onClick={() => setTab(2)}>
            Insights
          </button>
        </div>
      </div>
      <IonContent className="rounded-content">
        <Box className="bucketlist">
          {tab === 1 ? (
            <>
              <h1>Bucket List</h1>
              <Folder
                name="All items"
                icon={<IconFolderHeart />}
                id="all"
              />
              <Folder
                name="Favourites"
                icon={<IconStar />}
                id="favourites"
              />
              {userFolders.map((folder) => (
                <Folder
                  name={folder.name}
                  id={folder.id}
                  icon={<IconFolder />}
                  refresh={fetchFolders}
                  key={folder.id}
                />
              ))}
              <Folder
                name="Recently Deleted"
                icon={<IconTrash />}
                id="deleted"
              />
              <Button
                className="btn btn-orange btn-long"
                startIcon={<IconFolderPlus />}
                onClick={() => setAddModalOpen(true)}
                sx={{ mt: 3 }}
              >
                Add a folder
              </Button>
              <IonModal
                isOpen={addModalOpen}
                dismissible
                onDidDismiss={() => setAddModalOpen(false)}
              >
                <IonHeader mode="ios">
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton className="ion-cancel" onClick={() => setAddModalOpen(false)}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>Add a folder</IonTitle>
                    <IonButtons slot="end">
                      <IonButton className="ion-save" onClick={addFolder}>Add</IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="settings settings-modal">
                  <label style={{ marginTop: 0 }}>Folder Name</label>
                  <input
                    type="text"
                    placeholder="E.g. Paris Trip 2024"
                    value={addName}
                    onChange={(e) => setAddName(e.target.value)}
                  />
                </IonContent>
              </IonModal>
            </>
          ) : (
            <ComingSoon back={() => setTab(1)} />
          )}
        </Box>
      </IonContent>
    </IonPage>
  );
}

export default BucketListView;
