import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { TbPlaneTilt } from 'react-icons/tb';
import { Button } from '@mui/material';
import { IconArrowLeft, IconCheck } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import Airport from './Airport';

function OnboardingLocation({
  nextStep, back, selectedAirports, setSelectedAirports,
}) {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [searchField, setSearchField] = useState('');
  const [airports, setAirports] = useState([]);

  const updateAirport = () => {
    const data = {
      airports: selectedAirports.map((airport) => airport.id),
    };
    api.patch(`${API_URL}/dj-rest-auth/user/`, data).then(() => {
      nextStep();
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    });
  };

  const toggleAirport = (airport) => {
    if (selectedAirports.find((a) => a.id === airport.id)) {
      setSelectedAirports(selectedAirports.filter((a) => a.id !== airport.id));
    } else {
      setSelectedAirports([...selectedAirports, airport]);
    }
  };

  useEffect(() => {
    if (searchField.length < 3) {
      setAirports([]);
      return;
    }
    api.get(`${API_URL}/airports/?search=${searchField}`).then((response) => {
      setAirports(response.data);
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    });
  }, [searchField]);

  return (
    <div className="onboard-location">
      <Button
        className="btn btn-white btn-icon"
        sx={{ mt: -1, ml: -1, mb: 2 }}
        onClick={back}
      >
        <IconArrowLeft />
      </Button>
      <h1>What is your preferred departure airport?</h1>
      <h2 className="airports-title">Selected airports:</h2>
      <div className="selected-airports">
        {selectedAirports.length > 0 ? (
          <>
            {selectedAirports.map((airport) => (
              <span className="selected-airport">
                <TbPlaneTilt />
                {airport.iata_code}
              </span>
            ))}
          </>
        ) : (
          <span className="empty-airport">None</span>
        )}
      </div>
      <input
        type="text"
        placeholder="Search..."
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        className="airport-search"
      />
      <div className="airports">
        {airports.map((airport) => (
          <Airport
            selected={!!selectedAirports.find((a) => a.id === airport.id)}
            airport={airport}
            toggleAirport={toggleAirport}
            key={airport.id}
          />
        ))}
      </div>
      <Button
        className="btn btn-teal"
        endIcon={<IconCheck />}
        onClick={updateAirport}
        disabled={selectedAirports.length === 0}
        sx={{ mt: 3 }}
      >
        OK
      </Button>
    </div>
  );
}

OnboardingLocation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  // eslint-disable-next-line
  selectedAirports: PropTypes.array.isRequired,
  setSelectedAirports: PropTypes.func.isRequired,
};

export default OnboardingLocation;
