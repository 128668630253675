import React from 'react';
import { Holiday } from '../CalendarMonthView/interfaces';
import CalendarTimelineNodeV2 from '../../Components/CalendarTimelineNode';

export interface HolidayProps {
  holiday: Holiday;
  index: number;
  previousHoliday: Holiday;
}

const YearSeparator = ({ displayYear }: { displayYear: number }) => (
  <div className="year-separator" id={`year${displayYear}`}>
    <span>{displayYear}</span>
  </div>
);

export default ({ holiday, index, previousHoliday }: HolidayProps) => {
  const isFirstHoliday = index === 0;
  const isDifferentYear =
    previousHoliday && holiday.year !== previousHoliday.year;
  const renderYearSeparator = isFirstHoliday || isDifferentYear;

  return (
    <div key={index}>
      {renderYearSeparator && <YearSeparator displayYear={holiday.year} />}

      <CalendarTimelineNodeV2
        key={index}
        holiday={holiday}
        odd={index % 2 !== 0}
      />
    </div>
  );
};
