/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import {
  MONTH_ORDER,
  holidayToMonth,
  COUNTRY_PATH,
  TRIP_PATH
} from '../../Constants/staticConstants';
import CalendarMonthCard from 'Components/CalendarMonthCard/CalendarMonthCard';
import {
  CalendarTrip,
  GroupedTripResult,
  GroupedTrips,
  UpcomingTrip
} from './interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { retrieveScheduledItems, selectScheduledItems } from '../../store/scheduling/schedulingSlice';
import { Country, ScheduledItem } from '../../store/scheduling/schedulingInterfaces';
import { useIonViewDidEnter } from '@ionic/react';
import ScheduleItemModal from '../../Components/ScheduleItemModal';
import { SchedulableItem } from '../../Components/ScheduleComponentV2/ScheduleComponentV2';
import useEventTrack from 'hooks/useEventTrack';
import TrackEvent from 'Constants/eventConstant';

function CalendarMonthViewV2() {
  const scheduledItems = useSelector(selectScheduledItems);
  const [monthArr, setMonthArr] = useState<GroupedTripResult[]>([]);
  const history = useHistory();
  const location = useLocation();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedItem, setSelectedItem] = useState<SchedulableItem>();
  const { trackEvent } = useEventTrack();

  useIonViewDidEnter(() => {
    const fetchData = async () => {
      await dispatch(retrieveScheduledItems({ api }))?.unwrap();
    };
    fetchData();
  });

  const groupAndSortTrips = (data: ScheduledItem[]): GroupedTripResult[] => {
    const mappedTrips = data.map((item: ScheduledItem) => ({
      holiday: item.holiday,
      trip: item.trip?.trip as UpcomingTrip,
      country: item.country as Country,
      month: holidayToMonth[item.holiday.name],
      sortKey: `${item.holiday.year}-${MONTH_ORDER[holidayToMonth[item.holiday.name]]}`,
    }));

    mappedTrips.sort((tripA: CalendarTrip, tripB: CalendarTrip) => {
      if (tripA.holiday.year !== tripB.holiday.year)
        return tripA.holiday.year - tripB.holiday.year;
      return MONTH_ORDER[tripA.month] - MONTH_ORDER[tripB.month];
    });

    const grouped = mappedTrips.reduce(
      (grpTrip: GroupedTrips, trip: CalendarTrip) => {
        const tripkey = `${trip.month}-${trip.holiday.year}`;

        return {
          ...grpTrip,
          [tripkey]: [...(grpTrip[tripkey] || []), trip],
        };
      },
      {},
    );

    return Object.entries(grouped).map(([month, tripsdata]) => ({
      month,
      tripsdata,
    }));
  };

  useEffect(() => {
    if (scheduledItems.length > 0) {
      const sortedGroupedTrips: GroupedTripResult[] =
        groupAndSortTrips(scheduledItems);

      setMonthArr(sortedGroupedTrips);
    }
  }, [scheduledItems]);

  const rescheduleClick = async (schedulableItem: SchedulableItem) => {
    trackEvent(TrackEvent.TRIP_RESCHEDULED, {
      id: schedulableItem.id,
      type: 'trip',
      name: schedulableItem.name
    })
    setSelectedItem(schedulableItem);
    setIsScheduleModalOpen(true);
  };

  return (
    <div>
      <Box className="monthlist">
        {monthArr && monthArr.length > 0 ? (
          <>
            {monthArr.map((item: any) => (
              <div key={`${item.month}`}>
                <h2>{item.month}</h2>
                {item.tripsdata.map((trip: any) => {
                  const id = trip?.trip?.id || trip?.country?.id;
                  const image = trip?.trip?.image || trip?.country?.image;
                  const name = trip?.trip?.name || trip?.country?.name;
                  let url = TRIP_PATH;

                  if (trip?.country) {
                    url = COUNTRY_PATH;
                  }

                  return (
                    <div className="monthItem" key={`${id}_${name}`}>
                      <CalendarMonthCard
                        item={{
                          id,
                          image,
                          name,
                        }}
                        onClick={() =>
                          trip &&
                          history.push(`${url}${id}`, {
                            state: { background: location },
                          })
                        }
                        onRescheduleClick={() =>
                          rescheduleClick(trip.trip ? trip.trip : trip.country)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </>
        ) : (
          <div className="no-trips">No trips scheduled</div>
        )}
      </Box>
      {selectedItem && (
        <ScheduleItemModal
          openModal={isScheduleModalOpen}
          item={selectedItem}
          onCloseModal={() => setIsScheduleModalOpen(false)}
          from="Calendar"
        />
      )}
    </div>
  );
}

export default CalendarMonthViewV2;
