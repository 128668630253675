/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from '@mui/material';
import { TbCalendar } from 'react-icons/tb';
import './ScheduleItemModal.scss';
import { Holiday } from '../../Views/CalendarMonthView/interfaces';
import {
  retrieveHolidays, retrieveScheduledItems,
  scheduleItem, selectedHolidayId,
  selectHolidayInScheduleItemModal,
  selectHolidays
} from '../../store/scheduling/schedulingSlice';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { SchedulableItem } from '../ScheduleComponentV2/ScheduleComponentV2';

export interface ScheduleItemModalProps {
  openModal: boolean;
  onCloseModal: () => void;
  item: SchedulableItem;
  from: string;
}

const ScheduleItemModal = ({
                             openModal,
                             onCloseModal,
                             item,
                             from
                           }: ScheduleItemModalProps) => {
  const dispatch = useAppDispatch();
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [schedulingInProgress, setSchedulingInProgress] = useState(false);
  const holidays: Holiday[] = useAppSelector(selectHolidays);
  const selectedHoliday: number | null = useAppSelector(selectedHolidayId);
  const history = useHistory();

  const onScheduleHoliday = () => {
    (async () => {
      try {
        if (selectedHoliday) {
          setSchedulingInProgress(true);
          const scheduledItem = await dispatch(scheduleItem({
            api,
            holidayId: selectedHoliday,
            item
          })).unwrap();

          if (from === 'Calendar') {
            Swal.fire({
              icon: 'success',
              title: 'Scheduled Successfully',
              text: `Trip scheduled for ${scheduledItem.holiday.name} ${scheduledItem.holiday.year}`
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Scheduled Successfully',
              text: `Trip scheduled for ${scheduledItem.holiday.name} ${scheduledItem.holiday.year}`,
              showDenyButton: true,
              confirmButtonText: 'Go to Calendar',
              denyButtonText: 'Remain in Trip Page'
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(
                  `/app/calendar#holiday${selectedHolidayId}`
                );
              }

            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error', error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error
          });
        }
      } finally {
        setSchedulingInProgress(false);
        await dispatch(retrieveScheduledItems({
          api
        })).unwrap();
        onCloseModal();
      }

    })();
  };

  useEffect(() => {
    (async () => {
      await dispatch(retrieveHolidays({ api }));
    })();
  }, []);

  return item && (
    <Modal open={openModal} onClose={onCloseModal}>
      <div className="modal-content trip-schedule-modal">
        <h3>Schedule Trip</h3>
        <div className="block">
          Select a holiday for &nbsp;
          <b>
            &quot;
            {item.name}
            &quot;
          </b>
          .
        </div>
        {holidays && holidays.length === 0 && (
          <div className="block">No holidays available.</div>
        )}
        <div className="holidays">
          {holidays && holidays.map((holiday: Holiday) => (
            <div className={`holiday ${selectedHoliday === holiday.id ? 'selected' : ''}`}
                 onClick={() => dispatch(selectHolidayInScheduleItemModal(holiday.id))}
                 key={holiday.id}
            >{holiday.name} {holiday.year.toString()}</div>
          ))}
        </div>
        <Button
          className="btn btn-orange btn-small"
          startIcon={<TbCalendar />}
          sx={{ mt: 2 }}
          onClick={() => onScheduleHoliday()}
          disabled={!selectedHoliday || schedulingInProgress}
        >Schedule</Button>
      </div>
    </Modal>
  );
};

export default ScheduleItemModal;
