import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TbHelpHexagon } from 'react-icons/tb';
import { Button } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import Swal from 'sweetalert2';
import getImageUrl from '../Images/ImageUtils';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import { FROM_CALENDAR, holidayToMonth } from '../../Constants/staticConstants';
import { IdeasFilterContext } from '../../Providers/IdeasFilterProvider/IdeasFilterProvider';
import ScheduleHolidayItem from './ScheduleHolidayItem';
import {
  retrieveScheduledItems, scheduledItemsForHolidayWithId, scheduleItem
} from '../../store/scheduling/schedulingSlice';
import { useStore } from 'react-redux';
import { useAppDispatch } from '../../store/hooks';
import useEventTrack from 'hooks/useEventTrack';
import TrackError from 'Constants/errorEventConstant';
import TrackEvent from 'Constants/eventConstant';


const SCHEDULING_FAILED = 'Failed to schedule trip';
const SCHEDULING_SUCCESS_MESSAGE = 'Trip scheduled successfully';


const CalendarTimelineNodeV2 = ({ odd, holiday }) => {
  const history = useHistory();
  const location = useLocation();
  const [availableTrips, setAvailableTrips] = useState([]);
  const userContext = useContext(UserContext);
  const filters = useContext(IdeasFilterContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const { trackEvent, trackError } = useEventTrack();

  const state = useStore().getState();
  const scheduledItemForTrip = scheduledItemsForHolidayWithId(state, holiday.id);

  const dispatch = useAppDispatch();

  const scheduleTrip = async (scheduledTrip) => {
    const result = await Swal.fire({
      title: 'Confirmation',
      html: `Do you want to schedule! "<b>${scheduledTrip.name}</b>" for <i>${holiday.name} ${holiday.year}</i>?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    });
    if (result.isDenied || result.isDismissed) {
      setScheduleDialogOpen(false);
    }
    if (result.isConfirmed) {
      try {
        await dispatch(scheduleItem({ api, holidayId: holiday.id, item: scheduledTrip }));
        await dispatch(retrieveScheduledItems({ api }));
        trackEvent(TrackEvent.SCHEDULE_HOLIDAY, {
          holidayId: holiday.id,
          holidayYear: holiday.year
        })
        Swal.fire({
          title: 'Success', text: SCHEDULING_SUCCESS_MESSAGE, icon: 'success'
        });
      } catch {
        trackError(TrackError.SCHEDULE_HOLIDAY, {
          holidayId: holiday.id,
          holidayYear: holiday.year
        })
        Swal.fire({
          title: 'Error', text: SCHEDULING_FAILED, icon: 'error'
        });
      } finally {
        setScheduleDialogOpen(false);
      }
    }
  };

  const getTrips = () => {
    api.get(`${API_URL}/saved/?type=trip&scheduled=false&scheduling_v2_enabled=True`).then((r) => {
      trackEvent(TrackEvent.SAVED_TRIPS, {
        holidayId: holiday.id,
        holidayYear: holiday.year
      })
      setAvailableTrips(r?.data);
      if (r?.data && r.data.length > 0) {
        setScheduleDialogOpen(true);
      } else {
        filters.resetFilters();
        const monthString = holidayToMonth[holiday.name] || '';

        const months = monthString.split('/').map((month) => month.trim());
        filters.selectTripMonths(months);
        filters.selectTripType();

        history.push('/app/ideas', { from: FROM_CALENDAR });
      }
    });
  };

  const openScheduleDialog = () => getTrips();

  const scheduledItemRenderDetails = {};

  if (scheduledItemForTrip.length > 0) {
    if (scheduledItemForTrip[0].trip) {
      scheduledItemRenderDetails.id = scheduledItemForTrip[0].trip.trip.id;
      scheduledItemRenderDetails.image = scheduledItemForTrip[0].trip.trip.image;
      scheduledItemRenderDetails.name = scheduledItemForTrip[0].trip.trip.name;
      scheduledItemRenderDetails.number_of_days = scheduledItemForTrip[0].trip.trip.number_of_days;
      scheduledItemRenderDetails.caption = `${scheduledItemRenderDetails.name} - ${scheduledItemRenderDetails.number_of_days} days`;
      scheduledItemRenderDetails.type = 'trip';

    } else if (scheduledItemForTrip[0].country) {
      scheduledItemRenderDetails.id = scheduledItemForTrip[0].country.id;
      scheduledItemRenderDetails.image = scheduledItemForTrip[0].country.image;
      scheduledItemRenderDetails.caption = scheduledItemForTrip[0].country.name;
      scheduledItemRenderDetails.type = 'country';
    }
  }

  return (
    <div
      className={`holiday-node ${odd && 'odd'} ${scheduledItemRenderDetails?.id && 'planned'}`}
      id={`holiday${holiday.id}`}>
      <div className="holiday-info holiday-name">{holiday.name}</div>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */}
      <div
        className="holiday-circle"
        style={{
          backgroundImage: scheduledItemRenderDetails?.image ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${getImageUrl(scheduledItemRenderDetails?.image)})` : '',
          backgroundPosition: `${scheduledItemRenderDetails?.image?.interest_x || 50}% ${scheduledItemRenderDetails?.image?.interest_y || 50}%`
        }}
        onClick={() => {
          if (!scheduledItemRenderDetails?.id) {
            return;
          }

          if (scheduledItemRenderDetails.type === 'trip') {
            history.push(`trip/${scheduledItemRenderDetails.id}`, { state: { background: location } });
          }

          if (scheduledItemRenderDetails.type === 'country') {
            history.push(`location/country/${scheduledItemRenderDetails.id}`, { state: { background: location } });
          }
        }}
        role="button"
      >
        <img
          src={scheduledItemRenderDetails?.id ? '/semicircle-orange.svg' : '/semicircle.svg'}
          alt="Semicircle"
          className="semicircle"
        />
        <div className="holiday-icon">
          {holiday.icon ? (<img alt="Icon" src={holiday.icon.icon} />) : (<TbHelpHexagon />)}
        </div>
        {
          scheduledItemRenderDetails?.id && (<div className="holiday-trip-title">
            {scheduledItemRenderDetails.caption}
          </div>)
        }
        {!scheduledItemRenderDetails?.id && (<div className="holiday-button-holder">
          <Button
            data-testid="schedule-button"
            className="btn btn-icon btn-orange"
            onClick={openScheduleDialog}
          >
            <IconPlus />
          </Button>
        </div>)}
      </div>
      <ScheduleHolidayItem open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        availableTrips={availableTrips}
        scheduleTrip={scheduleTrip} />
    </div>);
};

export default CalendarTimelineNodeV2;
