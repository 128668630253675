export const features = {
  picture_gallery_v2: 'picture_gallery_v2',
  terms_and_conditions_v2: 'terms_and_conditions_v2',
};

export const defaultValues = {
  picture_gallery_v2: false,
  terms_and_conditions_v2: false,
};

export const flagsmithEnvironmentId = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT || 'production';
  switch (environment) {
    case 'local': {
      return '5yqHEqGoEh5F3KdNE3jcYK';
    }
    case 'production':
      return 'Me23pqQYTfyt6j6ZQRSiv3';
    case 'staging': {
      return 'HpDKmPGK4VCYi7UQKYshju';
    }
    default: {
      return 'HpDKmPGK4VCYi7UQKYshju';
    }
  }
};
