import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { IconArrowLeft, IconSparkles } from '@tabler/icons-react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { EXPERIENCE_LOCATIONS_URL } from '../../Constants/URLS';
import Preloader from '../../Components/Preloader';
import GalleryHeader from '../../Components/GalleryHeader';
import ExperienceInfo from '../../Components/Info/ExperienceInfo';
import getImageUrl from '../../Components/Images/ImageUtils';
import { ConfettiContext } from '../../Providers/ConfettiProvider/ConfettiProvider';
import { useFullscreen } from 'Providers/FullscreenProvider/FullscreenProvider';
import { UnlockScreenOrientation } from 'Utils/OrientationUtils';

function ExperienceView() {
  const { id } = useParams();
  const [experience, setExperience] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [images, setImages] = useState([]);
  const galleryRef = createRef();
  const history = useHistory();
  const [scrolled, setScrolled] = useState(false);
  // const [fullScreen, setFullScreen] = useState(false);
  const [reactionStatus, setReactionStatus] = useState(null);
  const { doConfetti } = useContext(ConfettiContext);
  const sizeRef = createRef();
  const { setIsFullscreen, isFullscreen } = useFullscreen();

  const handleContentScroll = (event) => {
    const { scrollTop } = event.detail;
    setScrolled(scrollTop > 10);
  };

  const fetchExperience = () => {
    api.get(`${EXPERIENCE_LOCATIONS_URL}${id}`).then((response) => {
      setExperience(response.data);
      setReactionStatus(response.data.reaction);
    });
  };

  useEffect(() => {
    setExperience(null);
    fetchExperience();
  }, [id]);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const updateImages = () => {
    if (experience && sizeRef.current) {
      const newImages = [
        {
          original: getImageUrl(experience.image),

          thumbnail: getImageUrl(experience.image),
        },
        ...experience.additional_images.map((image) => ({
          original: getImageUrl(image),
          thumbnail: getImageUrl(image),
        })),
      ];
      setImages(newImages);
    }
  };

  useEffect(() => {
    if (experience) {
      updateImages();
    }
  }, [experience]);

  const toggleLoved = () => {
    if (reactionStatus) {
      setReactionStatus(null);
      api
        .post(`${EXPERIENCE_LOCATIONS_URL}${id}/remove_reaction/`)
        .catch(() => {
          fetchExperience();
        });
    } else {
      setReactionStatus('saved');
      doConfetti();
      api.post(`${EXPERIENCE_LOCATIONS_URL}${id}/save/`).catch(() => {
        fetchExperience();
      });
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateImages();
    });

    if (sizeRef.current) {
      resizeObserver.observe(sizeRef.current);
    }

    return () => {
      if (sizeRef.current) {
        resizeObserver.unobserve(sizeRef.current);
      }
    };
  }, [sizeRef.current]);
  const openImage = (index) => {
    galleryRef.current.setState({ currentIndex: index, offsetPercentage: 0 });
    galleryRef.current.toggleFullScreen();
    UnlockScreenOrientation();
  };

  if (!experience) {
    return (
      <IonPage>
        <IonContent>
          <Preloader />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      {!isFullscreen && (
        <IonHeader className="transparent-header-wrapper">
          <IonToolbar
            className={`transparent-header ${scrolled ? 'scrolled' : ''}`}
          >
            <IonButtons slot="start">
              <IonButton
                fill="clear"
                onClick={() => history.back()}
                className="transparent-header-icon back"
              >
                <IconArrowLeft />
              </IonButton>
            </IonButtons>
            <IonText slot="end" className="transparent-header-type">
              <IconSparkles />
              Experience
            </IonText>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent
        fullscreen
        className="transparent-header-content"
        onIonScroll={handleContentScroll}
        scrollEvents
      >
        <div className="experience-view">
          <div
            className="experience-info-notch"
            style={{ opacity: (scrollPosition - 150) / (305 - 150) }}
          />
          {experience && (
            <GalleryHeader
              images={images}
              sizeRef={sizeRef}
              title={experience.experience.name}
              toggleLoved={toggleLoved}
              reaction={reactionStatus}
              galleryRef={galleryRef}
              fullScreen={isFullscreen}
              setFullScreen={setIsFullscreen}
            />
          )}
          {experience && (
            <ExperienceInfo experience={experience} openImage={openImage} />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default ExperienceView;
