import React, { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TRIPS_URL } from '../../Constants/URLS';
import { Button, CircularProgress } from '@mui/material';
import { TbSettingsAutomation } from 'react-icons/tb';
import { retrieveScheduledItems } from '../../store/scheduling/schedulingSlice';
import { useAppDispatch } from '../../store/hooks';

const AutoscheduleComponentV2 = ({ tripDetails }) => {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showAutoScheduleLoader, setShowAutoScheduleLoader] = useState(false);

  const handleAutoSchedule = async () => {
    setShowAutoScheduleLoader(true);
    Swal.fire({
      title: 'Auto-Schedule Trip',
      text: 'Are you sure you want to schedule this trip automatically?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // setSchedulingLoading(true);
        api
          .post(`${TRIPS_URL}${tripDetails.id}/schedule_for_me/`)
          .then((response) => {
            setShowAutoScheduleLoader(false);

            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Scheduled Successfully',
                text: `Trip scheduled for ${response.data.holiday}`,
                showDenyButton: true,
                confirmButtonText: 'Go to Calendar',
                denyButtonText: 'Remain in Trip Page',
              }).then((r) => {
                if (r.isConfirmed) {
                  history.push(
                    `/app/calendar#holiday${response.data.holiday_id}`,
                  );
                }
              });
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.error,
              });
              setShowAutoScheduleLoader(false);
            }
          })
          .finally(async () => {
            // updateScheduleStatus(true);
            // setSchedulingLoading(false);
            await dispatch(
              retrieveScheduledItems({
                api,
              }),
            );
          });
      } else {
        setShowAutoScheduleLoader(false);
      }
    });
  };

  return (
    <Button
      className="btn btn-text"
      startIcon={<TbSettingsAutomation />}
      onClick={() => handleAutoSchedule()}
      sx={{ mt: 2 }}
      disabled={showAutoScheduleLoader}
      endIcon={showAutoScheduleLoader && <CircularProgress size={20} />}
    >
      Auto-Schedule Trip
    </Button>
  );
};

export default AutoscheduleComponentV2;
