import getApiHost from '../Utils/UrlUtils';

export const API_URL = `${getApiHost()}/api/v1`;

// eslint-disable-next-line import/prefer-default-export
export const LOGIN_URL = `${API_URL}/auth/token/`;
export const REQUEST_URL = `${API_URL}/users/me`;
export const DELETE_ACCOUNT = `${API_URL}/users/delete-account/`;
export const REFRESH_URL = `${API_URL}/auth/token/refresh/`;
export const SIGNUP_URL = `${API_URL}/dj-rest-auth/registration/`;
export const TRIPS_URL = `${API_URL}/trips/`;
// export const USER_TRIPS_URL = `${API_URL}/user-trips/`;
export const REACTIONS_URL = `${API_URL}/reactions`;
export const TRIP_AIRPORTS_URL = `${API_URL}/trip-airports/`;
export const COMPACT_DESTINATION_URL = `${API_URL}/compact-destinations/`;
export const HOLIDAY_URL = `${API_URL}/holidays/`;
export const AVAILABLE_HOLIDAYS_URL = `${API_URL}/available-holidays/`;
export const INTEREST_CATEGORIES_URL = `${API_URL}/interest-categories/`;
export const LIKED_TRIP_URL = `${API_URL}/liked-trips/`;
export const LOCATIONS_URL = `${API_URL}/locations/`;
export const FORWARDING_URL = 'https://trabu-staging.backend.aiheroes.io/forwarding/trip-details';
export const EXPERIENCE_LOCATIONS_URL = `${API_URL}/experience-locations/`;
export const GOOGLE_LOGIN = `${API_URL}/dj-rest-auth/google/login/`;
export const FB_LOGIN = `${API_URL}/dj-rest-auth/facebook/login/`;
export const DISCOVER_URL = `${API_URL}/discover`;
export const SCHEDULED_ITEMS_URL = `${API_URL}/scheduled-items/`;
export const ADD_SCHEDULED_ITEM_URL = `${API_URL}/scheduled-items/`;
export const REMOVE_SCHEDULED_ITEM_URL = `${API_URL}/scheduled-items`;
export const TERMS_AND_CONDITIONS_URL = `${API_URL}/terms/`;
export const TERMS_ACCEPTANCE_URL = `${API_URL}/user-terms-acceptance/accept/`;
