import React, { useContext, useState } from 'react';
import { Button, Modal } from '@mui/material';
import { MdOutlineFeedback } from 'react-icons/md';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function Feedback() {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [bugText, setBugText] = useState('');
  const [isBugTextEmpty, setIsBugTextEmpty] = useState(false);
  const { user } = useContext(UserContext);

  const closeModal = () => {
    setFeedbackOpen(false);
    setBugText('');
    setIsBugTextEmpty(false);
  };

  const submitBug = () => {
    if (!bugText) {
      setIsBugTextEmpty(true);
      return;
    }
    Sentry.sendFeedback(
      {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        message: bugText,
      },
      {
        includeReplay: true,
      },
    );
    Swal.fire({
      icon: 'success',
      title: 'Thank you!',
      text: 'Your feedback has been submitted!',
    });
    closeModal();
  };

  const openModal = () => {
    setFeedbackOpen(true);
  };

  return (
    user && user.is_tester && (
      <>
        {/* eslint-disable-next-line */}
        <div onClick={openModal} className="feedback-button">
          <MdOutlineFeedback />
        </div>
        <Modal open={feedbackOpen} onClose={closeModal} className="feedback-modal">
          <div className="modal-content">
            <h2>Report a Bug</h2>
            <label>Name</label>
            <input type="text" value={`${user.first_name} ${user.last_name}`} disabled />
            <label>Email</label>
            <input type="text" value={user.email} disabled />
            <label>Description</label>
            <textarea
              placeholder="What's the bug? What did you expect?"
              rows={4}
              value={bugText}
              onChange={(e) => {
                setBugText(e.target.value);
                if (e.target.value) {
                  setIsBugTextEmpty(false);
                }
              }}
              className={isBugTextEmpty ? 'error' : ''}
            />
            <Button className="btn btn-orange btn-long" sx={{ mt: 2 }} onClick={submitBug}>
              Send Bug Report
            </Button>
            <Button className="btn btn-border-red btn-long btn-small" sx={{ mt: 2 }} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      </>
    )
  );
}

export default Feedback;
