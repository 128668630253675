import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import { Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { PhoneInput } from 'react-international-phone';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './ProfileSettings.scss';
import { API_URL } from '../../Constants/URLS';
// eslint-disable-next-line
import 'react-international-phone/style.css';

function PhoneNumber({ open, close }) {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (userContext.user && userContext.user.phone_number) {
      setPhone(userContext.user.phone_number);
    }
  }, [userContext, open]);

  const updatePhoneNumber = () => {
    if (!phone) {
      Swal.fire('Error', 'Please enter a valid phone number.', 'error');
      return;
    }
    if (phone === userContext.user.phone_number) {
      close();
      return;
    }
    api.patch(`${API_URL}/dj-rest-auth/user/`, { phone_number: phone })
      .then(() => {
        userContext.getUserInfo();
        Swal.fire('Success', 'Phone number updated successfully.', 'success');
        close();
      }).catch((e) => {
        Swal.fire('Error', e.response.data.phone_number[0], 'error');
      });
  };

  return (
    <Sheet snapPoints={[0.95]} isOpen={open} onClose={close}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="settings">
            <h1>Update Phone Number</h1>
            <label>Phone Number</label>
            <PhoneInput
              defaultCountry="gb"
              value={phone}
              onChange={(p) => setPhone(p)}
            />
            <Button
              className="btn btn-orange"
              startIcon={<FaSave />}
              sx={{ mt: 3 }}
              onClick={updatePhoneNumber}
            >
              Save
            </Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={close} />
    </Sheet>
  );
}

PhoneNumber.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default PhoneNumber;
